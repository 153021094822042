import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnimatedModal from '../../partials/content/AnimatedModal';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import SimpleDropzone from '../../partials/content/SimpleDropzone';
import VideoDropzone from '../../partials/content/VideoDropzone';
import '../../sass/components/_inputs.scss';
import '../../sass/pages/workout/editWorkout.scss';
import {
  actions as planActions,
  selectCoaches,
  selectCurrentCoachId,
  selectPlanLevelsForDropdown
} from '../../store/ducks/plan.duck';
import { selectLoadingIndicators } from '../../store/ducks/UI.duck';
import { actions, selectActiveWorkout, selectExerciseGroups } from '../../store/ducks/workout.duck';
import EditExerciseGroup from './EditExerciseGroup';

const EditWorkout = (props) => {

  const workoutId = props.match.params.id;
  const activeWorkout = useSelector(selectActiveWorkout);
  let exerciseGroups = useSelector(selectExerciseGroups);
  // const benefits = useSelector(selectPropertiesByType('benefits'));
  // const bodyParts = useSelector(selectPropertiesByType('bodyParts'));
  const loadingIndicators = useSelector(selectLoadingIndicators);
  const planLevels = useSelector(selectPlanLevelsForDropdown);
  const coaches = useSelector(selectCoaches);
  const currentCoachId = useSelector(selectCurrentCoachId);
  const {
    createWorkoutExerciseGroup,
    deleteWorkoutExerciseGroup,
    loadAllProperties,
    loadExerciseGroups,
    loadWorkout,
    updateWorkout,
    updateWorkoutExerciseGroup,
    // updateWorkoutProperties,
    uploadWorkoutFile,
  } = actions;
  const { loadCoaches, loadPlans } = planActions;
  const dispatch = useDispatch();

  const [activeExerciseGroupId, setActiveExerciseGroupId] = useState(null);
  const [exerciseGroupToAdd, setExerciseGroupToAdd] = useState(null);

  useEffect(() => {
    workoutId && dispatch(loadWorkout(workoutId));
  }, [dispatch, workoutId, loadWorkout]);

  useEffect(() => {
    dispatch(loadAllProperties());
  }, [dispatch, loadAllProperties]);

  useEffect(() => {
    dispatch(loadExerciseGroups());
  }, [dispatch, loadExerciseGroups]);

  useEffect(() => {
    dispatch(loadPlans());
  }, [dispatch, loadPlans]);

  useEffect(() => {
    !coaches?.length && dispatch(loadCoaches());
  }, [coaches, dispatch, loadCoaches]);

  if (!activeWorkout || String(activeWorkout.id) !== workoutId) return null;

  exerciseGroups = exerciseGroups.sort((a, b) =>
    a.title > b.title ? 1 : (
      a.title < b.title ? -1 : (
        a.title2 > b.title2 ? 1 : -1
      )
    )
  );
  exerciseGroups = exerciseGroups.filter(eg => !(activeWorkout.exerciseGroups || []).map(exg => exg.id).includes(eg.id));

  return (
    <Portlet>
      <PortletBody>
        <div className="amo-row">
          <div className="amo-column">
            <FormControl>
              <InputLabel htmlFor="coach">Coach</InputLabel>
              <Select
                inputProps={{ name: 'Coach', id: 'coach' }}
                style={{ minWidth: '300px' }}
                defaultValue={get(activeWorkout, 'coachId')}
                onBlur={(e) => dispatch(updateWorkout(activeWorkout.id, { coachId: e.target.value }))}
              >
                {coaches.map(c =>
                  <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                )}
              </Select>
            </FormControl>
            <div className="amo-divider" />
            <TextField
              id="title"
              label="Title"
              defaultValue={activeWorkout.title}
              onBlur={(e) => dispatch(updateWorkout(activeWorkout.id, { title: e.target.value.trim() }))}
            />
            <div className="amo-divider" />
            <FormControl>
              <InputLabel htmlFor="type">Type</InputLabel>
              <Select
                inputProps={{ name: 'Type', id: 'type' }}
                style={{ width: "300px" }}
                defaultValue={get(activeWorkout, 'type') ?? ''}
                onBlur={(e) => dispatch(updateWorkout(activeWorkout.id, { type: e.target.value }))}
              >
                <MenuItem value="workout">Workout</MenuItem>
                <MenuItem value="screening" disabled>Screening</MenuItem>
                <MenuItem value="stretch">Stretch</MenuItem>
              </Select>
            </FormControl>
            {/* <div className="amo-divider" /> */}
            {/* <TextField
              id="overview"
              label="Overview"
              className="input-standard"
              margin="normal"
              defaultValue={activeWorkout.overview}
              onBlur={(e) => dispatch(updateWorkout(activeWorkout.id, { overview: e.target.value.trim() }))}
            /> */}
            <div className="amo-divider" />
            <FormControl>
              <InputLabel htmlFor="planLevelId">Plan & Plan Level</InputLabel>
              <Select
                inputProps={{ name: 'Plan & Plan Level', id: 'planLevelId' }}
                style={{ width: "300px" }}
                defaultValue={get(activeWorkout, 'planLevelId') ?? ''}
                onBlur={(e) => dispatch(updateWorkout(activeWorkout.id, { planLevelId: e.target.value }))}
              >
                {planLevels
                  .filter(pl => !currentCoachId ? true : (pl.coachId === currentCoachId))
                  .map(pl =>
                    <MenuItem key={pl.id} value={pl.id}>{pl.title}</MenuItem>
                  )
                }
              </Select>
            </FormControl>
            <div className="amo-divider" />
            <FormControl>
              <InputLabel htmlFor="weekOfPlan">Plan Level Week</InputLabel>
              <Select
                inputProps={{ name: 'Plan Level Week', id: 'weekOfPlan' }}
                style={{ width: '300px' }}
                defaultValue={get(activeWorkout, 'weekOfPlan') ?? ''}
                onBlur={(e) => dispatch(updateWorkout(activeWorkout.id, { weekOfPlan: e.target.value }))}
              >
                <MenuItem value={0}>Week 0</MenuItem>
                <MenuItem value={1}>Week 1</MenuItem>
                <MenuItem value={2}>Week 2</MenuItem>
                <MenuItem value={3}>Week 3</MenuItem>
                <MenuItem value={4}>Week 4</MenuItem>
              </Select>
            </FormControl>
            {/* <FormControl>
              <InputLabel htmlFor="benefits">Benefits</InputLabel>
              <Select
                multiple
                inputProps={{ name: 'Benefits', id: 'benefits' }}
                style={{ width: '300px' }}
                defaultValue={(activeWorkout?.benefits ?? []).map(b => b.id)}
                onBlur={(e) => dispatch(updateWorkoutProperties(activeWorkout.id, 'benefits', e.target.value))}
              >
                {benefits.map((b) =>
                  <MenuItem key={b.id} value={b.id}>{b.value}</MenuItem>
                )}
              </Select>
            </FormControl> */}
            {/* <div className="amo-divider" /> */}
            {/* <FormControl>
              <InputLabel htmlFor="body-parts">Body Parts</InputLabel>
              <Select
                multiple
                inputProps={{ name: 'Body Parts', id: 'body-parts' }}
                style={{ width: '300px' }}
                defaultValue={(activeWorkout?.bodyParts ?? []).map(bp => bp.id)}
                onBlur={(e) => dispatch(updateWorkoutProperties(activeWorkout.id, 'bodyParts', e.target.value))}
              >
                {bodyParts.map((pb) =>
                  <MenuItem key={pb.id} value={pb.id}>{pb.value}</MenuItem>
                )}
              </Select>
            </FormControl> */}
            <div className="amo-divider" />
            <FormControl>
              <InputLabel htmlFor="working-time">Working Time</InputLabel>
              <Select
                inputProps={{ name: 'Working Time', id: 'working-time' }}
                style={{ width: '300px' }}
                defaultValue={activeWorkout?.workingTime}
                onBlur={(e) => dispatch(updateWorkout(activeWorkout.id, { workingTime: e.target.value }))}
              >
                <MenuItem value={null}>-</MenuItem>
                {[20, 25, 30, 35, 40, 45, 50, 55, 60].map((wt) =>
                  <MenuItem key={wt} value={wt}>{wt}s</MenuItem>
                )}
              </Select>
            </FormControl>
            <div className="amo-divider" />
            <FormControl>
              <InputLabel htmlFor="resting-time">Resting Time</InputLabel>
              <Select
                inputProps={{ name: 'Resting Time', id: 'resting-time' }}
                style={{ width: '300px' }}
                defaultValue={activeWorkout?.restingTime}
                onBlur={(e) => dispatch(updateWorkout(activeWorkout.id, { restingTime: e.target.value }))}
              >
                <MenuItem value={null}>-</MenuItem>
                {[10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60].map((rt) =>
                  <MenuItem key={rt} value={rt}>{rt}s</MenuItem>
                )}
              </Select>
            </FormControl>
            <div className="amo-divider" />
            <FormControl>
              <InputLabel htmlFor="reps">Reps</InputLabel>
              <Select
                inputProps={{ name: 'Reps', id: 'reps' }}
                style={{ width: '300px' }}
                defaultValue={activeWorkout?.reps}
                onBlur={(e) => dispatch(updateWorkout(activeWorkout.id, { reps: e.target.value }))}
              >
                <MenuItem value={null}>-</MenuItem>
                {[6, 8, 10, 12, 15].map((r) =>
                  <MenuItem key={r} value={r}>{r}x</MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
          <div className="amo-divider" />
          <div className="amo-column">
            <div className="amo-row">
              <SimpleDropzone
                loading={loadingIndicators.includes('workout_file_upload_cover')}
                title="Workout Cover"
                onDelete={() => dispatch(uploadWorkoutFile(activeWorkout.id, null, 'cover'))}
                onDrop={(files) => files.length && dispatch(uploadWorkoutFile(activeWorkout.id, files[0], 'cover'))}
                url={activeWorkout?.cover}
              />
              <div className="amo-divider" />
              <SimpleDropzone
                loading={loadingIndicators.includes('workout_file_upload_thumbnail')}
                title="Workout Thumbnail"
                onDelete={() => dispatch(uploadWorkoutFile(activeWorkout.id, null, 'thumbnail'))}
                onDrop={(files) => files.length && dispatch(uploadWorkoutFile(activeWorkout.id, files[0], 'thumbnail'))}
                url={activeWorkout?.thumbnail}
              />
            </div>
            <div className="amo-row">
              <VideoDropzone
                loading={loadingIndicators.includes('workout_file_upload_intro')}
                title="Workout Intro"
                onDelete={() => dispatch(uploadWorkoutFile(activeWorkout.id, null, 'intro'))}
                onDrop={(files) => files.length && dispatch(uploadWorkoutFile(activeWorkout.id, files[0], 'intro'))}
                url={(activeWorkout?.workoutIntermissions ?? []).find(x => x?.type === 'intro')?.video}
                duration={(activeWorkout?.workoutIntermissions ?? []).find(x => x?.type === 'intro')?.videoDuration}
              />
              <div className="amo-divider" />
              <VideoDropzone
                loading={loadingIndicators.includes('workout_file_upload_outro')}
                title="Workout Outro"
                onDelete={() => dispatch(uploadWorkoutFile(activeWorkout.id, null, 'outro'))}
                onDrop={(files) => files.length && dispatch(uploadWorkoutFile(activeWorkout.id, files[0], 'outro'))}
                url={(activeWorkout?.workoutIntermissions ?? []).find(x => x?.type === 'outro')?.video}
                duration={(activeWorkout?.workoutIntermissions ?? []).find(x => x?.type === 'outro')?.videoDuration}
              />
            </div>
          </div>
        </div>
        <div className="amo-divider" />
        <div className="amo-divider" />
        <div className="edit-workout-sections-container">
          <div className="edit-workout-section-wrapper">
            {activeWorkout?.exerciseGroups &&
              <div className="section-body">
                <h2 style={{ margin: '8px 0 8px 0' }}>EXERCISE GROUPS:</h2>
                {activeWorkout?.exerciseGroups.map((exerciseGroup, index) =>
                  <div
                    className="edit-workout-exercise"
                    key={exerciseGroup.id}
                    onClick={() => setActiveExerciseGroupId(exerciseGroup.id)}
                  >
                    <span onClick={(e) => e.stopPropagation()}>
                      <FormControl style={{ margin: '0 8px 0 0' }}>
                        <InputLabel htmlFor="working-time">Working Time</InputLabel>
                        <Select
                          inputProps={{ name: 'Working Time', id: 'working-time' }}
                          style={{ width: '100px' }}
                          defaultValue={exerciseGroup?.workingTime || null}
                          onBlur={(e) => dispatch(updateWorkoutExerciseGroup(activeWorkout.id, exerciseGroup.id, { workingTime: e.target.value }))}
                        >
                          <MenuItem value={null}>-</MenuItem>
                          {[20, 25, 30, 35, 40, 45, 50, 55, 60].map((wt) =>
                            <MenuItem key={wt} value={wt}>{wt}s</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      <FormControl style={{ margin: '0 8px 0 0' }}>
                        <InputLabel htmlFor="resting-time">Resting Time</InputLabel>
                        <Select
                          inputProps={{ name: 'Resting Time', id: 'resting-time' }}
                          style={{ width: '100px' }}
                          defaultValue={exerciseGroup?.restingTime || null}
                          onBlur={(e) => dispatch(updateWorkoutExerciseGroup(activeWorkout.id, exerciseGroup.id, { restingTime: e.target.value }))}
                        >
                          <MenuItem value={null}>-</MenuItem>
                          {[10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60].map((rt) =>
                            <MenuItem key={rt} value={rt}>{rt}s</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      <FormControl style={{ margin: '0 8px 0 0' }}>
                        <InputLabel htmlFor="reps">Reps</InputLabel>
                        <Select
                          inputProps={{ name: 'Reps', id: 'reps' }}
                          style={{ width: '100px' }}
                          defaultValue={exerciseGroup?.reps || null}
                          onBlur={(e) => dispatch(updateWorkoutExerciseGroup(activeWorkout.id, exerciseGroup.id, { reps: e.target.value }))}
                        >
                          <MenuItem value={null}>-</MenuItem>
                          {[6, 8, 10, 12, 15].map((r) =>
                            <MenuItem key={r} value={r}>{r}x</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      <div style={{ width: 60, display: 'inline-flex', flexDirection: 'row-reverse', margin: '8px 0' }}>
                        <DeleteForeverIcon
                          fontSize="large"
                          onClick={() => dispatch(deleteWorkoutExerciseGroup(activeWorkout.id, exerciseGroup.id))}
                        />
                        {index > 0 &&
                          <ArrowUpwardIcon
                            fontSize="large"
                            onClick={() => dispatch(updateWorkoutExerciseGroup(activeWorkout.id, exerciseGroup.id, { order: index }))}
                          />
                        }
                      </div>
                    </span>
                    <span>
                      {exerciseGroup?.WorkoutExerciseGroups?.order}
                      {' - '}
                      [{exerciseGroup?.id}]
                      {' - '}
                      {exerciseGroup?.title ?? '<title missing>'}
                      {' '}
                      {`(${exerciseGroup?.title2?.length > 70 ? `${exerciseGroup?.title2?.substring(0, 70)}...` : exerciseGroup?.title2})`}
                    </span>
                  </div>
                )}
              </div>
            }
            <div className="amo-column">
              <FormControl style={{ marginRight: 16 }}>
                <InputLabel htmlFor="exercise">Exercise Group to Add</InputLabel>
                <Select
                  style={{ minWidth: 300 }}
                  value={exerciseGroupToAdd}
                  onChange={(e) => setExerciseGroupToAdd(e.target.value)}
                  inputProps={{ name: 'Exercise Group to Add', id: 'exercise' }}
                >
                  {(exerciseGroups || [])
                    .filter(eg => !currentCoachId ? true : (eg.coachId === currentCoachId))
                    .map(eg =>
                      <MenuItem key={eg.id} style={{ maxWidth: 900 }} value={eg.id}>
                        {eg.title} [{eg.id}] ({eg.title2})
                      </MenuItem>
                    )
                  }
                </Select>
              </FormControl>
              <div
                className="edit-workout-add-element small"
                onClick={() => {
                  dispatch(createWorkoutExerciseGroup(activeWorkout.id, exerciseGroupToAdd));
                  setExerciseGroupToAdd(null);
                }}
              >
                + Add Selected Exercise Group
              </div>
            </div>
          </div>
        </div>
      </PortletBody>
      <AnimatedModal open={!!activeExerciseGroupId} onClose={() => setActiveExerciseGroupId(null)}>
        <EditExerciseGroup exerciseGroupId={activeExerciseGroupId} />
      </AnimatedModal>
    </Portlet >
  );
}

export default EditWorkout;
