import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { actions } from '../../store/ducks/auth.duck';


const LogOut = () => {

  const { logOut } = actions;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logOut());
  }, [dispatch, logOut]);

  return <Redirect to="/" />;
}

export default LogOut;
