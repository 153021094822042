import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AudioDropzone from '../../partials/content/AudioDropzone';
import { PortletBody } from '../../partials/content/Portlet';
import { selectLoadingIndicators } from '../../store/ducks/UI.duck';
import { actions, selectWorkoutAudioById } from '../../store/ducks/workout.duck';


const EditWorkoutAudio = ({ workoutAudioId }) => {

  const loadingIndicators = useSelector(selectLoadingIndicators);
  const workoutAudio = useSelector(selectWorkoutAudioById(workoutAudioId));
  const { uploadWorkoutAudioFile } = actions;
  const dispatch = useDispatch();

  return !workoutAudio ? null : (
    <PortletBody style={{ width: 1150, boxShadow: '0px 0px 13px 0px rgba(82, 63, 105, 0.2)' }}>
      <div className="amo-column">
        <TextField
          className="input-standard"
          defaultValue={workoutAudio.title || ''}
          disabled
          label="Title"
        />
        <div className="amo-divider" />
        <div>
          {Array.from({ length: 20 }, (_, i) => i + 1).map(number =>
            <div className="amo-flex1" style={{ maxWidth: 350, minWidth: 350, margin: '10px 10px 0 0', display: 'inline-block' }}>
              <AudioDropzone
                title={number}
                onDelete={() => dispatch(uploadWorkoutAudioFile(workoutAudio.id, null, `${workoutAudio.type}-${number}`))}
                onDrop={(files) => dispatch(uploadWorkoutAudioFile(workoutAudio.id, files[0], `${workoutAudio.type}-${number}`))}
                loading={loadingIndicators.includes(`workoutAudio_file_upload_${workoutAudio.type}-${number}_${workoutAudio.id}`)}
                url={(workoutAudio.workoutAudioIntermissions || []).find(i => i.type === `${workoutAudio.type}-${number}`)?.audio}
                duration={(workoutAudio.workoutAudioIntermissions || []).find(i => i.type === `${workoutAudio.type}-${number}`)?.audioDuration}
              />
            </div>
          )}
        </div>
      </div>
    </PortletBody>
  );
}

EditWorkoutAudio.propTypes = {
  workoutAudioId: PropTypes.number,
};

export default EditWorkoutAudio;
