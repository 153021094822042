import { TextField } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import SimpleDropzone from '../../partials/content/SimpleDropzone';
import VideoDropzone from '../../partials/content/VideoDropzone';
import { selectLoadingIndicators } from '../../store/ducks/UI.duck';
import { actions, selectThemeEpisodeById } from '../../store/ducks/theme.duck';

function EditThemeEpisode({ themeEpisodeId }) {

  const themeEpisode = useSelector(state => selectThemeEpisodeById(themeEpisodeId)(state));
  const loadingIndicators = useSelector(selectLoadingIndicators);
  const { updateThemeEpisode, uploadThemeEpisodeFile } = actions;
  const dispatch = useDispatch();

  return (
    <Portlet>
      <PortletBody>
        <div className="amo-row">
          <div className="amo-column">
            <TextField
              label="Title"
              className="input-standard"
              defaultValue={get(themeEpisode, 'title', '')}
              onBlur={(e) => dispatch(updateThemeEpisode(themeEpisodeId, { ...themeEpisode, title: e.target.value }))}
            />
            <div className="amo-divider" />
            <TextField
              label="Push Notification Text"
              className="input-standard"
              defaultValue={get(themeEpisode, 'textForPushNotification', '')}
              onBlur={(e) => dispatch(updateThemeEpisode(themeEpisodeId, { ...themeEpisode, textForPushNotification: e.target.value }))}
            />
            <div className="amo-divider" />
            <TextField
              label="Card Text"
              className="input-standard"
              defaultValue={get(themeEpisode, 'textForNotification', '')}
              onBlur={(e) => dispatch(updateThemeEpisode(themeEpisodeId, { ...themeEpisode, textForNotification: e.target.value }))}
            />
            <div className="amo-divider" />
            <TextField
              label="Card Text Follow Up"
              className="input-standard"
              defaultValue={get(themeEpisode, 'textFollowUpForNotification', '')}
              onBlur={(e) => dispatch(updateThemeEpisode(themeEpisodeId, { ...themeEpisode, textFollowUpForNotification: e.target.value }))}
            />
          </div>
          <div className="amo-divider" />
          <SimpleDropzone
            loading={loadingIndicators.includes(`theme_episode_upload_thumbnail_${themeEpisodeId}`)}
            title="Thumbnail"
            onDrop={(files) => files.length && dispatch(uploadThemeEpisodeFile(themeEpisodeId, files[0], 'thumbnail'))}
            url={get(themeEpisode, 'thumbnail')}
          />
          <div className="amo-divider" />
          <VideoDropzone
            loading={loadingIndicators.includes(`theme_episode_upload_video_${themeEpisodeId}`)}
            title="Video"
            onDrop={(files) => files.length && dispatch(uploadThemeEpisodeFile(themeEpisodeId, files[0], 'video'))}
            url={get(themeEpisode, 'video')}
            duration={get(themeEpisode, 'videoDuration')}
          />
        </div>
      </PortletBody>
    </Portlet>
  );
}

EditThemeEpisode.propTypes = {
  themeEpisodeId: PropTypes.number,
};

export default EditThemeEpisode;
