import React from 'react';
import { Fade, Backdrop, Modal, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import '../../sass/components/_animated-modal.scss';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

export default function AnimatedModal(props) {
  const classes = useStyles();
  const { open, onClose } = props;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <div className="animated-modal-container">
          <div className="animated-modal-content">{props.children}</div>
          <div className="animated-modal-close" onClick={onClose}>
            <Close />
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

AnimatedModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};
