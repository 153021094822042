import axios from "axios";

export const uploadFile = (url, file, contentType, fileType) => {
  const formData = new FormData();

  formData.append("file", file);
  formData.append("contentType", contentType);
  formData.append("fileType", fileType);

  return axios.post(url, formData, { headers: { "Content-Type": "multipart/form-data" } });
}

export const isEmail = (value) => {
  const regExp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'i');
  return regExp.test(value);
}
