import { get } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import Table from '../../partials/content/Table';
import {
  actions as planActions,
  selectCoaches,
  selectCurrentCoachId,
  selectPlanLevelsForDropdown
} from '../../store/ducks/plan.duck';
import { selectLoadingIndicators } from '../../store/ducks/UI.duck';
import { actions, selectWorkouts } from '../../store/ducks/workout.duck';

const Workouts = ({ history }) => {

  const workouts = useSelector(selectWorkouts);
  const planLevels = useSelector(selectPlanLevelsForDropdown);
  const loadingIndicators = useSelector(selectLoadingIndicators);
  const coaches = useSelector(selectCoaches);
  const currentCoachId = useSelector(selectCurrentCoachId);
  const { createWorkout, deleteWorkouts, duplicateWorkouts, loadWorkouts } = actions;
  const { loadCoaches, loadPlans } = planActions;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadWorkouts());
  }, [dispatch, loadWorkouts]);

  useEffect(() => {
    dispatch(loadPlans());
  }, [dispatch, loadPlans]);

  useEffect(() => {
    !coaches?.length && dispatch(loadCoaches());
  }, [coaches, dispatch, loadCoaches]);

  return (
    <Portlet>
      <PortletBody>
        <Table
          data={(workouts || [])
            .filter(w => !currentCoachId ? true : (w.coachId === currentCoachId))
            .map(w => ({
              ...w,
              coach: coaches.find(c => c.id === w.coachId)?.name,
              planLevel: get(planLevels.find(pl => pl.id === w.planLevelId), 'title') || '',
            }))
          }
          headCells={[
            { id: 'id', label: 'ID' },
            { id: 'coach', label: 'Coach' },
            { id: 'planLevel', label: 'Plan & Plan Level' },
            { id: 'weekOfPlan', label: 'Plan Level Week' },
            { id: 'title', label: 'Title' },
            { id: 'createdAt', label: 'Created', isDateTime: true },
          ]}
          isLoading={loadingIndicators.includes('workouts_loading')}
          title="Workouts"
          onCreate={() => dispatch(createWorkout({ coachId: currentCoachId }))}
          onDelete={(ids) => dispatch(deleteWorkouts(ids))}
          onDuplicate={(ids) => dispatch(duplicateWorkouts(ids))}
          onRowClick={(id) => history.push(`/edit-workout/${id}`)}
        />
      </PortletBody>
    </Portlet>
  );
}

export default Workouts;
