import { addDays } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnimatedModal from '../../partials/content/AnimatedModal';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import Table from '../../partials/content/Table';
import { actions as planActions, selectPlans } from '../../store/ducks/plan.duck';
import { actions as userActions, selectUsers } from '../../store/ducks/user.duck';
import EditUser from './EditUser';

const FREE_TRIAL_DAYS = 7;
const Users = () => {

  const users = useSelector(state => selectUsers(state));
  const plans = useSelector(state => selectPlans(state));
  const { loadAllUsers } = userActions;
  const { loadPlans } = planActions;
  const dispatch = useDispatch();
  const [activeUserId, setActiveUserId] = useState();

  useEffect(() => {
    dispatch(loadAllUsers());
  }, [dispatch, loadAllUsers]);

  useEffect(() => {
    dispatch(loadPlans());
  }, [dispatch, loadPlans]);

  const usersList = useMemo(() => {
    return users.map(u => {
      const plan = plans.find(p => p.id === u.planId);
      const userPlan = (u?.userPlans || []).find(up => up.planId === u.planId);
      const userPlanLevelIndex = userPlan?.levelsCompleted ?? 0;
      const planLevel = plan?.levels[userPlanLevelIndex];
      const timezone = (-1 * u.timezoneOffset) / 60;
      return {
        ...u,
        isAdmin: u.isAdmin ? 'Yes' : '',
        trialUntil: new Date(addDays(new Date(u.createdAt), FREE_TRIAL_DAYS)),
        isSubscriptionActive: u.isSubscriptionActive ? 'Yes' : '',
        plan: plan?.title || '',
        planLevel: plan?.title && planLevel?.title ? `${plan?.title} - ${planLevel?.title}` : '',
        timezone: `GMT${timezone === 0 ? '' : (timezone > 0 ? `+${timezone}` : timezone)}`,
      };
    });
  }, [plans, users]);

  return (
    <Portlet>
      <PortletBody>
        <Table
          data={usersList}
          defaultOrder='desc'
          defaultOrderBy='id'
          headCells={[
            { id: 'id', label: 'ID' },
            { id: 'email', label: 'Email' },
            { id: 'name', label: 'Name' },
            { id: 'gender', label: 'Gender' },
            { id: 'experience', label: 'Experience' },
            { id: 'planLevel', label: 'Plan & Plan Level' },
            { id: 'planLevelWeek', label: 'Week' },
            // { id: 'themeId', label: 'Theme ID' },
            { id: 'isAdmin', label: 'Admin' },
            { id: 'trialUntil', label: 'Trial Until', isDate: true },
            { id: 'promoAdminUntil', label: 'Promo From Admin Until', isDate: true },
            { id: 'promoCodeUntil', label: 'Promo Code Until', isDate: true },
            { id: 'isSubscriptionActive', label: 'Subscribed' },
            { id: 'timezone', label: 'Timezone' },
            { id: 'createdAt', label: 'Created', isDateTime: true },
            { id: 'registerOrigin', label: 'Register Origin' },
            { id: 'initialCoachId', label: 'Coach Id' },
          ]}
          title="Users"
          onRowClick={(id) => setActiveUserId(id)}
        />
      </PortletBody>
      <AnimatedModal open={!!activeUserId} onClose={() => setActiveUserId(null)}>
        <EditUser userId={activeUserId} />
      </AnimatedModal>
    </Portlet>
  );
}

export default Users;
