import { TextField, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import { actions, amoTypeLabels, selectAmoCategoryById } from '../../store/ducks/amo.duck';
import SimpleDropzone from '../../partials/content/SimpleDropzone';
import VideoDropzone from '../../partials/content/VideoDropzone';
import { selectLoadingIndicators } from '../../store/ducks/UI.duck';
import { useRouteMatch } from 'react-router-dom';


const EditAmo = ({ amoId }) => {

  const match = useRouteMatch();
  const amoCategoryId = +match.params.amoCategoryId;
  const amoCategoryLevelId = +match.params.amoCategoryLevelId;
  const amoCategory = useSelector(state => selectAmoCategoryById(amoCategoryId)(state));
  const loadingIndicators = useSelector(selectLoadingIndicators);
  const amoCategoryLevels = get(amoCategory, 'levels', []);
  const amoCategoryLevel = amoCategoryLevels.find(acl => acl.id === amoCategoryLevelId);
  const amos = get(amoCategoryLevel, 'amos', []);
  const amo = amos.find(a => a.id === amoId);
  const { loadAmoCategories, updateAmo, uploadAmoFile } = actions;
  const dispatch = useDispatch();

  useEffect(() => {
    !amoCategory && dispatch(loadAmoCategories());
  }, [dispatch, amoCategory, loadAmoCategories]);

  return !amoCategory ? null : (
    <Portlet>
      <PortletBody>
        <div className="amo-row">
          <div className="amo-column">
            <FormControl>
              <InputLabel htmlFor="type">Type</InputLabel>
              <Select
                inputProps={{ name: 'Type', id: 'type' }}
                style={{ width: '300px' }}
                defaultValue={get(amo, 'type') ?? ''}
                onBlur={(e) => dispatch(updateAmo(amoId, { type: e.target.value }))}
              >
                {(amoCategoryLevel.title === 'Level 1' ?
                  ['workoutReminder', 'workoutUndoneReminder'] :
                  [
                    'planStarter',
                    'weekOpener',
                    'wellnessReminder',
                    'wellnessWeekendReminder',
                    'weekFailedRecap',
                    'weekFailed1to99Recap',
                    'weekSucceededRecap',
                  ]
                ).map(ac =>
                  <MenuItem key={ac} value={ac}>{amoTypeLabels[ac]}</MenuItem>
                )}
              </Select>
            </FormControl>
            <div className="amo-divider" />
            <FormControl>
              <InputLabel htmlFor="weekNumber">Week</InputLabel>
              <Select
                inputProps={{ name: 'Week', id: 'weekNumber' }}
                style={{ width: '300px' }}
                defaultValue={get(amo, 'weekNumber') ?? ''}
                onBlur={(e) => dispatch(updateAmo(amoId, { weekNumber: e.target.value }))}
              >
                <MenuItem value={0}>Week 0</MenuItem>
                <MenuItem value={1}>Week 1</MenuItem>
                <MenuItem value={2}>Week 2</MenuItem>
                <MenuItem value={3}>Week 3</MenuItem>
                <MenuItem value={4}>Week 4</MenuItem>
              </Select>
            </FormControl>
            <div className="amo-divider" />
            <TextField
              label="Title"
              className="input-standard"
              defaultValue={get(amo, 'title', '')}
              onBlur={(e) => dispatch(updateAmo(amoId, { title: e.target.value }))}
            />
            <div className="amo-divider" />
            <TextField
              label="Push Notification Text"
              className="input-standard"
              defaultValue={get(amo, 'textForPushNotification', '')}
              onBlur={(e) => dispatch(updateAmo(amoId, { textForPushNotification: e.target.value }))}
            />
            <div className="amo-divider" />
            <TextField
              label="Card Text"
              className="input-standard"
              defaultValue={get(amo, 'textForNotification', '')}
              onBlur={(e) => dispatch(updateAmo(amoId, { textForNotification: e.target.value }))}
            />
            <div className="amo-divider" />
            {get(amo, 'type') === 'workoutReminder' ?
              <>
                <TextField
                  label="Option 1 Label"
                  className="input-standard"
                  defaultValue={get(amo, 'data.option1Label', '')}
                  onBlur={(e) => dispatch(
                    updateAmo(amoId, { data: { ...get(amo, 'data', {}), option1Label: e.target.value } })
                  )}
                />
                <div className="amo-divider" />
                <TextField
                  label="Option 1 Follow Up Text"
                  className="input-standard"
                  defaultValue={get(amo, 'data.option1FollowUp', '')}
                  onBlur={(e) => dispatch(
                    updateAmo(amoId, { data: { ...get(amo, 'data', {}), option1FollowUp: e.target.value } })
                  )}
                />
                <div className="amo-divider" />
                <TextField
                  label="Option 2 Label"
                  className="input-standard"
                  defaultValue={get(amo, 'data.option2Label', '')}
                  onBlur={(e) => dispatch(
                    updateAmo(amoId, { data: { ...get(amo, 'data', {}), option2Label: e.target.value } })
                  )}
                />
                <div className="amo-divider" />
                <TextField
                  label="Option 2 Follow Up Text"
                  className="input-standard"
                  defaultValue={get(amo, 'data.option2FollowUp', '')}
                  onBlur={(e) => dispatch(
                    updateAmo(amoId, { data: { ...get(amo, 'data', {}), option2FollowUp: e.target.value } })
                  )}
                />
              </> :
              <TextField
                label="Card Text Follow Up"
                className="input-standard"
                defaultValue={get(amo, 'textFollowUpForNotification', '')}
                onBlur={(e) => dispatch(updateAmo(amoId, { textFollowUpForNotification: e.target.value }))}
              />
            }
          </div>
          <div className="amo-divider" />
          <div className="amo-column">
            {/* 
              <SimpleDropzone
                loading={loadingIndicators.includes(`amo_file_upload_thumbnail_${amoId}`)}
                title="Thumbnail"
                onDrop={(files) => files.length && dispatch(uploadAmoFile(amoId, files[0], 'thumbnail'))}
                url={get(amo, 'thumbnail', '')}
              />
              <div className="amo-divider" />  
            */}
            {get(amo, 'type') === 'workoutReminder' &&
              <>
                <SimpleDropzone
                  loading={loadingIndicators.includes(`amo_file_upload_data.option1FollowUpGif_${amoId}`)}
                  title="Option 1 Follow Up Gif"
                  onDelete={() => dispatch(uploadAmoFile(amoId, null, 'data.option1FollowUpGif'))}
                  onDrop={(files) => files.length && dispatch(uploadAmoFile(amoId, files[0], 'data.option1FollowUpGif'))}
                  url={get(amo, 'data.option1FollowUpGif', '')}
                />
                <div className="amo-divider" />
              </>
            }
            <VideoDropzone
              loading={loadingIndicators.includes(`amo_file_upload_video_${amoId}`)}
              title={get(amo, 'type') === 'workoutReminder' ? 'Option 2 Follow Up Video' : 'Video'}
              onDelete={() => dispatch(uploadAmoFile(amoId, null, 'video'))}
              onDrop={(files) => files.length && dispatch(uploadAmoFile(amoId, files[0], 'video'))}
              url={get(amo, 'video')}
              duration={get(amo, 'videoDuration')}
            />
          </div>
        </div>
      </PortletBody>
    </Portlet>
  );
}

EditAmo.propTypes = {
  amoId: PropTypes.number,
};

export default EditAmo;