import { TextField, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import Table from '../../partials/content/Table';
import { actions } from '../../store/ducks/plan.duck';
import { selectPlanById } from '../../store/ducks/plan.duck';
import { useHistory } from 'react-router-dom';
import SimpleDropzone from '../../partials/content/SimpleDropzone';
import { actions as planActions, selectCoaches } from '../../store/ducks/plan.duck';
import { selectLoadingIndicators } from '../../store/ducks/UI.duck';


function EditPlan({ match }) {

  const planId = +match.params.planId;
  const plan = useSelector(state => selectPlanById(planId)(state));
  const planLevels = get(plan, 'levels', []);
  const loadingIndicators = useSelector(selectLoadingIndicators);
  const coaches = useSelector(selectCoaches);
  const { createPlanLevel, deletePlanLevels, loadPlans, updatePlan, uploadPlanFile } = actions;
  const { loadCoaches } = planActions;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    planId && dispatch(loadPlans());
  }, [dispatch, planId, loadPlans]);

  useEffect(() => {
    !coaches?.length && dispatch(loadCoaches());
  }, [coaches, dispatch, loadCoaches]);

  const planLevelsRows = React.useMemo(() => {
    return planLevels.map(l => ({
      ...l,
      overview: `${l?.overview?.length > 50 ? `${l?.overview?.substring(0, 50)}...` : l?.overview}`,
    }));
  }, [planLevels]);

  return !plan ? null : (
    <Portlet>
      <PortletBody>
        <div className="amo-row">
          <div className="amo-column">
            <FormControl style={{ margin: '0 16px 0 0' }}>
              <InputLabel htmlFor="coach">Coach</InputLabel>
              <Select
                inputProps={{ name: 'Coach', id: 'coach' }}
                style={{ minWidth: '300px' }}
                defaultValue={get(plan, 'coachId')}
                onBlur={(e) => dispatch(updatePlan(planId, { ...plan, coachId: e.target.value }))}
              >
                {coaches.map(c =>
                  <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                )}
              </Select>
            </FormControl>
            <div className="amo-divider" />
            <FormControl style={{ margin: '0 16px 0 0' }}>
              <InputLabel htmlFor="coach">Title</InputLabel>
              <Select
                inputProps={{ name: 'Title', id: 'title' }}
                style={{ minWidth: '300px' }}
                defaultValue={get(plan, 'title')}
                onBlur={(e) => dispatch(updatePlan(planId, { ...plan, title: e.target.value }))}
              >
                <MenuItem value="Bodyweight">Bodyweight</MenuItem>
                <MenuItem value="Dumbbells">Dumbbells</MenuItem>
                <MenuItem value="Gym">Gym</MenuItem>
              </Select>
            </FormControl>
            <div className="amo-divider" />
            <TextField
              label="Focus"
              className="input-standard"
              defaultValue={get(plan, 'focus', '')}
              onBlur={(e) => dispatch(updatePlan(planId, { focus: e.target.value }))}
            />
          </div>
          <div className="amo-divider" />
          <SimpleDropzone
            loading={loadingIndicators.includes(`plan_file_upload_thumbnail_${planId}`)}
            title="Thumbnail"
            onDrop={(files) => files.length && dispatch(uploadPlanFile(planId, files[0], 'thumbnail'))}
            url={get(plan, 'thumbnail')}
          />
        </div>
        <div className="amo-divider" />
        <Table
          data={planLevelsRows}
          headCells={[
            { id: 'id', label: 'ID' },
            { id: 'title', label: 'Title' },
            { id: 'overview', label: 'Overview' },
            { id: 'createdAt', label: 'Created', isDateTime: true },
          ]}
          title="Plan Levels"
          onCreate={() => dispatch(createPlanLevel(planId, `Level ${planLevels.length + 1}`))}
          onDelete={(ids) => dispatch(deletePlanLevels(ids))}
          onRowClick={(id) => history.push(`/edit-plan/${planId}/edit-plan-level/${id}`)}
        />
      </PortletBody>
    </Portlet>
  );
}

EditPlan.propTypes = {
  match: PropTypes.object,
};

export default EditPlan;