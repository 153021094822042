import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../partials/content/Table';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import { actions, selectCoaches } from '../../store/ducks/plan.duck';
import { selectLoadingIndicators } from '../../store/ducks/UI.duck';


const Coaches = ({ history }) => {

  const coaches = useSelector(selectCoaches);
  const loadingIndicators = useSelector(selectLoadingIndicators);
  const { createCoach, loadCoaches } = actions;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCoaches());
  }, [dispatch, loadCoaches]);

  return (
    <Portlet>
      <PortletBody>
        <Table
          data={coaches || []}
          headCells={[
            { id: 'id', label: 'ID' },
            { id: 'avatar', isImage: true, label: 'Avatar' },
            { id: 'name', label: 'Name' },
            { id: 'description', label: 'Description' },
            { id: 'createdAt', label: 'Created', isDateTime: true },
          ]}
          isLoading={loadingIndicators.includes('coaches_loading')}
          title="Coaches"
          onCreate={() => dispatch(createCoach())}
          onRowClick={(id) => history.push(`/edit-coach/${id}`)}
        />
      </PortletBody>
    </Portlet>
  );
}

export default Coaches;
