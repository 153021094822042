import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../partials/content/Table';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import { actions, selectPlans } from '../../store/ducks/plan.duck';
import { actions as planActions, selectCoaches, selectCurrentCoachId } from '../../store/ducks/plan.duck';
import { selectLoadingIndicators } from '../../store/ducks/UI.duck';


const Plans = ({ history }) => {

  const plans = useSelector(selectPlans);
  const loadingIndicators = useSelector(selectLoadingIndicators);
  const coaches = useSelector(selectCoaches);
  const currentCoachId = useSelector(selectCurrentCoachId);
  const { createPlan, duplicatePlans, loadPlans } = actions;
  const { loadCoaches } = planActions;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadPlans());
  }, [dispatch, loadPlans]);

  useEffect(() => {
    !coaches?.length && dispatch(loadCoaches());
  }, [coaches, dispatch, loadCoaches]);

  return (
    <Portlet>
      <PortletBody>
        <Table
          data={(plans || [])
            .filter(wa => !currentCoachId ? true : (wa.coachId === currentCoachId))
            .map(p => ({
              ...p,
              coach: coaches.find(c => c.id === p.coachId)?.name,
            }))
          }
          headCells={[
            { id: 'id', label: 'ID' },
            { id: 'coach', label: 'Coach' },
            { id: 'title', label: 'Title' },
            { id: 'focus', label: 'Focus' },
            { id: 'thumbnail', isImage: true, label: 'Thumbnail' },
            { id: 'createdAt', label: 'Created', isDateTime: true },
          ]}
          isLoading={loadingIndicators.includes('plans_loading')}
          title="Plans"
          onCreate={() => dispatch(createPlan(currentCoachId))}
          onDuplicate={(ids) => dispatch(duplicatePlans(ids))}
          onRowClick={(id) => history.push(`/edit-plan/${id}`)}
        />
      </PortletBody>
    </Portlet>
  );
}

export default Plans;
