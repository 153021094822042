import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnimatedModal from '../../partials/content/AnimatedModal';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import Table from '../../partials/content/Table';
import { actions, selectExerciseGroups } from '../../store/ducks/workout.duck';
import { actions as planActions, selectCoaches, selectCurrentCoachId } from '../../store/ducks/plan.duck';
import EditExerciseGroup from './EditExerciseGroup';


const ExerciseGroups = () => {

  const exerciseGroups = useSelector(selectExerciseGroups);
  const coaches = useSelector(selectCoaches);
  const currentCoachId = useSelector(selectCurrentCoachId);
  const { createExerciseGroup, deleteExerciseGroups, loadExerciseGroups } = actions;
  const { loadCoaches } = planActions;
  const dispatch = useDispatch();

  const [activeExerciseGroupId, setActiveExerciseGroupId] = useState(null);

  useEffect(() => {
    dispatch(loadExerciseGroups());
  }, [dispatch, loadExerciseGroups]);

  useEffect(() => {
    !coaches?.length && dispatch(loadCoaches());
  }, [coaches, dispatch, loadCoaches]);

  return (
    <Portlet>
      <PortletBody>
        <Table
          data={(exerciseGroups || [])
            .filter(eg => !currentCoachId ? true : (eg.coachId === currentCoachId))
            .map(eg => ({
              ...eg,
              coach: coaches.find(c => c.id === eg.coachId)?.name,
              title2: `${eg?.title2?.length > 50 ? `${eg?.title2?.substring(0, 50)}...` : eg?.title2}`,
            }))
          }
          defaultOrderBy="id"
          defaultOrder="desc"
          headCells={[
            { id: 'id', label: 'ID' },
            { id: 'coach', label: 'Coach' },
            { id: 'title', label: 'Title' },
            { id: 'title2', label: 'System Title' },
            { id: 'setAmount', label: 'Rounds Amount' },
            { id: 'createdAt', label: 'Created', isDateTime: true },
          ]}
          title="Exercise Groups"
          onCreate={() => dispatch(createExerciseGroup(currentCoachId))}
          onDelete={(ids) => dispatch(deleteExerciseGroups(ids))}
          onRowClick={(id) => setActiveExerciseGroupId(id)}
        />
      </PortletBody>
      <AnimatedModal open={!!activeExerciseGroupId} onClose={() => setActiveExerciseGroupId(null)}>
        <EditExerciseGroup exerciseGroupId={activeExerciseGroupId} />
      </AnimatedModal>
    </Portlet>
  );
}

export default ExerciseGroups;
