import { TextField, Select, MenuItem, FormControl, InputLabel, CircularProgress } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import '../../sass/pages/workout/editExercise.scss';
import { actions as planActions, selectCoaches, selectCurrentCoachId } from '../../store/ducks/plan.duck';
import { actions, selectExerciseGroupById, selectExercises } from '../../store/ducks/workout.duck';
import EditExercise from './EditExercise';


const EditExerciseGroup = ({ exerciseGroupId }) => {

  const exerciseGroup = useSelector(selectExerciseGroupById(exerciseGroupId));
  let exercises = useSelector(selectExercises);
  const coaches = useSelector(selectCoaches);
  const currentCoachId = useSelector(selectCurrentCoachId);
  const {
    createExerciseGroupExercise,
    deleteExerciseGroupExercise,
    loadExerciseGroup,
    loadExercises,
    updateExerciseGroup,
    updateExerciseGroupExerciseOrder,
  } = actions;
  const { loadCoaches } = planActions;
  const dispatch = useDispatch();

  const [activeExerciseIndex, setActiveExerciseIndex] = useState(0);
  const activeExercise = get(exerciseGroup, ['exercises', activeExerciseIndex]);
  const [exerciseToAdd, setExerciseToAdd] = useState(null);

  useEffect(() => {
    exerciseGroupId && dispatch(loadExerciseGroup(exerciseGroupId));
  }, [dispatch, exerciseGroupId, loadExerciseGroup]);

  useEffect(() => {
    dispatch(loadExercises());
  }, [dispatch, loadExercises]);

  useEffect(() => {
    !coaches?.length && dispatch(loadCoaches());
  }, [coaches, dispatch, loadCoaches]);

  if (!exerciseGroup) {
    return <CircularProgress />;
  }

  exercises = exercises.sort((a, b) => a.title > b.title ? 1 : -1);
  exercises = exercises.filter(e => !(exerciseGroup.exercises || []).map(ex => ex.id).includes(e.id));

  return (
    <Portlet style={{ maxWidth: '100%' }}>
      <PortletBody>
        <div className="amo-row">
          <FormControl>
            <InputLabel htmlFor="coach">Coach</InputLabel>
            <Select
              inputProps={{ name: 'Coach', id: 'coach' }}
              style={{ minWidth: '300px' }}
              defaultValue={get(exerciseGroup, 'coachId')}
              onBlur={(e) => dispatch(updateExerciseGroup(exerciseGroup.id, { ...exerciseGroup, coachId: e.target.value }))}
            >
              {coaches.map(c =>
                <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
              )}
            </Select>
          </FormControl>
          <div className="amo-divider" />
          <FormControl style={{ minWidth: 160 }}>
            <InputLabel htmlFor="set-amount">Title</InputLabel>
            <Select
              defaultValue={exerciseGroup.title}
              inputProps={{ name: 'Title', id: 'title' }}
              onBlur={(e) => dispatch(updateExerciseGroup(exerciseGroup.id, { title: e.target.value }))}
            >
              <MenuItem value="Warm Up">Warm Up</MenuItem>
              <MenuItem value="Cool Down">Cool Down</MenuItem>
              <MenuItem value="Workout">Workout</MenuItem>
              <MenuItem value="Giant Set 1">Giant Set 1</MenuItem>
              <MenuItem value="Giant Set 2">Giant Set 2</MenuItem>
              <MenuItem value="Giant Set 3">Giant Set 3</MenuItem>
              <MenuItem value="Giant Set 4">Giant Set 4</MenuItem>
              <MenuItem value="Superset 1">Superset 1</MenuItem>
              <MenuItem value="Superset 2">Superset 2</MenuItem>
              <MenuItem value="Superset 3">Superset 3</MenuItem>
              <MenuItem value="Superset 4">Superset 4</MenuItem>
              <MenuItem value="Superset 5">Superset 5</MenuItem>
              <MenuItem value="Superset 6">Superset 6</MenuItem>
              <MenuItem value="Superset 7">Superset 7</MenuItem>
              <MenuItem value="Superset 8">Superset 8</MenuItem>
              <MenuItem value="Triset 1">Triset 1</MenuItem>
              <MenuItem value="Triset 2">Triset 2</MenuItem>
              <MenuItem value="Triset 3">Triset 3</MenuItem>
              <MenuItem value="Triset 4">Triset 4</MenuItem>
              <MenuItem value="Exercise 1">Exercise 1</MenuItem>
              <MenuItem value="Exercise 2">Exercise 2</MenuItem>
              <MenuItem value="Exercise 3">Exercise 3</MenuItem>
              <MenuItem value="Exercise 4">Exercise 4</MenuItem>
              <MenuItem value="Exercise 5">Exercise 5</MenuItem>
              <MenuItem value="Exercise 6">Exercise 6</MenuItem>
              <MenuItem value="Exercise 7">Exercise 7</MenuItem>
              <MenuItem value="Exercise 8">Exercise 8</MenuItem>
              <MenuItem value="Finisher">Finisher</MenuItem>
              <MenuItem value="Mindfulness Stretch">Mindfulness Stretch</MenuItem>
            </Select>
          </FormControl>
          <div className="amo-divider" />
          <TextField
            id="standard-with-placeholder"
            label="Exercise Group System Title"
            // className="input-standard"
            defaultValue={exerciseGroup.title2 || ''}
            onBlur={(e) => dispatch(updateExerciseGroup(exerciseGroup.id, { title2: e.target.value.trim() }))}
            style={{ minWidth: '300px' }}
          />
          <div className="amo-divider" />
          <FormControl style={{ minWidth: 100 }}>
            <InputLabel htmlFor="set-amount">Rounds Amount</InputLabel>
            <Select
              defaultValue={exerciseGroup.setAmount}
              inputProps={{ name: 'Rounds Amount', id: 'set-amount' }}
              onBlur={(e) => dispatch(updateExerciseGroup(exerciseGroup.id, { setAmount: e.target.value }))}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="amo-divider" />
        <PortletBody style={{ boxShadow: '0px 0px 13px 0px rgba(82, 63, 105, 0.2)' }}>
          <h2 style={{ margin: '8px 0 8px 0' }}>
            EXERCISES:
          </h2>
          {(exerciseGroup.exercises || []).map((exercise, index) =>
            <div
              className="edit-workout-exercise"
              key={exercise.id}
              onClick={() => { setActiveExerciseIndex(null); setTimeout(() => setActiveExerciseIndex(index), 10); }}
            >
              <span>
                {index > 0 &&
                  <ArrowUpwardIcon
                    fontSize="large"
                    onClick={(e) => {
                      dispatch(updateExerciseGroupExerciseOrder(exerciseGroup.id, exercise.id, index));
                      e.stopPropagation();
                    }}
                  />
                }
                <DeleteForeverIcon
                  fontSize="large"
                  onClick={(e) => { dispatch(deleteExerciseGroupExercise(exerciseGroup.id, exercise.id)); e.stopPropagation(); }}
                />
              </span>
              <span style={exercise.id === activeExercise?.id ? { fontWeight: 'bold' } : {}} >
                {exercise?.ExerciseGroupExercises?.order}
                {' - '}
                [{exercise?.id}]
                {' - '}
                {exercise?.title || '<title missing>'}
              </span>
            </div>
          )}
          <div className="amo-divider" />
          <div className="amo-column">
            <FormControl style={{ marginRight: 16 }}>
              <InputLabel htmlFor="exercise">Exercise to Add</InputLabel>
              <Select
                style={{ minWidth: 300 }}
                value={exerciseToAdd}
                onChange={(e) => setExerciseToAdd(e.target.value)}
                inputProps={{ name: 'Exercise to Add', id: 'exercise' }}
              >
                {(exercises || [])
                  .filter(e => !currentCoachId ? true : (e.coachId === currentCoachId))
                  .map(e =>
                    <MenuItem value={e.id}>{e.title} [{e.id}]</MenuItem>
                  )
                }
              </Select>
            </FormControl>
            <div
              className="edit-workout-add-element small"
              onClick={() => {
                dispatch(createExerciseGroupExercise(exerciseGroup.id, exerciseToAdd));
                setExerciseToAdd(null);
              }}
            >
              + Add Selected Exercise
            </div>
          </div>
        </PortletBody>
        {/* </div> */}
        <div className="amo-divider" />
        {!!activeExercise &&
          <EditExercise exerciseId={activeExercise.id} />
        }
      </PortletBody>
    </Portlet >
  );
}

EditExerciseGroup.propTypes = {
  exerciseGroupId: PropTypes.number,
};

export default EditExerciseGroup;
