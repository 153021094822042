import { TextField } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import Table from '../../partials/content/Table';
import { actions, selectAmoCategoryById } from '../../store/ducks/amo.duck';
import { selectLoadingIndicators } from '../../store/ducks/UI.duck';


const EditAmoCategory = ({ match }) => {

  const amoCategoryId = +match.params.amoCategoryId;
  const loadingIndicators = useSelector(selectLoadingIndicators);
  const amoCategory = useSelector(state => selectAmoCategoryById(amoCategoryId)(state));
  const amoCategoryLevels = get(amoCategory, 'levels', []);
  const { loadAmoCategories, createAmoCategoryLevel, deleteAmoCategoryLevels, duplicateAmoCategoryLevels } = actions;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    !amoCategory && dispatch(loadAmoCategories());
  }, [dispatch, amoCategory, loadAmoCategories]);

  return !amoCategory ? null : (
    <Portlet>
      <PortletBody>
        <div className="amo-row">
          <div className="amo-column">
            <TextField
              label="Title"
              className="input-standard"
              disabled
              value={get(amoCategory, 'title', '')}
            />
          </div>
        </div>
        <br />
        <br />
        <Table
          data={amoCategoryLevels || []}
          headCells={[
            { id: 'id', label: 'ID' },
            { id: 'title', label: 'Title' },
            { id: 'createdAt', label: 'Created', isDateTime: true },
          ]}
          isLoading={loadingIndicators.includes('amo_category_levels_loading')}
          title="Amo Category Levels"
          onCreate={() => dispatch(createAmoCategoryLevel(amoCategoryId, `Level ${amoCategoryLevels.length + 1}`))}
          onDuplicate={(ids) => dispatch(duplicateAmoCategoryLevels(ids))}
          onDelete={(ids) => dispatch(deleteAmoCategoryLevels(ids))}
          onRowClick={(id) => history.push(`/edit-amo-category/${amoCategoryId}/edit-amo-category-level/${id}`)}
        />
      </PortletBody>
    </Portlet>
  );
}

EditAmoCategory.propTypes = {
  match: PropTypes.object,
};

export default EditAmoCategory;
