import {
  Checkbox,
  CircularProgress, IconButton, lighten, makeStyles, Paper, TablePagination, TableSortLabel, TextField,
  Toolbar, Tooltip, Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import clsx from 'clsx';
import { format } from 'date-fns';
import { debounce, isFunction } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import '../../sass/components/_table.scss';


function descendingComparator(a, b, orderBy) {
  const aa = a[orderBy] ?? '';
  const bb = b[orderBy] ?? '';
  if (bb < aa) {
    return -1;
  }
  if (bb > aa) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, order, orderBy) {
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function filterData(array, headCells, searchText) {
  return !searchText ? array : array.filter(row => {
    return headCells.some(hc => {
      return `${row[hc.id]}`.toLowerCase().includes(searchText);
    });
  });
}

function EnhancedTableHead(props) {
  const { headCells, isWithCheckbox, onRequestSort, order, orderBy } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <thead>
      <tr>
        {isWithCheckbox &&
          <th style={{ whiteSpace: 'nowrap', textAlign: 'center', padding: 15 }}></th>
        }
        {headCells.map(headCell =>
          <th key={headCell.id} style={{ whiteSpace: 'nowrap', textAlign: 'center', padding: '10px 5px 10px 30px' }}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <span style={{ whiteSpace: 'nowrap' }}>
                {headCell.label}
              </span>
            </TableSortLabel>
          </th>
        )}
      </tr>
    </thead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
}));

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85)
  },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
  }
}));

const EnhancedTableToolbar = ({
  count,
  numSelected,
  onCreate,
  onDelete,
  onDuplicate,
  onMoveUp,
  onPageChange,
  onRowsPerPageChange,
  onSearch,
  page,
  rowsPerPage,
  title,
}) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={clsx(classes.root, { [classes.highlight]: numSelected > 0 })} >
      <div className={classes.title}>
        {!!numSelected &&
          <Typography color="inherit" variant="subtitle1" style={{ marginLeft: 20 }}>
            {numSelected} selected
          </Typography>
        }
        {!numSelected &&
          <Typography variant="h5" id="tableTitle" style={{ margin: '12px 0 0 20px', whiteSpace: 'nowrap' }}>
            {title}
          </Typography>
        }
        {!numSelected && count > 50 &&
          <TablePagination
            rowsPerPageOptions={[50, 500, 5000]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            style={{ marginLeft: 20, minWidth: 440 }}
          />
        }
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <div style={{ display: 'flex' }}>
          {!numSelected && isFunction(onSearch) &&
            <TextField
              label="Search"
              className="input-standard"
              defaultValue=""
              onChange={(e) => onSearch(e.target.value.toLowerCase())}
            />
          }
          {!numSelected && isFunction(onCreate) &&
            <Tooltip title="Create" onClick={onCreate}>
              <IconButton aria-label="Create">
                <AddIcon />
              </IconButton>
            </Tooltip>
          }
          {!!numSelected && isFunction(onMoveUp) &&
            <Tooltip title="Move Up (Decrease Order)" onClick={onMoveUp}>
              <IconButton aria-label="Move Up (Decrease Order)">
                <ArrowUpwardIcon />
              </IconButton>
            </Tooltip>
          }
          {!!numSelected && isFunction(onDuplicate) &&
            <Tooltip title="Duplicate" onClick={onDuplicate}>
              <IconButton aria-label="Duplicate">
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          }
          {!!numSelected && isFunction(onDelete) &&
            <Tooltip title="Delete" onClick={onDelete}>
              <IconButton aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          }
        </div>
      </div>
    </Toolbar>
  );
};

export default function EnhancedTable(props) {
  const {
    data,
    defaultOrder,
    defaultOrderBy,
    headCells,
    isLoading,
    onCreate,
    onDelete,
    onDuplicate,
    onMoveUp,
    onRowClick,
    title,
  } = props;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [order, setOrder] = React.useState(defaultOrder || 'asc');
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy || 'id');
  const [selected, setSelected] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const debouncedSetSearchText = React.useMemo(() => debounce(setSearchText, 500), []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function checkRow(_, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  }

  async function onMoveUpSelected() {
    if (!onMoveUp) return;
    await onMoveUp(...selected);
  }

  async function deleteSelected() {
    if (!onDelete) return;
    const res = await onDelete(selected);
    if (res) setSelected([]);
  }

  async function duplicateSelected() {
    if (!onDuplicate) return;
    const res = await onDuplicate(selected);
    if (res) setSelected([]);
  }

  const isWithCheckbox = !!onDelete || !!onDuplicate;
  const isSelected = name => selected.indexOf(name) !== -1;
  const filteredData = React.useMemo(
    () => !searchText ? data : filterData(data, headCells, searchText)
    , [searchText, headCells, data]);

  return (
    <Paper className={classes.paper}>
      <EnhancedTableToolbar
        count={filteredData.length}
        numSelected={selected.length}
        page={page}
        title={title}
        onCreate={onCreate}
        onDelete={isFunction(onDelete) ? deleteSelected : undefined}
        onDuplicate={isFunction(onDuplicate) ? duplicateSelected : undefined}
        onMoveUp={isFunction(onMoveUp) && selected.length === 1 ? onMoveUpSelected : undefined}
        onPageChange={(_, newPage) => setPage(newPage)}
        onRowsPerPageChange={(e) => {
          setPage(0)
          setRowsPerPage(e.target.value);
        }}
        onSearch={text => debouncedSetSearchText(text)}
        rowsPerPage={rowsPerPage}
      />
      <div style={{ borderRadius: 5, width: '100%', overflow: 'scroll' }}>
        {isLoading &&
          <div className="loading-wrapper">
            <CircularProgress />
          </div>
        }
        <table style={{ borderTop: '1px solid lightgrey', width: '100%' }}>
          <EnhancedTableHead
            classes={classes}
            headCells={headCells}
            isWithCheckbox={isWithCheckbox}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
          />
          <tbody>
            {stableSort(filteredData, order, orderBy)
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((row, index) =>
                <tr
                  className="table-row"
                  key={row.id}
                  style={{ cursor: onRowClick ? 'pointer' : 'unset' }}
                >
                  {isWithCheckbox &&
                    <td style={{ textAlign: 'center', borderTop: '1px solid lightgrey' }}>
                      <Checkbox
                        checked={isSelected(row.id)}
                        inputProps={{ "aria-labelledby": `enhanced-table-checkbox-${index}` }}
                        onClick={event => checkRow(event, row.id)}
                        style={{ height: 30 }}
                      />
                    </td>
                  }
                  {headCells.map(cell =>
                    <td
                      key={cell.id}
                      onClick={() => onRowClick && onRowClick(row.id)}
                      style={{
                        borderTop: '1px solid lightgrey',
                        fontSize: 12,
                        height: cell.isImage && row[cell.id] ? 70 : 'unset',
                        maxWidth: cell.width || 'unset',
                        minWidth: cell.width || 'unset',
                        padding: 8,
                        textAlign: 'center',
                      }}
                    >
                      {cell.isImage ?
                        <img
                          alt=''
                          src={row[cell.id]}
                          style={{ maxHeight: 60, margin: -10 }}
                        /> :
                        <span style={cell.isMultiline ? {} : { whiteSpace: 'nowrap' }}>
                          {(cell.isDate || cell.isDateTime) ?
                            <>
                              {!row[cell.id] ?
                                '' :
                                format(new Date(row[cell.id]), `dd.MM.yyyy${cell.isDateTime ? ', hh:mm' : ''}`)
                              }
                            </> :
                            row[cell.id]
                          }
                        </span>
                      }
                    </td>
                  )}
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </Paper>
  );
}

EnhancedTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  onDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
  onRowClick: PropTypes.func
};
