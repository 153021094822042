import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as amo from './ducks/amo.duck';
import * as auth from './ducks/auth.duck';
import * as plan from './ducks/plan.duck';
import * as promoCode from './ducks/promo.code';
import * as theme from './ducks/theme.duck';
import * as workout from './ducks/workout.duck';
import * as user from './ducks/user.duck';
import * as userWorkout from './ducks/user.workout.duck';
import * as UI from './ducks/UI.duck';

import { metronic } from '../../_metronic';


export const rootReducer = combineReducers({
  amo: amo.reducer,
  auth: auth.reducer,
  builder: metronic.builder.reducer,
  i18n: metronic.i18n.reducer,
  plan: plan.reducer,
  promoCode: promoCode.reducer,
  theme: theme.reducer,
  workout: workout.reducer,
  user: user.reducer,
  userWorkout: userWorkout.reducer,
  UI: UI.reducer,
});

export function* rootSaga() {
  yield all([
    amo.saga(),
    auth.saga(),
    plan.saga(),
    promoCode.saga(),
    theme.saga(),
    workout.saga(),
    user.saga(),
    userWorkout.saga(),
    UI.saga(),
  ]);
}
