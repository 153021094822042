import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnimatedModal from '../../partials/content/AnimatedModal';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import Table from '../../partials/content/Table';
import { actions, selectExercises } from '../../store/ducks/workout.duck';
import { actions as planActions, selectCoaches, selectCurrentCoachId } from '../../store/ducks/plan.duck';
import EditExercise from './EditExercise';


const Exercises = () => {

  const exercises = useSelector(selectExercises);
  const coaches = useSelector(selectCoaches);
  const currentCoachId = useSelector(selectCurrentCoachId);
  const { createExercise, deleteExercises, duplicateExercises, loadExercises } = actions;
  const { loadCoaches } = planActions;
  const dispatch = useDispatch();

  const [activeExerciseId, setActiveExerciseId] = useState(null);

  useEffect(() => {
    dispatch(loadExercises());
  }, [dispatch, loadExercises]);

  useEffect(() => {
    !coaches?.length && dispatch(loadCoaches());
  }, [coaches, dispatch, loadCoaches]);

  return (
    <Portlet>
      <PortletBody>
        <Table
          data={(exercises || [])
            .filter(e => !currentCoachId ? true : (e.coachId === currentCoachId))
            .map(e => ({
              ...e,
              coach: coaches.find(c => c.id === e.coachId)?.name,
            }))
          }
          defaultOrder="desc"
          defaultOrderBy="bodyPart"
          headCells={[
            { id: 'id', label: 'ID' },
            { id: 'coach', label: 'Coach' },
            { id: 'type', label: 'Type' },
            { id: 'bodyPart', label: 'Body Part' },
            { id: 'rank', label: 'Rank' },
            { id: 'title', label: 'Title' },
            { id: 'thumbnail', isImage: true, label: 'Thumbnail' },
            { id: 'createdAt', label: 'Created', isDateTime: true },
          ]}
          title="Exercises"
          onCreate={() => dispatch(createExercise(currentCoachId))}
          onDelete={(ids) => dispatch(deleteExercises(ids))}
          onDuplicate={(ids) => dispatch(duplicateExercises(ids))}
          onRowClick={(id) => setActiveExerciseId(id)}
        />
      </PortletBody>
      <AnimatedModal open={!!activeExerciseId} onClose={() => setActiveExerciseId(null)}>
        <Portlet>
          <EditExercise exerciseId={activeExerciseId} />
        </Portlet>
      </AnimatedModal>
    </Portlet>
  );
}

export default Exercises;
