import { TextField } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import Table from '../../partials/content/Table';
import { actions, selectCurrentCoachId, selectPlanLevelByPlanIdAndId } from '../../store/ducks/plan.duck';
import { actions as workoutsActions, selectWorkoutsByPlanLevelId } from '../../store/ducks/workout.duck';


function EditPlanLevel({ match }) {

  const planId = +match.params.planId;
  const planLevelId = +match.params.planLevelId;
  const planLevel = useSelector(state => selectPlanLevelByPlanIdAndId(planId, planLevelId)(state));
  const workouts = useSelector(state => selectWorkoutsByPlanLevelId(planLevelId)(state));
  const currentCoachId = useSelector(selectCurrentCoachId);
  const { loadPlans, updatePlanLevel } = actions;
  const { createWorkout, deleteWorkouts, duplicateWorkouts, loadWorkouts, updateWorkoutOrder } = workoutsActions;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    !planLevel && dispatch(loadPlans());
  }, [dispatch, planLevel, loadPlans]);

  useEffect(() => {
    dispatch(loadWorkouts());
  }, [dispatch, loadWorkouts]);

  const onMoveUp = (workoutId) => {
    const workout = workouts.find(w => w.id === workoutId);
    if (!workout) return;
    dispatch(updateWorkoutOrder(workoutId, workout.order > 1 ? workout.order - 1 : 1));
  }

  return !planLevel ? null : (
    <Portlet>
      <PortletBody>
        <div className="amo-row">
          <TextField
            label="Title"
            className="input-standard"
            defaultValue={get(planLevel, 'title', '')}
            onBlur={(e) => dispatch(updatePlanLevel(planLevelId, { title: e.target.value }))}
          />
          <TextField
            label="Overview"
            className="input-standard"
            defaultValue={get(planLevel, 'overview', '')}
            onBlur={(e) => dispatch(updatePlanLevel(planLevelId, { overview: e.target.value }))}
          />
        </div>
        <div className="amo-divider" />
        {[0, 1, 2, 3, 4].map(weekNumber =>
          <TextField
            label={`Week ${weekNumber} Overview`}
            className="input-standard"
            defaultValue={get(planLevel, ['weeksOverviews', weekNumber], '')}
            key={weekNumber}
            onBlur={(e) => {
              let weeksOverviews = get(planLevel, 'weeksOverviews') || ['', '', '', '', ''];
              weeksOverviews[weekNumber] = e.target.value;
              dispatch(updatePlanLevel(planLevelId, { weeksOverviews }));
            }}
          />
        )}
        <br />
        <br />
        {[0, 1, 2, 3, 4].map(weekNumber => {
          const planLevelWeekWorkouts = workouts.filter(w => w.weekOfPlan === weekNumber) || [];
          return (
            <>
              <Table
                data={planLevelWeekWorkouts}
                defaultOrderBy="order"
                headCells={[
                  { id: 'id', label: 'ID' },
                  { id: 'weekOfPlan', label: 'Plan Level Week' },
                  { id: 'order', label: 'Order' },
                  { id: 'title', label: 'Title' },
                  { id: 'type', label: 'Type' },
                  { id: 'thumbnail', isImage: true, label: 'Thumbnail' },
                  { id: 'cover', isImage: true, label: 'Cover' },
                  { id: 'createdAt', label: 'Created', isDateTime: true },
                ]}
                title={`Week ${weekNumber} Workouts`}
                onCreate={() => dispatch(createWorkout({
                  coachId: currentCoachId, planLevelId, weekOfPlan: weekNumber, order: planLevelWeekWorkouts.length + 1
                }))}
                onDelete={(ids) => dispatch(deleteWorkouts(ids))}
                onDuplicate={(ids) => dispatch(duplicateWorkouts(ids))}
                onMoveUp={onMoveUp}
                onRowClick={(id) => history.push(`/edit-workout/${id}`)}
              />
              <div className="amo-divider" />
            </>
          );
        })}
      </PortletBody>
    </Portlet>
  );
}

EditPlanLevel.propTypes = {
  match: PropTypes.object,
};

export default EditPlanLevel;