import { TextField, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import { actions, selectCoachById } from '../../store/ducks/plan.duck';
import SimpleDropzone from '../../partials/content/SimpleDropzone';
import { selectLoadingIndicators } from '../../store/ducks/UI.duck';


function EditCoach({ match }) {

  const coachId = +match.params.coachId;
  const coach = useSelector(state => selectCoachById(coachId)(state));
  const loadingIndicators = useSelector(selectLoadingIndicators);
  const { loadCoaches, updateCoach, uploadCoachFile } = actions;
  const dispatch = useDispatch();

  useEffect(() => {
    coachId && dispatch(loadCoaches());
  }, [dispatch, coachId, loadCoaches]);

  return !coach ? null : (
    <Portlet>
      <PortletBody>
        <div className="amo-row">
          <div className="amo-column">
            <FormControl style={{ margin: '0 16px 0 0' }}>
              <InputLabel htmlFor="coach">Available for users</InputLabel>
              <Select
                inputProps={{ name: 'Available for users', id: 'isAvailable' }}
                style={{ minWidth: '300px' }}
                defaultValue={get(coach, 'isAvailable')}
                onBlur={(e) => dispatch(updateCoach(coachId, { isAvailable: e.target.value }))}
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
            <div className="amo-divider" />
            <TextField
              label="Name"
              className="input-standard"
              defaultValue={get(coach, 'name', '')}
              onBlur={(e) => dispatch(updateCoach(coachId, { name: e.target.value }))}
            />
            <div className="amo-divider" />
            <TextField
              label="Description"
              className="input-standard"
              defaultValue={get(coach, 'description', '')}
              onBlur={(e) => dispatch(updateCoach(coachId, { description: e.target.value }))}
            />
          </div>
          <div className="amo-divider" />
          <SimpleDropzone
            loading={loadingIndicators.includes(`coach_file_upload_avatar_${coachId}`)}
            title="Avatar"
            onDrop={(files) => files.length && dispatch(uploadCoachFile(coachId, files[0], 'avatar'))}
            url={get(coach, 'avatar')}
          />
          <div className="amo-divider" />
          <SimpleDropzone
            loading={loadingIndicators.includes(`coach_file_upload_cover_${coachId}`)}
            title="Cover"
            onDrop={(files) => files.length && dispatch(uploadCoachFile(coachId, files[0], 'cover'))}
            url={get(coach, 'cover')}
          />
          <div className="amo-divider" />
          <SimpleDropzone
            loading={loadingIndicators.includes(`coach_file_upload_subscriptionCover_${coachId}`)}
            title="Subscription Cover"
            onDrop={(files) => files.length && dispatch(uploadCoachFile(coachId, files[0], 'subscriptionCover'))}
            url={get(coach, 'subscriptionCover')}
          />
        </div>
      </PortletBody>
    </Portlet>
  );
}

EditCoach.propTypes = {
  match: PropTypes.object,
};

export default EditCoach;