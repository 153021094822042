import axios from 'axios';
import { cloneDeep, get } from 'lodash';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { uploadFile } from '../../../utils';
import { actions as UIActions } from './UI.duck';


export const actionTypes = {
  // AMO CATEGORIES
  LoadAmoCategories: '[LoadAmoCategories] Action',
  LoadAmoCategoriesSuccess: '[LoadAmoCategoriesSuccess] Action',
  // AMO CATEGORY LEVELS
  CreateAmoCategoryLevel: '[CreateAmoCategoryLevel] Action',
  CreateAmoCategoryLevelSuccess: '[CreateAmoCategoryLevelSuccess] Action',
  DuplicateAmoCategoryLevels: '[DuplicateAmoCategoryLevels] Action',
  UpdateAmoCategoryLevel: '[UpdateAmoCategoryLevel] Action',
  UpdateAmoCategoryLevelSuccess: '[UpdateAmoCategoryLevelSuccess] Action',
  DeleteAmoCategoryLevels: '[DeleteAmoCategoryLevels] Action',
  DeleteAmoCategoryLevelsSuccess: '[DeleteAmoCategoryLevelsSuccess] Action',
  // AMOS
  CreateAmo: '[CreateAmo] Action',
  CreateAmoSuccess: '[CreateAmoSuccess] Action',
  UpdateAmo: '[UpdateAmo] Action',
  UploadAmoFile: '[UploadAmoFile] Action',
  UpdateAmoSuccess: '[UpdateAmoSuccess] Action',
  DeleteAmos: '[DeleteAmos] Action',
  DeleteAmosSuccess: '[DeleteAmosSuccess] Action',
};

const initialPlansState = {
  amoCategories: [],
};

export const reducer =  (state = initialPlansState, action) => {
  switch (action.type) {
    // AMO CATEGORIES
    case actionTypes.LoadAmoCategoriesSuccess: {
      const { amoCategories } = action.payload;
      return { ...state, amoCategories };
    }
    // AMO CATEGORY LEVELS
    case actionTypes.CreateAmoCategoryLevelSuccess: {
      const { amoCategoryId, amoCategoryLevel } = action.payload;
      const amoCategoryIndex = state.amoCategories.findIndex(p => p.id === amoCategoryId);
      const amoCategory = cloneDeep(state.amoCategories[amoCategoryIndex]);
      amoCategory.levels = [...amoCategory.levels, amoCategoryLevel];
      state.amoCategories[amoCategoryIndex] = amoCategory;
      return state;
    }
    case actionTypes.UpdateAmoCategoryLevelSuccess: {
      const { amoCategoryLevel } = action.payload;
      const amoCategoryIndex = state.amoCategories.findIndex(ac => ac.levels.some(acl => acl.id === amoCategoryLevel.id));
      const amoCategory = cloneDeep(state.amoCategories[amoCategoryIndex]);
      const amoCategoryLevelIndex = amoCategory.levels.findIndex(acl => acl.id === amoCategoryLevel.id);
      amoCategory.levels[amoCategoryLevelIndex] = { ...amoCategory.levels[amoCategoryLevelIndex], amoCategoryLevel };
      state.amoCategories[amoCategoryIndex] = amoCategory;
      return state;
    }
    case actionTypes.DeleteAmoCategoryLevelsSuccess: {
      const { ids } = action.payload;
      let amoCategories = cloneDeep(state.amoCategories);
      amoCategories = amoCategories.map(amoCategory => ({
        ...amoCategory,
        levels: amoCategory.levels.filter(ac => !ids.includes(ac.id)),
      }));
      return { ...state, amoCategories };
    }
    // AMOS
    case actionTypes.CreateAmoSuccess: {
      const { amoCategoryLevelId, amo } = action.payload;
      let amoCategories = cloneDeep(state.amoCategories);
      amoCategories = amoCategories.map(amoCategory => ({
        ...amoCategory,
        levels: amoCategory.levels.map(amoCategoryLevel => ({
          ...amoCategoryLevel,
          amos: amoCategoryLevel.id === amoCategoryLevelId ? [...(amoCategoryLevel.amos || []), amo] : amoCategoryLevel.amos,
        })),
      }));
      return { ...state, amoCategories };
    }
    case actionTypes.UpdateAmoSuccess: {
      const { id, amo } = action.payload;
      let amoCategories = cloneDeep(state.amoCategories);
      amoCategories = amoCategories.map(amoCategory => ({
        ...amoCategory,
        levels: amoCategory.levels.map(amoCategoryLevel => ({
          ...amoCategoryLevel,
          amos: amoCategoryLevel.amos.map(a => a.id === id ? { ...a, ...amo } : a)
        }))
      }));
      return { ...state, amoCategories };
    }
    case actionTypes.DeleteAmosSuccess: {
      const { ids } = action.payload;
      let amoCategories = cloneDeep(state.amoCategories);
      amoCategories = amoCategories.map(amoCategory => ({
        ...amoCategory,
        levels: amoCategory.levels.map(amoCategoryLevel => ({
          ...amoCategoryLevel,
          amos: amoCategoryLevel.amos.filter(a => !ids.includes(a.id))
        })),
      }));
      return { ...state, amoCategories };
    }
    default:
      return state;
  }
}

export const selectAmoCategories = (state) => get(state, 'amo.amoCategories', []);
export const selectAmoCategoryById = (amoCategoryId) => (state) => {
  const amoCategories = selectAmoCategories(state);
  return amoCategories.find(ac => ac.id === amoCategoryId);
}

export const actions = {
  // AMO CATEGORIES
  loadAmoCategories: () => ({ type: actionTypes.LoadAmoCategories }),
  loadAmoCategoriesSuccess: (amoCategories) => ({ type: actionTypes.LoadAmoCategoriesSuccess, payload: { amoCategories } }),
  // AMO CATEGORY LEVELS
  createAmoCategoryLevel: (amoCategoryId, title) => ({ type: actionTypes.CreateAmoCategoryLevel, payload: { amoCategoryId, title } }),
  createAmoCategoryLevelSuccess: (amoCategoryId, amoCategoryLevel) => ({ type: actionTypes.CreateAmoCategoryLevelSuccess, payload: { amoCategoryId, amoCategoryLevel } }),
  duplicateAmoCategoryLevels: (ids) => ({ type: actionTypes.DuplicateAmoCategoryLevels, payload: { ids } }),
  updateAmoCategoryLevel: (id, amoCategoryLevel) => ({ type: actionTypes.UpdateAmoCategoryLevel, payload: { id, amoCategoryLevel } }),
  updateAmoCategoryLevelSuccess: (amoCategoryLevel) => ({ type: actionTypes.UpdateAmoCategoryLevelSuccess, payload: { amoCategoryLevel } }),
  deleteAmoCategoryLevels: (ids) => ({ type: actionTypes.DeleteAmoCategoryLevels, payload: { ids } }),
  deleteAmoCategoryLevelsSuccess: (ids) => ({ type: actionTypes.DeleteAmoCategoryLevelsSuccess, payload: { ids } }),
  // AMOS
  createAmo: (amoCategoryLevelId, title) => ({ type: actionTypes.CreateAmo, payload: { amoCategoryLevelId, title } }),
  createAmoSuccess: (amoCategoryLevelId, amo) => ({ type: actionTypes.CreateAmoSuccess, payload: { amoCategoryLevelId, amo } }),
  updateAmo: (id, amo) => ({ type: actionTypes.UpdateAmo, payload: { id, amo } }),
  uploadAmoFile: (id, file, contentType) => ({ type: actionTypes.UploadAmoFile, payload: { id, file, contentType } }),
  updateAmoSuccess: (id, amo) => ({ type: actionTypes.UpdateAmoSuccess, payload: { id, amo } }),
  deleteAmos: (ids) => ({ type: actionTypes.DeleteAmos, payload: { ids } }),
  deleteAmosSuccess: (ids) => ({ type: actionTypes.DeleteAmosSuccess, payload: { ids } }),
};

export function* saga() {
  // AMO CATEGORIES
  yield takeLatest(actionTypes.LoadAmoCategories, function* saga() {
    const res = yield axios.get('api/dashboard/amo-categories');
    if (res?.status !== 200) return;
    yield put(actions.loadAmoCategoriesSuccess(res.data.data.amoCategories));
  });
  // AMO CATEGORY LEVELS
  yield takeLatest(actionTypes.CreateAmoCategoryLevel, function* saga({ payload }) {
    const { amoCategoryId, title } = payload;
    const res = yield axios.post(`api/dashboard/amo-categories/${amoCategoryId}/levels`, { title });
    if (res?.status !== 200) return;
    yield put(actions.createAmoCategoryLevelSuccess(amoCategoryId, res.data.data.amoCategoryLevel));
  });
  yield takeLatest(actionTypes.DuplicateAmoCategoryLevels, function* saga({ payload }) {
    const { ids } = payload;
    const loadingId = 'amo_category_levels_loading';
    yield put(UIActions.addLoadingIndicator(loadingId));
    yield Promise.all(ids.map(id => axios.post(`api/dashboard/amo-categories/levels/${id}/duplicate`)));
    yield put(actions.loadAmoCategories());
    yield put(UIActions.removeLoadingIndicator(loadingId));
  });
  yield takeLatest(actionTypes.UpdateAmoCategoryLevel, function* saga({ payload }) {
    const { id, amoCategoryLevel } = payload;
    const res = yield axios.put(`api/dashboard/amo-category-levels/${id}`, amoCategoryLevel);
    if (res?.status !== 200) return;
    yield put(actions.updateAmoCategoryLevelSuccess(res.data.data.amoCategoryLevel));
  });
  yield takeLatest(actionTypes.DeleteAmoCategoryLevels, function* saga({ payload }) {
    const { ids } = payload;
    yield Promise.all(ids.map(id => axios.delete(`api/dashboard/amo-category-levels/${id}`)));
    yield put(actions.deleteAmoCategoryLevelsSuccess(ids));
  });
  // AMOS
  yield takeLatest(actionTypes.CreateAmo, function* saga({ payload }) {
    const { amoCategoryLevelId, title } = payload;
    const res = yield axios.post(`api/dashboard/amo-category-levels/${amoCategoryLevelId}/amos`, { title });
    if (res?.status !== 200) return;
    yield put(actions.createAmoSuccess(amoCategoryLevelId, res.data.data.amo));
  });
  yield takeLatest(actionTypes.UpdateAmo, function* saga({ payload }) {
    const { id, amo } = payload;
    const res = yield axios.put(`api/dashboard/amos/${id}`, amo);
    if (res?.status !== 200) return;
    yield put(actions.updateAmoSuccess(id, res.data.data.amo));
  });
  yield takeLatest(actionTypes.DeleteAmos, function* saga({ payload }) {
    const { ids } = payload;
    yield Promise.all(ids.map(id => axios.delete(`api/dashboard/amos/${id}`)));
    yield put(actions.deleteAmosSuccess(ids));
  });
  yield takeEvery(actionTypes.UploadAmoFile, function* saga({ payload }) {
    const { id, file, contentType } = payload;
    const loadingId = `amo_file_upload_${contentType}_${id}`;
    yield put(UIActions.addLoadingIndicator(loadingId));
    try {
      const res = yield uploadFile(`api/dashboard/amos/${id}/files`, file, contentType);
      if (res?.status !== 200) return;
      yield put(actions.updateAmoSuccess(id, res.data.data.amo));
    } catch (e) {
      console.error('saga actionTypes.UploadAmoFile error', e);
    } finally {
      yield put(UIActions.removeLoadingIndicator(loadingId));
    }
  });
}

export const amoTypeLabels = {
  'planStarter': 'Plan Starter',
  'weekOpener': 'Week Opener',
  'workoutReminder': 'Workout Reminder',
  'workoutRecap15': 'Workout Recap',
  'workoutUndoneReminder': 'Workout Undone Reminder',
  'wellnessReminder': 'Wellness Reminder',
  'wellnessWeekendReminder': 'Wellness Weekend Reminder',
  'weekSucceededRecap': 'Week Succeeded (Done 100%) Recap',
  'weekFailed1to99Recap': 'Week Failed (Done 1-99%) Recap',
  'weekFailedRecap': 'Week Failed (Done 0%) Recap',
};
