import axios from 'axios';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { uploadFile } from '../../../utils';
import { actions as UIActions } from './UI.duck';


export const actionTypes = {
  // WORKOUTS
  LoadWorkouts: '[LoadWorkouts] Action',
  LoadWorkoutsSuccess: '[LoadWorkoutsSuccess] Action',
  LoadWorkout: '[LoadWorkout] Action',
  LoadWorkoutSuccess: '[LoadWorkoutSuccess] Action',
  CreateWorkout: '[CreateWorkout] Action',
  CreateWorkoutSuccess: '[CreateWorkoutSuccess] Action',
  DuplicateWorkouts: '[DuplicateWorkouts] Action',
  UpdateWorkoutOrder: '[UpdateWorkoutOrder] Action',
  UpdateWorkout: '[UpdateWorkout] Action',
  UpdateWorkoutSuccess: '[UpdateWorkoutSuccess] Action',
  UpdateWorkoutProperties: '[UpdateWorkoutProperties] Action',
  UploadWorkoutFile: '[UploadWorkoutFile] Action',
  DeleteWorkouts: '[DeleteWorkouts] Action',
  DeleteWorkoutsSuccess: '[DeleteWorkoutsSuccess] Action',
  // EXERCISE GROUPS
  LoadExerciseGroups: '[LoadExerciseGroups] Action',
  LoadExerciseGroupsSuccess: '[LoadExerciseGroupsSuccess] Action',
  LoadExerciseGroup: '[LoadExerciseGroup] Action',
  LoadExerciseGroupSuccess: '[LoadExerciseGroupSuccess] Action',
  CreateExerciseGroup: '[CreateExerciseGroup] Action',
  CreateExerciseGroupSuccess: '[CreateExerciseGroupSuccess] Action',
  CreateWorkoutExerciseGroup: '[CreateWorkoutExerciseGroup] Action',
  CreateWorkoutExerciseGroupSuccess: '[CreateWorkoutExerciseGroupSuccess] Action',
  UpdateWorkoutExerciseGroup: '[UpdateWorkoutExerciseGroupOrdr] Action',
  UpdateExerciseGroup: '[UpdateExerciseGroup] Action',
  UploadExerciseGroupFile: '[UploadExerciseGroupFile] Action',
  DeleteExerciseGroups: '[DeleteExerciseGroups] Action',
  DeleteExerciseGroupsSuccess: '[DeleteExerciseGroupsSuccess] Action',
  DeleteWorkoutExerciseGroup: '[DeleteWorkoutExerciseGroup] Action',
  // EXERCISES
  LoadExercises: '[LoadExercises] Action',
  LoadExercisesSuccess: '[LoadExercisesSuccess] Action',
  LoadExercise: '[LoadExercise] Action',
  LoadExerciseSuccess: '[LoadExerciseSuccess] Action',
  UploadExerciseFile: '[UploadExerciseFile] Action',
  UpdateExerciseProperties: '[UpdateExerciseProperties] Action',
  UpdateExerciseMuscleGroups: '[UpdateExerciseMuscleGroups] Action',
  CreateExercise: '[CreateExercise] Action',
  CreateExerciseSuccess: '[CreateExerciseSuccess] Action',
  DuplicateExercises: '[DuplicateExercises] Action',
  CreateExerciseGroupExercise: '[CreateExerciseGroupExercise] Action',
  UpdateExercise: '[UpdateExercise] Action',
  UpdateExerciseGroupExerciseOrder: '[UpdateExerciseGroupExerciseOrder] Action',
  DeleteExercises: '[DeletesExercises] Action',
  DeleteExercisesSuccess: '[DeleteExercisesSuccess] Action',
  DeleteExerciseGroupExercise: '[DeleteExerciseGroupExercise] Action',
  // EXERCISE SETS
  CreateSet: '[CreateSet] Action',
  CreateSetSuccess: '[CreateSetSuccess] Action',
  UpdateSet: '[UpdateSet] Action',
  UpdateSetSuccess: '[UpdateSetSuccess] Action',
  UploadSetFile: '[UploadSetFile] Action',
  DeleteSet: '[DeleteSet] Action',
  DeleteSetSuccess: '[DeleteSetSuccess] Action',
  // PROPERTIES
  LoadAllProperties: '[LoadAllProperties] Action',
  LoadAllPropertiesSuccess: '[LoadAllPropertiesSuccess] Action',
  // WORKOUT AUDIOS
  LoadAllWorkoutAudios: '[LoadAllWorkoutAudios] Action',
  LoadAllWorkoutAudiosSuccess: '[LoadAllWorkoutAudiosSuccess] Action',
  UpdateWorkoutAudioSuccess: '[UpdateWorkoutAudioSuccess] Action',
  UploadWorkoutAudioFile: '[UploadWorkoutAudioFile] Action',
};

const initialAuthState = {
  workouts: undefined,
  exerciseGroups: [],
  exercises: [],
  properties: [],
  workoutAudios: [],
  activeWorkout: undefined,
  activeExerciseGroup: undefined
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    // WORKOUTS
    case actionTypes.LoadWorkoutsSuccess: {
      const { workouts } = action.payload;
      return { ...state, workouts };
    }
    case actionTypes.LoadWorkoutSuccess: {
      const { workout } = action.payload;
      const newExerciseGroups = workout.exerciseGroups;
      const newExercises = workout.exerciseGroups
        .reduce((acc, eg) => {
          return [...acc, ...(eg.exercises || [])];
        }, [])
        .map(e => ({ ...e, isDetailed: true }));
      let { exerciseGroups } = state;
      exerciseGroups = exerciseGroups.filter(eg => !newExerciseGroups.map(exg => exg.id).includes(eg.id));
      exerciseGroups = [...exerciseGroups, ...newExerciseGroups];
      let { exercises } = state;
      exercises = exercises.filter(e => !newExercises.map(ex => ex.id).includes(e.id));
      exercises = [...exercises, ...newExercises];
      return { ...state, activeWorkout: workout, exerciseGroups, exercises };
    }
    case actionTypes.CreateWorkoutSuccess: {
      const { workout } = action.payload;
      return { ...state, workouts: [...state.workouts, workout] };
    }
    case actionTypes.UpdateWorkoutSuccess: {
      const { workout } = action.payload;
      return { ...state, activeWorkout: { ...state.activeWorkout, ...workout } };
    }
    case actionTypes.DeleteWorkoutsSuccess: {
      const { ids } = action.payload;
      if (!state.workouts) return state;
      return { ...state, workouts: state.workouts.filter(w => !ids.includes(w.id)) };
    }
    // EXERCISE GROUPS
    case actionTypes.LoadExerciseGroupsSuccess: {
      const { exerciseGroups } = action.payload;
      return { ...state, exerciseGroups };
    }
    case actionTypes.LoadExerciseGroupSuccess: {
      const { exerciseGroup } = action.payload;
      let { exerciseGroups } = state;
      const index = exerciseGroups.findIndex(eg => eg.id === exerciseGroup.id);
      exerciseGroups[index] = { ...exerciseGroups[index], ...exerciseGroup, isDetailed: true };
      return { ...state, exerciseGroups };
    }
    case actionTypes.CreateExerciseGroupSuccess: {
      const { exerciseGroup } = action.payload;
      let { exerciseGroups } = state;
      return { ...state, exerciseGroups: [...exerciseGroups, exerciseGroup] };
    }
    case actionTypes.DeleteExerciseGroupsSuccess: {
      const { ids } = action.payload;
      let { exerciseGroups } = state;
      return { ...state, exerciseGroups: exerciseGroups.filter(e => !ids.includes(e.id)) };
    }
    // EXERCISES
    case actionTypes.LoadExercisesSuccess: {
      const { exercises } = action.payload;
      return { ...state, exercises };
    }
    case actionTypes.LoadExerciseSuccess: {
      const { exercise } = action.payload;
      let { exercises } = state;
      const index = exercises.findIndex(e => e.id === exercise.id);
      if (index >= 0) {
        exercises[index] = { ...exercises[index], ...exercise, isDetailed: true };
      } else {
        exercises.push({ exercise, isDetailed: true });
      }
      return { ...state, exercises };
    }
    case actionTypes.CreateExerciseSuccess: {
      const { exercise } = action.payload;
      let { exercises } = state;
      exercises = [...exercises, { ...exercise, isDetailed: true }];
      return { ...state, exercises };
    }
    case actionTypes.DeleteExercisesSuccess: {
      const { ids } = action.payload;
      let { exercises } = state;
      return { ...state, exercises: exercises.filter(e => !ids.includes(e.id)) };
    }
    // EXERCISE SETS
    case actionTypes.CreateSetSuccess: {
      const { exerciseId, set } = action.payload;
      let { exercises } = state;
      const index = exercises.findIndex(e => e.id === exerciseId);
      if (index < 0) return state;
      exercises[index].sets.push(set);
      return { ...state, exercises };
    }
    case actionTypes.UpdateSetSuccess: {
      const { set } = action.payload;
      let { exercises } = state;
      const index = exercises.findIndex(e => (e.sets || []).map(s => s.id).includes(set.id));
      if (index < 0) return state;
      const setIndex = (exercises[index].sets || []).findIndex(s => s.id === set.id);
      exercises[index].sets[setIndex] = { ...exercises[index].sets[setIndex], ...set };
      return { ...state, exercises };
    }
    case actionTypes.DeleteSetSuccess: {
      const { setId } = action.payload;
      let { exercises } = state;
      const index = exercises.findIndex(e => (e.sets || []).map(s => s.id).includes(setId));
      if (index < 0) return state;
      exercises[index].sets = exercises[index].sets.filter(s => s.id !== setId);
      return { ...state, exercises };
    }
    // PROPERTIES
    case actionTypes.LoadAllPropertiesSuccess: {
      const { properties } = action.payload;
      return { ...state, properties };
    }
    // WORKOUT AUDIOS
    case actionTypes.LoadAllWorkoutAudiosSuccess: {
      const { workoutAudios } = action.payload;
      return { ...state, workoutAudios };
    }
    case actionTypes.UpdateWorkoutAudioSuccess: {
      const { workoutAudio } = action.payload;
      let { workoutAudios } = state;
      const index = workoutAudios.findIndex(c => c.id === workoutAudio.id);
      if (index < 0) return state;
      workoutAudios[index] = { ...workoutAudios[index], ...workoutAudio };
      return { ...state, workoutAudios };
    }
    default:
      return state;
  }
}

// WORKOUTS
export const selectWorkouts = (state) =>
  state.workout.workouts || [];
export const selectWorkoutsByPlanLevelId = (planLevelId) => (state) => {
  const workouts = selectWorkouts(state);
  return workouts.filter(w => w.planLevelId === planLevelId);
}
export const selectActiveWorkout = (state) => {
  return state.workout.activeWorkout;
}
// EXERCISE GROUPS
export const selectExerciseGroups = (state) =>
  state.workout.exerciseGroups || [];
export const selectExerciseGroupById = (exerciseGroupId) => (state) => {
  const exerciseGroups = selectExerciseGroups(state);
  return exerciseGroups.find(eg => eg.id === exerciseGroupId);
}
// EXERCISES
export const selectExercises = (state) =>
  state.workout.exercises || [];
export const selectExerciseById = (exerciseId) => (state) => {
  const exercises = selectExercises(state);
  return exercises.find(e => e.id === exerciseId);
}
// EXERCISE SETS
export const selectSetById = (setId) => (state) => {
  const exercises = selectExercises(state);
  let set = null;
  exercises.forEach(e => {
    (e.sets || []).forEach(s => {
      if (s.id === setId) {
        set = s;
      }
    });
  });
  return set;
}
// PROPERTIES
export const selectProperties = (state) =>
  state.workout.properties || [];
export const selectPropertiesByType = (type) => (state) => {
  const properties = selectProperties(state);
  return properties.filter(p => p.type === type);
}
// WORKOUT AUDIOS
export const selectWorkoutAudios = (state) =>
  state.workout.workoutAudios || [];
export const selectWorkoutAudioById = (workoutAudioId) => (state) => {
  const workoutAudios = selectWorkoutAudios(state);
  return workoutAudios.find(c => c.id === workoutAudioId);
}

export const actions = {
  // WORKOUTS
  loadWorkouts: () => ({ type: actionTypes.LoadWorkouts }),
  loadWorkoutsSuccess: (workouts) => ({ type: actionTypes.LoadWorkoutsSuccess, payload: { workouts } }),
  loadWorkout: (id) => ({ type: actionTypes.LoadWorkout, payload: { id } }),
  loadWorkoutSuccess: (workout) => ({ type: actionTypes.LoadWorkoutSuccess, payload: { workout } }),
  createWorkout: (workout) => ({ type: actionTypes.CreateWorkout, payload: { workout } }),
  createWorkoutSuccess: (workout) => ({ type: actionTypes.CreateWorkoutSuccess, payload: { workout } }),
  duplicateWorkouts: (ids) => ({ type: actionTypes.DuplicateWorkouts, payload: { ids } }),
  updateWorkoutOrder: (id, order) => ({ type: actionTypes.UpdateWorkoutOrder, payload: { id, order }, }),
  updateWorkout: (id, workout) => ({ type: actionTypes.UpdateWorkout, payload: { id, workout }, }),
  updateWorkoutSuccess: (workout) => ({ type: actionTypes.UpdateWorkoutSuccess, payload: { workout }, }),
  updateWorkoutProperties: (id, type, properties) => ({ type: actionTypes.UpdateWorkoutProperties, payload: { id, type, properties } }),
  uploadWorkoutFile: (id, file, contentType) => ({ type: actionTypes.UploadWorkoutFile, payload: { id, file, contentType } }),
  deleteWorkouts: (ids) => ({ type: actionTypes.DeleteWorkouts, payload: { ids } }),
  deleteWorkoutsSuccess: (ids) => ({ type: actionTypes.DeleteWorkoutsSuccess, payload: { ids } }),
  // EXERCISE GROUPS
  loadExerciseGroups: () => ({ type: actionTypes.LoadExerciseGroups }),
  loadExerciseGroupsSuccess: (exerciseGroups) => ({ type: actionTypes.LoadExerciseGroupsSuccess, payload: { exerciseGroups } }),
  loadExerciseGroup: (id) => ({ type: actionTypes.LoadExerciseGroup, payload: { id } }),
  loadExerciseGroupSuccess: (exerciseGroup) => ({ type: actionTypes.LoadExerciseGroupSuccess, payload: { exerciseGroup } }),
  createExerciseGroup: (coachId) => ({ type: actionTypes.CreateExerciseGroup, payload: { coachId } }),
  createExerciseGroupSuccess: (exerciseGroup) => ({ type: actionTypes.CreateExerciseGroupSuccess, payload: { exerciseGroup } }),
  createWorkoutExerciseGroup: (id, exerciseGroupId) => ({ type: actionTypes.CreateWorkoutExerciseGroup, payload: { id, exerciseGroupId } }),
  updateWorkoutExerciseGroup: (id, exerciseGroupId, workoutExerciseGroup) => ({ type: actionTypes.UpdateWorkoutExerciseGroup, payload: { id, exerciseGroupId, workoutExerciseGroup } }),
  updateExerciseGroup: (exerciseGroupId, exerciseGroup) => ({ type: actionTypes.UpdateExerciseGroup, payload: { exerciseGroupId, exerciseGroup } }),
  uploadExerciseGroupFile: (id, file, contentType) => ({ type: actionTypes.UploadExerciseGroupFile, payload: { id, file, contentType } }),
  deleteExerciseGroups: (ids) => ({ type: actionTypes.DeleteExerciseGroups, payload: { ids } }),
  deleteExerciseGroupsSuccess: (ids) => ({ type: actionTypes.DeleteExerciseGroupsSuccess, payload: { ids } }),
  deleteWorkoutExerciseGroup: (id, exerciseGroupId) => ({ type: actionTypes.DeleteWorkoutExerciseGroup, payload: { id, exerciseGroupId } }),
  // EXERCISES
  loadExercises: () => ({ type: actionTypes.LoadExercises }),
  loadExercisesSuccess: (exercises) => ({ type: actionTypes.LoadExercisesSuccess, payload: { exercises } }),
  loadExercise: (id) => ({ type: actionTypes.LoadExercise, payload: { id } }),
  loadExerciseSuccess: (exercise) => ({ type: actionTypes.LoadExerciseSuccess, payload: { exercise } }),
  createExercise: (coachId) => ({ type: actionTypes.CreateExercise, payload: { coachId } }),
  createExerciseSuccess: (exercise) => ({ type: actionTypes.CreateExerciseSuccess, payload: { exercise } }),
  duplicateExercises: (ids) => ({ type: actionTypes.DuplicateExercises, payload: { ids } }),
  createExerciseGroupExercise: (id, exerciseId) => ({ type: actionTypes.CreateExerciseGroupExercise, payload: { id, exerciseId } }),
  updateExerciseGroupExerciseOrder: (id, exerciseId, order) => ({ type: actionTypes.UpdateExerciseGroupExerciseOrder, payload: { id, exerciseId, order } }),
  updateExercise: (exerciseId, exercise) => ({ type: actionTypes.UpdateExercise, payload: { exerciseId, exercise } }),
  uploadExerciseFile: (id, file, contentType, fileType) => ({ type: actionTypes.UploadExerciseFile, payload: { id, file, contentType, fileType } }),
  updateExerciseProperties: (exerciseId, type, properties) => ({ type: actionTypes.UpdateExerciseProperties, payload: { exerciseId, type, properties } }),
  updateExerciseMuscleGroups: (exerciseId, type, properties) => ({ type: actionTypes.UpdateExerciseMuscleGroups, payload: { exerciseId, type, properties } }),
  deleteExercises: (ids) => ({ type: actionTypes.DeleteExercises, payload: { ids } }),
  deleteExercisesSuccess: (ids) => ({ type: actionTypes.DeleteExercisesSuccess, payload: { ids } }),
  deleteExerciseGroupExercise: (id, exerciseId) => ({ type: actionTypes.DeleteExerciseGroupExercise, payload: { id, exerciseId } }),
  // EXERCISE SETS
  createSet: (exerciseId, order) => ({ type: actionTypes.CreateSet, payload: { exerciseId, order } }),
  createSetSuccess: (exerciseId, set) => ({ type: actionTypes.CreateSetSuccess, payload: { exerciseId, set } }),
  updateSet: (setId, set) => ({ type: actionTypes.UpdateSet, payload: { setId, set } }),
  updateSetSuccess: (set) => ({ type: actionTypes.UpdateSetSuccess, payload: { set } }),
  uploadSetFile: (id, file, contentType) => ({ type: actionTypes.UploadSetFile, payload: { id, file, contentType } }),
  deleteSet: (setId) => ({ type: actionTypes.DeleteSet, payload: { setId } }),
  deleteSetSuccess: (setId) => ({ type: actionTypes.DeleteSetSuccess, payload: { setId } }),
  // PROPERTIES
  loadAllProperties: () => ({ type: actionTypes.LoadAllProperties }),
  loadAllPropertiesSuccess: (properties) => ({ type: actionTypes.LoadAllPropertiesSuccess, payload: { properties } }),
  // WORKOUT AUDIOS
  loadAllWorkoutAudios: () => ({ type: actionTypes.LoadAllWorkoutAudios }),
  loadAllWorkoutAudiosSuccess: (workoutAudios) => ({ type: actionTypes.LoadAllWorkoutAudiosSuccess, payload: { workoutAudios } }),
  updateWorkoutAudioSuccess: (workoutAudio) => ({ type: actionTypes.UpdateWorkoutAudioSuccess, payload: { workoutAudio } }),
  uploadWorkoutAudioFile: (id, file, contentType) => ({ type: actionTypes.UploadWorkoutAudioFile, payload: { id, file, contentType } }),
};

export function* saga() {

  // WORKOUTS

  yield takeLatest(actionTypes.LoadWorkouts, function* saga() {
    const loadingId = 'workouts_loading';
    yield put(UIActions.addLoadingIndicator(loadingId));
    const res = yield axios.get('api/dashboard/workouts');
    if (res?.status !== 200) return;
    yield put(actions.loadWorkoutsSuccess(res.data.data.workouts));
    yield put(UIActions.removeLoadingIndicator(loadingId));
  });

  yield takeLatest(actionTypes.LoadWorkout, function* saga({ payload }) {
    const { id } = payload;
    const res = yield axios.get(`api/dashboard/workouts/${id}`);
    if (res?.status !== 200) return;
    yield put(actions.loadWorkoutSuccess(res.data.data.workout));
  });

  yield takeLatest(actionTypes.CreateWorkout, function* saga({ payload }) {
    let { workout } = payload;
    const res = yield axios.post('api/dashboard/workouts', workout);
    workout = res?.data?.data?.workout;
    if (!workout) return;
    yield put(actions.createWorkoutSuccess(workout));
  });

  yield takeLatest(actionTypes.DuplicateWorkouts, function* saga({ payload }) {
    const { ids } = payload;
    const loadingId = 'workouts_loading';
    yield put(UIActions.addLoadingIndicator(loadingId));
    yield Promise.all(ids.map(id => axios.post(`api/dashboard/workouts/${id}/duplicate`)));
    yield put(actions.loadWorkouts());
    yield put(UIActions.removeLoadingIndicator(loadingId));
  });

  yield takeLatest(actionTypes.UpdateWorkout, function* saga({ payload }) {
    const { id, workout } = payload;
    const res = yield axios.put(`api/dashboard/workouts/${id}`, workout);
    if (res?.status !== 200) return;
    yield put(actions.updateWorkoutSuccess(res.data.data.workout));
  });

  yield takeLatest(actionTypes.UpdateWorkoutProperties, function* saga({ payload }) {
    const { id, type, properties } = payload;
    const workout = yield select(selectActiveWorkout);
    const allProperties = yield select(selectPropertiesByType(type));
    const prevProperties = (workout[type] ?? []).map(p => p.id);
    const propertiesToAdd = properties.filter(b => !prevProperties.includes(b));
    const propertiesToRemove = prevProperties.filter(b => !properties.includes(b));
    for (const p of allProperties) {
      if (propertiesToAdd.includes(p.id)) {
        yield axios.put(`api/dashboard/workouts/${id}/properties`, { propertyId: p.id });
      } else if (propertiesToRemove.includes(p.id)) {
        yield axios.delete(`api/dashboard/workouts/${id}/properties/${p.id}`);
      }
    }
    yield put(actions.updateWorkoutSuccess({ ...workout, [type]: properties.map(p => allProperties.find(ap => ap.id === p)) }));
  });

  yield takeEvery(actionTypes.UploadWorkoutFile, function* saga({ payload }) {
    const { id, file, contentType } = payload;
    const loadingId = `exercise_group_file_upload_${contentType}`;
    yield put(UIActions.addLoadingIndicator(loadingId));
    try {
      const res = yield uploadFile(`api/dashboard/workouts/${id}/files`, file, contentType, 'video');
      if (res?.status !== 200) return;
      let workout = yield select(selectActiveWorkout);
      if (['cover', 'thumbnail'].includes(contentType)) {
        workout = res.data.data.workout;
      } else if (!file) {
        workout.workoutIntermissions = workout.workoutIntermissions.filter(i => i.type !== contentType);
      } else if (workout.workoutIntermissions) {
        workout.workoutIntermissions = workout.workoutIntermissions.filter(i => i.type !== contentType);
        workout.workoutIntermissions.push(res.data.data.intermission);
      } else {
        workout.workoutIntermissions = [res.data.data.intermission];
      }
      yield put(actions.updateWorkoutSuccess(workout));
    } catch (e) {
      console.log('UploadWorkoutFileError', e);
    } finally {
      yield put(UIActions.removeLoadingIndicator(loadingId));
    }
  });

  yield takeLatest(actionTypes.UpdateWorkoutOrder, function* saga({ payload }) {
    const { id, order } = payload;
    console.log(id, order);
    yield axios.put(`api/dashboard/workouts/${id}/order`, { order });
    yield put(actions.loadWorkouts());
  });

  yield takeLatest(actionTypes.DeleteWorkouts, function* saga({ payload }) {
    const { ids } = payload;
    yield Promise.all(ids.map(id => axios.delete(`api/dashboard/workouts/${id}`)));
    yield put(actions.deleteWorkoutsSuccess(ids));
  });

  // EXERCISE GROUPS

  yield takeLatest(actionTypes.LoadExerciseGroups, function* saga() {
    const res = yield axios.get('api/dashboard/exerciseGroups');
    if (res?.status !== 200) return;
    yield put(actions.loadExerciseGroupsSuccess(res.data.data.exerciseGroups));
  });

  yield takeLatest(actionTypes.LoadExerciseGroup, function* saga({ payload }) {
    const { id } = payload;
    const res = yield axios.get(`api/dashboard/exerciseGroups/${id}`);
    if (res?.status !== 200) return;
    yield put(actions.loadExerciseGroupSuccess(res.data.data.exerciseGroup));
  });

  yield takeLatest(actionTypes.CreateExerciseGroup, function* saga({ payload }) {
    const { coachId } = payload;
    const res = yield axios.post('api/dashboard/exerciseGroups', { coachId });
    if (res?.status !== 200) return;
    yield put(actions.createExerciseGroupSuccess(res.data.data.exerciseGroup));
  });

  yield takeLatest(actionTypes.CreateWorkoutExerciseGroup, function* saga({ payload }) {
    const { id, exerciseGroupId } = payload;
    yield axios.post(`api/dashboard/workouts/${id}/exerciseGroups/${exerciseGroupId}`);
    const workout = yield select(selectActiveWorkout);
    yield put(actions.loadWorkout(workout.id));
  });

  yield takeLatest(actionTypes.UpdateWorkoutExerciseGroup, function* saga({ payload }) {
    const { id, exerciseGroupId, workoutExerciseGroup } = payload;
    yield axios.put(`api/dashboard/workouts/${id}/exerciseGroups/${exerciseGroupId}`, workoutExerciseGroup);
    const workout = yield select(selectActiveWorkout);
    yield put(actions.loadWorkout(workout.id));
  });

  yield takeLatest(actionTypes.UpdateExerciseGroup, function* saga({ payload }) {
    const { exerciseGroupId, exerciseGroup } = payload;
    yield axios.put(`api/dashboard/exerciseGroups/${exerciseGroupId}`, exerciseGroup);
    yield put(actions.loadExerciseGroupSuccess({ id: exerciseGroupId, ...exerciseGroup }));
  });

  yield takeEvery(actionTypes.UploadExerciseGroupFile, function* saga({ payload }) {
    const { id, file, contentType } = payload;
    const loadingId = `exercise_group_file_upload_${contentType}`;
    yield put(UIActions.addLoadingIndicator(loadingId));
    try {
      const res = yield uploadFile(`api/dashboard/exerciseGroups/${id}/files`, file, contentType, 'audio');
      if (res?.status !== 200) return;
      const exerciseGroup = yield select(selectExerciseGroupById(id));
      if (!file) {
        exerciseGroup.exerciseGroupIntermissions = exerciseGroup.exerciseGroupIntermissions.filter(i => i.type !== contentType);
      } else if (exerciseGroup.exerciseGroupIntermissions) {
        exerciseGroup.exerciseGroupIntermissions = exerciseGroup.exerciseGroupIntermissions.filter(i => i.type !== contentType);
        exerciseGroup.exerciseGroupIntermissions.push(res.data.data.intermission);
      } else {
        exerciseGroup.exerciseGroupIntermissions = [res.data.data.intermission];
      }
      yield put(actions.loadExerciseGroupSuccess(exerciseGroup));
    } catch (e) {
      console.log('UploadExerciseGroupFileError', e);
    } finally {
      yield put(UIActions.removeLoadingIndicator(loadingId));
    }
  });

  yield takeLatest(actionTypes.DeleteExerciseGroups, function* saga({ payload }) {
    const { ids } = payload;
    yield Promise.all(ids.map(id => axios.delete(`api/dashboard/exerciseGroups/${id}`)));
    yield put(actions.deleteExerciseGroupsSuccess(ids));
  });

  yield takeLatest(actionTypes.DeleteWorkoutExerciseGroup, function* saga({ payload }) {
    const { id, exerciseGroupId } = payload;
    yield axios.delete(`api/dashboard/workouts/${id}/exerciseGroups/${exerciseGroupId}`);
    const workout = yield select(selectActiveWorkout);
    yield put(actions.loadWorkout(workout.id));
  });

  // EXERCISES

  yield takeLatest(actionTypes.LoadExercises, function* saga() {
    const res = yield axios.get('api/dashboard/exercises');
    if (res?.status !== 200) return;
    yield put(actions.loadExercisesSuccess(res.data.data.exercises));
  });

  yield takeLatest(actionTypes.LoadExercise, function* saga({ payload }) {
    const { id } = payload;
    const res = yield axios.get(`api/dashboard/exercises/${id}`);
    if (res?.status !== 200) return;
    yield put(actions.loadExerciseSuccess(res.data.data.exercise));
  });

  yield takeLatest(actionTypes.CreateExercise, function* saga({ payload }) {
    const { coachId } = payload;
    const res = yield axios.post(`api/dashboard/exercises`, { coachId });
    yield put(actions.createExerciseSuccess(res.data.data.exercise));
  });

  yield takeLatest(actionTypes.DuplicateExercises, function* saga({ payload }) {
    const { ids } = payload;
    const loadingId = 'exrcises_loading';
    yield put(UIActions.addLoadingIndicator(loadingId));
    yield Promise.all(ids.map(id => axios.post(`api/dashboard/exercises/${id}/duplicate`)));
    yield put(actions.loadExercises());
    yield put(UIActions.removeLoadingIndicator(loadingId));
  });

  yield takeLatest(actionTypes.CreateExerciseGroupExercise, function* saga({ payload }) {
    const { id, exerciseId } = payload;
    yield axios.post(`api/dashboard/exerciseGroups/${id}/exercises/${exerciseId}`);
    const workout = yield select(selectActiveWorkout);
    if (workout?.id) {
      yield put(actions.loadWorkout(workout.id));
    }
    yield put(actions.loadExerciseGroup(id));
  });

  yield takeLatest(actionTypes.UpdateExerciseGroupExerciseOrder, function* saga({ payload }) {
    const { id, exerciseId, order } = payload;
    yield axios.put(`api/dashboard/exerciseGroups/${id}/exercises/${exerciseId}/order`, { order });
    const workout = yield select(selectActiveWorkout);
    if (workout?.id) {
      yield put(actions.loadWorkout(workout.id));
    }
    yield put(actions.loadExerciseGroup(id));
  });

  yield takeLatest(actionTypes.UpdateExercise, function* saga({ payload }) {
    const { exerciseId, exercise } = payload;
    const res = yield axios.put(`api/dashboard/exercises/${exerciseId}`, exercise);
    yield put(actions.loadExerciseSuccess(res.data.data.exercise));
  });

  yield takeLatest(actionTypes.UpdateExerciseProperties, function* saga({ payload }) {
    const { exerciseId, type, properties } = payload;
    const exercise = yield select(selectExerciseById(exerciseId));
    const allProperties = yield select(selectPropertiesByType(type));
    const prevProperties = (exercise[type] ?? []).map(p => p.id);
    const propertiesToAdd = properties.filter(b => !prevProperties.includes(b));
    const propertiesToRemove = prevProperties.filter(b => !properties.includes(b));
    for (const p of allProperties) {
      if (propertiesToAdd.includes(p.id)) {
        yield axios.put(`api/dashboard/exercises/${exerciseId}/equipment`, { equipmentId: p.id });
      } else if (propertiesToRemove.includes(p.id)) {
        yield axios.delete(`api/dashboard/exercises/${exerciseId}/equipment/${p.id}`);
      }
    }
    yield put(actions.loadExerciseSuccess({ ...exercise, [type]: properties.map(p => allProperties.find(ap => ap.id === p)) }));
  });

  yield takeLatest(actionTypes.UpdateExerciseMuscleGroups, function* saga({ payload }) {
    const { exerciseId, type, properties } = payload;
    const exercise = yield select(selectExerciseById(exerciseId));
    const allProperties = yield select(selectPropertiesByType(type));
    const prevProperties = (exercise[type] ?? []).map(p => p.id);
    const propertiesToAdd = properties.filter(b => !prevProperties.includes(b));
    const propertiesToRemove = prevProperties.filter(b => !properties.includes(b));
    for (const p of allProperties) {
      if (propertiesToAdd.includes(p.id)) {
        yield axios.put(`api/dashboard/exercises/${exerciseId}/muscleGroup`, { muscleGroupId: p.id });
      } else if (propertiesToRemove.includes(p.id)) {
        yield axios.delete(`api/dashboard/exercises/${exerciseId}/muscleGroup/${p.id}`);
      }
    }
    yield put(actions.loadExerciseSuccess({ ...exercise, [type]: properties.map(p => allProperties.find(ap => ap.id === p)) }));
  });

  yield takeEvery(actionTypes.UploadExerciseFile, function* saga({ payload }) {
    const { id, file, contentType, fileType } = payload;
    const loadingId = `exercise_file_upload_${fileType}_${id}`;
    yield put(UIActions.addLoadingIndicator(loadingId));
    try {
      const res = yield uploadFile(`api/dashboard/exercises/${id}/files`, file, contentType, fileType);
      if (res?.status !== 200) return;
      let newExercise;
      if (contentType === 'thumbnail') {
        newExercise = res.data.data.exercise; // backend returned exercise
      } else {
        const exercise = yield select(selectExerciseById(id)); // backend returned intermission
        const intermission = res.data.data.intermission;
        const intermissions = exercise.exerciseIntermissions.filter(ei => ei.id !== intermission.id);
        exercise.exerciseIntermissions = [...intermissions, intermission];
        newExercise = exercise;
      }
      yield put(actions.loadExerciseSuccess(newExercise));
    } catch (e) {
      console.log('UploadExerciseFileError', e);
    } finally {
      yield put(UIActions.removeLoadingIndicator(loadingId));
    }
  });

  yield takeLatest(actionTypes.DeleteExercises, function* saga({ payload }) {
    const { ids } = payload;
    yield Promise.all(ids.map(id => axios.delete(`api/dashboard/exercises/${id}`)));
    yield put(actions.deleteExercisesSuccess(ids));
  });

  yield takeLatest(actionTypes.DeleteExerciseGroupExercise, function* saga({ payload }) {
    const { id, exerciseId } = payload;
    yield axios.delete(`api/dashboard/exerciseGroups/${id}/exercises/${exerciseId}`);
    const workout = yield select(selectActiveWorkout);
    if (workout?.id) {
      yield put(actions.loadWorkout(workout.id));
    }
    yield put(actions.loadExerciseGroup(id));
  });

  // EXERCISE SETS

  yield takeLatest(actionTypes.CreateSet, function* saga({ payload }) {
    const { exerciseId, order } = payload;
    const res = yield axios.post(`api/dashboard/sets`, { exerciseId, order, type: 'repsBased', goal: 10 });
    yield put(actions.createSetSuccess(exerciseId, res.data.data.set));
  });

  yield takeLatest(actionTypes.UpdateSet, function* saga({ payload }) {
    const { setId, set } = payload;
    const res = yield axios.put(`api/dashboard/sets/${setId}`, set);
    yield put(actions.updateSetSuccess(res.data.data.set));
  });

  yield takeEvery(actionTypes.UploadSetFile, function* saga({ payload }) {
    const { id, file, contentType } = payload;
    const loadingId = `set_file_upload_${contentType}_${id}`;
    yield put(UIActions.addLoadingIndicator(loadingId));
    try {
      const res = yield uploadFile(`api/dashboard/sets/${id}/files`, file, contentType, 'audio');
      if (res?.status !== 200) return;
      const set = yield select(selectSetById(id));
      if (!file) {
        set.setIntermissions = set.setIntermissions.filter(i => i.type !== contentType);
      } else {
        const intermission = res.data.data.intermission;
        if (set.setIntermissions) {
          set.setIntermissions = set.setIntermissions.filter(i => i.type !== contentType);
          set.setIntermissions.push(intermission);
        } else {
          set.setIntermissions = [intermission];
        }
      }
      yield put(actions.updateSetSuccess(set));
    } catch (e) {
      console.log('UploadSetFileError', e);
    } finally {
      yield put(UIActions.removeLoadingIndicator(loadingId));
    }
  });

  yield takeLatest(actionTypes.DeleteSet, function* saga({ payload }) {
    const { setId } = payload;
    const res = yield axios.delete(`api/dashboard/sets/${setId}`);
    if (res?.status !== 200) return;
    yield put(actions.deleteSetSuccess(setId));
  });

  // PROPERTIES

  yield takeLatest(actionTypes.LoadAllProperties, function* saga() {
    const res = yield axios.get('api/dashboard/properties');
    if (res?.status !== 200) return;
    yield put(actions.loadAllPropertiesSuccess(res.data.data.properties));
  });

  // WORKOUT AUDIOS

  yield takeLatest(actionTypes.LoadAllWorkoutAudios, function* saga() {
    const res = yield axios.get('api/dashboard/workoutAudios');
    if (res?.status !== 200) return;
    yield put(actions.loadAllWorkoutAudiosSuccess(res.data.data.workoutAudios));
  });

  yield takeEvery(actionTypes.UploadWorkoutAudioFile, function* saga({ payload }) {
    const { id, file, contentType } = payload;
    const loadingId = `workoutAudio_file_upload_${contentType}_${id}`;
    yield put(UIActions.addLoadingIndicator(loadingId));
    try {
      const res = yield uploadFile(`api/dashboard/workoutAudios/${id}/files`, file, contentType, 'audio');
      if (res?.status !== 200) return;
      const workoutAudio = yield select(selectWorkoutAudioById(id));
      if (!file) {
        workoutAudio.workoutAudioIntermissions = workoutAudio.workoutAudioIntermissions.filter(i => i.type !== contentType);
      } else {
        const intermission = res.data.data.intermission;
        if (workoutAudio.workoutAudioIntermissions) {
          workoutAudio.workoutAudioIntermissions = workoutAudio.workoutAudioIntermissions.filter(i => i.type !== contentType);
          workoutAudio.workoutAudioIntermissions.push(intermission);
        } else {
          workoutAudio.workoutAudioIntermissions = [intermission];
        }
      }
      yield put(actions.updateWorkoutAudioSuccess(workoutAudio));
    } catch (e) {
      console.log('UploadWorkoutAudioFileError', e);
    } finally {
      yield put(UIActions.removeLoadingIndicator(loadingId));
    }
  });
}
