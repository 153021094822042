import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import '../../sass/components/_audio-files-dropzone.scss';
import AddIcon from '@material-ui/icons/Add';
import MusicNote from '@material-ui/icons/MusicNote';
import Delete from '@material-ui/icons/Delete';

import { CircularProgress } from '@material-ui/core';

export default function AudioDropzone(props) {

  const { duration, loading, title, onDelete, onDrop, url } = props;

  function localOnDrop(files) {
    if (loading || !files?.length) return;
    if (!files[0].type.includes('audio')) {
      window.alert('This input only excepts audio files.');
      return;
    }
    onDrop(files);
  }

  const getShortUrl = (url) => {
    let urlShort = url.split('/').pop();
    if (urlShort.length > 31) {
      const urlShortLength = urlShort.length;
      urlShort = urlShort.substring(0, 12) + '...' + urlShort.substring(urlShortLength - 15, urlShortLength);
    }
    return urlShort;
  }


  return (
    <div className="audio-files-dropzone-wrapper">
      <label>{title}</label>
      <Dropzone onDrop={localOnDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="audio-files-dropzone-container">
            <input {...getInputProps()} />
            {loading ?
              <div className="progress-wrapper">
                <CircularProgress />
              </div> :
              (url ?
                <div className="file-name-wrapper">
                  <p>{`${getShortUrl(url)}${duration ? ` - ${Math.round(duration*100)/100}s` : ''}`}</p>
                </div> :
                <AddIcon />
              )
            }
            <div className="music-wrapper">
              <MusicNote />
            </div>
            {(url && onDelete) &&
              <div
                className="delete-wrapper"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
              >
                <Delete />
              </div>
            }
          </div>
        )}
      </Dropzone>
    </div>
  );
}

AudioDropzone.prototype = {
  duration: PropTypes.number,
  title: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  url: PropTypes.string,
  loading: PropTypes.bool
};
