import axios from 'axios';
import { cloneDeep, get } from 'lodash';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { uploadFile } from '../../../utils';
import { actions as UIActions } from './UI.duck';


export const actionTypes = {
  // COACHES
  CreateCoach: '[CreateCoach] Action',
  CreateCoachSuccess: '[CreateCoachSuccess] Action',
  LoadCoaches: '[LoadCoaches] Action',
  LoadCoachesSuccess: '[LoadCoachseSuccess] Action',
  UpdateCoach: '[UpdateCoach] Action',
  UploadCoachFile: '[UploadCoachFile] Action',
  UpdateCoachSuccess: '[UpdateCoachSuccess] Action',
  SetCurrentCoachId: '[SetCurrentCoachId] Action',
  // PLANS
  CreatePlan: '[CreatePlan] Action',
  CreatePlanSuccess: '[CreatePlanSuccess] Action',
  DuplicatePlans: '[DuplicatePlans] Action',
  LoadPlans: '[LoadPlans] Action',
  LoadPlansSuccess: '[LoadPlansSuccess] Action',
  UpdatePlan: '[UpdatePlan] Action',
  UploadPlanFile: '[UploadPlanFile] Action',
  UpdatePlanSuccess: '[UpdatePlanSuccess] Action',
  // PLAN LEVELS
  CreatePlanLevel: '[CreatePlanLevel] Action',
  CreatePlanLevelSuccess: '[CreatePlanLevelSuccess] Action',
  DeletePlanLevels: '[DeletePlanLevels] Action',
  DeletePlanLevelsSuccess: '[DeletePlanLevelsSuccess] Action',
  UpdatePlanLevel: '[UpdatePlanLevel] Action',
  UpdatePlanLevelSuccess: '[UpdatePlanLevelSuccess] Action',
};

const initialPlansState = {
  currentCoachId: 0,
  coaches: [],
  plans: [],
};

export const reducer = persistReducer({ storage, key: 'persist-plans', blackList: ['plans'] }, (state = initialPlansState, action) => {
  switch (action.type) {
    // COACHES
    case actionTypes.LoadCoachesSuccess: {
      const { coaches } = action.payload;
      return { ...state, coaches };
    }
    case actionTypes.CreateCoachSuccess: {
      const { coaches } = state;
      const { coach } = action.payload;
      return { ...state, coaches: [...coaches, coach] };
    }
    case actionTypes.UpdateCoachSuccess: {
      const { coach } = action.payload;
      const coachIndex = state.coaches.findIndex(c => c.id === coach.id);
      const coaches = cloneDeep(state.coaches);
      coaches[coachIndex] = { ...coaches[coachIndex], ...coach };
      return { ...state, coaches };
    }
    case actionTypes.SetCurrentCoachId: {
      const { coachId } = action.payload;
      return { ...state, currentCoachId: coachId };
    }
    // PLANS
    case actionTypes.LoadPlansSuccess: {
      const { plans } = action.payload;
      return { ...state, plans };
    }
    case actionTypes.CreatePlanSuccess: {
      const { plans } = state;
      const { plan } = action.payload;
      return { ...state, plans: [...plans, plan] };
    }
    case actionTypes.UpdatePlanSuccess: {
      const { plan } = action.payload;
      const planIndex = state.plans.findIndex(p => p.id === plan.id);
      const plans = cloneDeep(state.plans);
      plans[planIndex] = { ...plans[planIndex], ...plan };
      return { ...state, plans };
    }
    // PLAN LEVELS
    case actionTypes.CreatePlanLevelSuccess: {
      const { planId, planLevel } = action.payload;
      const planIndex = state.plans.findIndex(p => p.id === planId);
      const plan = cloneDeep(state.plans[planIndex]);
      plan.levels = [...plan.levels, planLevel];
      state.plans[planIndex] = plan;
      return state;
    }
    case actionTypes.DeletePlanLevelsSuccess: {
      const { ids } = action.payload;
      let plans = cloneDeep(state.plans);
      plans = plans.map(plan => ({ ...plan, levels: plan.levels.filter(pl => !ids.includes(pl.id)) }));
      return { ...state, plans };
    }
    case actionTypes.UpdatePlanLevelSuccess: {
      const { planLevel } = action.payload;
      const planIndex = state.plans.findIndex(p => p.levels.some(pl => pl.id === planLevel.id));
      const plan = cloneDeep(state.plans[planIndex]);
      const planLevelIndex = plan.levels.findIndex(pl => pl.id === planLevel.id);
      plan.levels[planLevelIndex] = planLevel;
      state.plans[planIndex] = plan;
      return state;
    }
    default:
      return state;
  }
});

// COACHES
export const selectCoaches = (state) => get(state, 'plan.coaches', []);
export const selectCoachById = (coachId) => (state) => {
  const coaches = selectCoaches(state);
  return coaches.find(c => c.id === coachId);
}
export const selectCurrentCoachId = (state) => get(state, 'plan.currentCoachId', 0);
// PLANS
export const selectPlans = (state) => get(state, 'plan.plans', []);
export const selectPlanById = (planId) => (state) => {
  const plans = selectPlans(state);
  return plans.find(p => p.id === planId);
}
// PLAN LEVELS
export const selectPlanLevelByPlanIdAndId = (planId, planLevelId) => (state) => {
  const plan = selectPlanById(planId)(state);
  return plan.levels.find(pl => pl.id === planLevelId);
}
export const selectPlanLevelsForDropdown = (state) => {
  const plans = selectPlans(state);
  return plans.reduce((res, plan) => {
    const levels = plan.levels.map(l => ({ id: l.id, coachId: plan.coachId, title: `${plan.title} - ${l.title}` }));
    return [...res, ...levels];
  }, []);
}

export const actions = {
  // COACHES
  loadCoaches: () => ({ type: actionTypes.LoadCoaches }),
  loadCoachesSuccess: (coaches) => ({ type: actionTypes.LoadCoachesSuccess, payload: { coaches } }),
  createCoach: () => ({ type: actionTypes.CreateCoach }),
  createCoachSuccess: (coach) => ({ type: actionTypes.CreateCoachSuccess, payload: { coach } }),
  updateCoach: (id, coach) => ({ type: actionTypes.UpdateCoach, payload: { id, coach } }),
  uploadCoachFile: (id, file, contentType) => ({ type: actionTypes.UploadCoachFile, payload: { id, file, contentType } }),
  updateCoachSuccess: (coach) => ({ type: actionTypes.UpdateCoachSuccess, payload: { coach } }),
  setCurrentCoachId: (coachId) => ({ type: actionTypes.SetCurrentCoachId, payload: { coachId } }),
  // PLANS
  loadPlans: () => ({ type: actionTypes.LoadPlans }),
  loadPlansSuccess: (plans) => ({ type: actionTypes.LoadPlansSuccess, payload: { plans } }),
  createPlan: (coachId) => ({ type: actionTypes.CreatePlan, payload: { coachId } }),
  createPlanSuccess: (plan) => ({ type: actionTypes.CreatePlanSuccess, payload: { plan } }),
  duplicatePlans: (ids) => ({ type: actionTypes.DuplicatePlans, payload: { ids } }),
  updatePlan: (id, plan) => ({ type: actionTypes.UpdatePlan, payload: { id, plan } }),
  uploadPlanFile: (id, file, contentType) => ({ type: actionTypes.UploadPlanFile, payload: { id, file, contentType } }),
  updatePlanSuccess: (plan) => ({ type: actionTypes.UpdatePlanSuccess, payload: { plan } }),
  // PLAN LEVELS
  createPlanLevel: (planId, title) => ({ type: actionTypes.CreatePlanLevel, payload: { planId, title } }),
  createPlanLevelSuccess: (planId, planLevel) => ({ type: actionTypes.CreatePlanLevelSuccess, payload: { planId, planLevel } }),
  deletePlanLevels: (ids) => ({ type: actionTypes.DeletePlanLevels, payload: { ids } }),
  deletePlanLevelsSuccess: (ids) => ({ type: actionTypes.DeletePlanLevelsSuccess, payload: { ids } }),
  updatePlanLevel: (id, planLevel) => ({ type: actionTypes.UpdatePlanLevel, payload: { id, planLevel } }),
  updatePlanLevelSuccess: (planLevel) => ({ type: actionTypes.UpdatePlanLevelSuccess, payload: { planLevel } }),
};

export function* saga() {
  // COACHES
  yield takeLatest(actionTypes.LoadCoaches, function* saga() {
    const loadingId = 'coaches_loading';
    yield put(UIActions.addLoadingIndicator(loadingId));
    const res = yield axios.get('api/dashboard/coaches');
    if (res?.status !== 200) return;
    yield put(actions.loadCoachesSuccess(res.data.data.coaches));
    yield put(UIActions.removeLoadingIndicator(loadingId));
  });
  yield takeLatest(actionTypes.CreateCoach, function* saga() {
    const loadingId = 'coaches_loading';
    yield put(UIActions.addLoadingIndicator(loadingId));
    const res = yield axios.post('api/dashboard/coaches');
    yield put(actions.createCoachSuccess(res.data.data.coach));
    yield put(UIActions.removeLoadingIndicator(loadingId));
  });
  yield takeLatest(actionTypes.UpdateCoach, function* saga({ payload }) {
    const { id, coach } = payload;
    const res = yield axios.put(`api/dashboard/coaches/${id}`, coach);
    if (res?.status !== 200) return;
    yield put(actions.updateCoachSuccess(res.data.data.coach));
  });
  yield takeEvery(actionTypes.UploadCoachFile, function* saga({ payload }) {
    const { id, file, contentType } = payload;
    const loadingId = `coach_file_upload_${contentType}_${id}`;
    yield put(UIActions.addLoadingIndicator(loadingId));
    try {
      const res = yield uploadFile(`api/dashboard/coaches/${id}/files`, file, contentType);
      if (res?.status !== 200) return;
      yield put(actions.updateCoachSuccess(res.data.data.coach));
    } catch (e) {
      console.error('saga actionTypes.UploadCoachFile error', e);
    } finally {
      yield put(UIActions.removeLoadingIndicator(loadingId));
    }
  });
  // PLANS
  yield takeLatest(actionTypes.LoadPlans, function* saga() {
    const loadingId = 'plans_loading';
    yield put(UIActions.addLoadingIndicator(loadingId));
    const res = yield axios.get('api/dashboard/plans');
    if (res?.status !== 200) return;
    yield put(actions.loadPlansSuccess(res.data.data.plans));
    yield put(UIActions.removeLoadingIndicator(loadingId));
  });
  yield takeLatest(actionTypes.CreatePlan, function* saga({ payload }) {
    const { coachId } = payload;
    const loadingId = 'plans_loading';
    yield put(UIActions.addLoadingIndicator(loadingId));
    const res = yield axios.post('api/dashboard/plans', { coachId });
    yield put(actions.createPlanSuccess(res.data.data.plan));
    yield put(UIActions.removeLoadingIndicator(loadingId));
  });
  yield takeLatest(actionTypes.DuplicatePlans, function* saga({ payload }) {
    const { ids } = payload;
    const loadingId = 'plans_loading';
    yield put(UIActions.addLoadingIndicator(loadingId));
    yield Promise.all(ids.map(id => axios.post(`api/dashboard/plans/${id}/duplicate`)));
    yield put(actions.loadPlans());
    yield put(UIActions.removeLoadingIndicator(loadingId));
  });
  yield takeLatest(actionTypes.UpdatePlan, function* saga({ payload }) {
    const { id, plan } = payload;
    const res = yield axios.put(`api/dashboard/plans/${id}`, plan);
    if (res?.status !== 200) return;
    yield put(actions.updatePlanSuccess(res.data.data.plan));
  });
  yield takeEvery(actionTypes.UploadPlanFile, function* saga({ payload }) {
    const { id, file, contentType } = payload;
    const loadingId = `plan_file_upload_${contentType}_${id}`;
    yield put(UIActions.addLoadingIndicator(loadingId));
    try {
      const res = yield uploadFile(`api/dashboard/plans/${id}/files`, file, 'thumbnail');
      if (res?.status !== 200) return;
      yield put(actions.updatePlanSuccess(res.data.data.plan));
    } catch (e) {
      console.error('saga actionTypes.UploadPlanFile error', e);
    } finally {
      yield put(UIActions.removeLoadingIndicator(loadingId));
    }
  });
  // PLAN LEVELS
  yield takeLatest(actionTypes.CreatePlanLevel, function* saga({ payload }) {
    const { planId, title } = payload;
    const res = yield axios.post(`api/dashboard/plans/${planId}/levels`, { title });
    if (res?.status !== 200) return;
    yield put(actions.createPlanLevelSuccess(planId, res.data.data.planLevel));
  });
  yield takeLatest(actionTypes.DeletePlanLevels, function* saga({ payload }) {
    const { ids } = payload;
    yield Promise.all(ids.map(id => axios.delete(`api/dashboard/levels/${id}`)));
    yield put(actions.deletePlanLevelsSuccess(ids));
  });
  yield takeLatest(actionTypes.UpdatePlanLevel, function* saga({ payload }) {
    const { id, planLevel } = payload;
    const res = yield axios.put(`api/dashboard/levels/${id}`, planLevel);
    if (res?.status !== 200) return;
    yield put(actions.updatePlanLevelSuccess(res.data.data.planLevel));
  });
}
