import React, { Suspense, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import { LayoutContextProvider, LayoutSplashScreen } from '../../_metronic';
import Layout from '../../_metronic/layout/Layout';
import * as routerHelpers from '../router/RouterHelpers';
import LogIn from '../pages/core/LogIn';
import LogOut from '../pages/core/LogOut';
import UserWorkouts from '../pages/userWorkouts/UserWorkouts';
import EditPlan from '../pages/plans/EditPlan';
import EditPlanLevel from '../pages/plans/EditPlanLevel';
import Coaches from '../pages/plans/Coaches';
import EditCoach from '../pages/plans/EditCoach';
import Plans from '../pages/plans/Plans';
import EditTheme from '../pages/themes/EditTheme';
import Themes from '../pages/themes/Themes';
import EditExerciseGroup from '../pages/workouts/EditExerciseGroup';
import EditWorkout from '../pages/workouts/EditWorkout';
import Workouts from '../pages/workouts/Workouts';
import { actions, selectCurrentUser } from '../store/ducks/auth.duck';
import AmoCategories from '../pages/amos/AmoCategories';
import EditAmoCategory from '../pages/amos/EditAmoCategory';
import EditAmoCategoryLevel from '../pages/amos/EditAmoCategoryLevel';
import ResetPassword from '../pages/core/ResetPassword';
import Users from '../pages/users/Users';
import Exercises from '../pages/workouts/Exercises';
import EditExercise from '../pages/workouts/EditExercise';
import ExerciseGroups from '../pages/workouts/ExerciseGroups';
import WorkoutAudios from '../pages/workouts/WorkoutAudios';
import EditWorkoutAudio from '../pages/workouts/EditWorkoutAudio';
import PromoCodes from '../pages/promoCodes/PromoCodes';
import EditPromoCode from '../pages/promoCodes/EditPromoCode';


export const Routes = withRouter(({ history }) => {

  const dispatch = useDispatch();
  const { loadUser } = actions;
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);

  const { menuConfig } = useSelector(({ auth, urls, builder: { menuConfig } }) => ({ menuConfig }), shallowEqual);

  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch, loadUser]);

  if (!currentUser) {
    return (
      <LayoutContextProvider history={history} menuConfig={menuConfig}>
        <Switch>
          <Route exact path="/login" component={LogIn} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          <Redirect to="/login" />
        </Switch>
      </LayoutContextProvider>
    );
  }

  return (
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        <Layout>
          <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
              <Redirect exact from="/" to="/plans" />
              <Route path="/logout" component={LogOut} />
              {/* THEMES: */}
              <Route path="/themes" component={Themes} />
              <Route path="/edit-theme/:themeId" component={EditTheme} />
              {/* AMOS: */}
              <Route path="/amo-categories" component={AmoCategories} />
              <Route
                path="/edit-amo-category/:amoCategoryId/edit-amo-category-level/:amoCategoryLevelId"
                component={EditAmoCategoryLevel}
              />
              <Route path="/edit-amo-category/:amoCategoryId" component={EditAmoCategory} />
              {/* PROMO CODES: */}
              <Route path="/promo-codes" component={PromoCodes} />
              <Route path="/edit-promo-code/:promoCodeId" component={EditPromoCode} />
              {/* PLANS: */}
              <Route path="/coaches" component={Coaches} />
              <Route path="/edit-coach/:coachId" component={EditCoach} />
              <Route path="/plans" component={Plans} />
              <Route path="/edit-plan/:planId/edit-plan-level/:planLevelId" component={EditPlanLevel} />
              <Route path="/edit-plan/:planId" component={EditPlan} />
              {/* WORKOUTS: */}
              <Route path="/workouts" component={Workouts} />
              <Route path="/edit-workout/:id" component={EditWorkout} />
              <Route path="/workout-audios" component={WorkoutAudios} />
              <Route path="/edit-workout-audio" component={EditWorkoutAudio} />
              <Route path="/exerciseGroups" component={ExerciseGroups} />
              <Route path="/edit-exercise-group/:id" component={EditExerciseGroup} />
              <Route path="/exercises" component={Exercises} />
              <Route path="/edit-exercise/:exerciseId" component={EditExercise} />
              <Route path="/feedback" component={UserWorkouts} />
              {/* USERS: */}
              <Route path="/users" component={Users} />
            </Switch>
          </Suspense>
        </Layout>
      </Switch>
    </LayoutContextProvider>
  );
});
