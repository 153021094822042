import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import '../../sass/components/_video-dropzone.scss';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { CircularProgress } from '@material-ui/core';

const SimpleDropzone = (props) => {

  const { title, onDelete, onDrop, url, loading } = props;

  function localOnDrop(files) {
    if (loading || !files?.length) return;
    if (!files[0].type.includes('image')) {
      window.alert('This input only excepts image files.');
      return;
    }
    onDrop(files);
  }

  return (
    <div className="dropzone-container">
      <label>{title}</label>
      <Dropzone onDrop={localOnDrop} disabled={!onDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="dropzone-wrapper">
            {url && <img alt='preview_image' src={url} className='dropzone-preview' />}
            <input {...getInputProps()} />
            {onDrop &&
              (loading ?
                <CircularProgress /> :
                <div className="dropzone-icon-wrapper">
                  <AddIcon />
                </div>
              )
            }
            {(url && onDelete) &&
              <div
                className="dropzone-icon-wrapper"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
              >
                <DeleteIcon />
              </div>
            }
          </div>
        )}
      </Dropzone>
    </div>
  );
}

SimpleDropzone.propTypes = {
  title: PropTypes.string,
  onDelete: PropTypes.func,
  onDrop: PropTypes.func,
  url: PropTypes.string,
  loading: PropTypes.bool
};

export default SimpleDropzone;
