import { TextField, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnimatedModal from '../../partials/content/AnimatedModal';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import Table from '../../partials/content/Table';
import { actions, selectThemeById } from '../../store/ducks/theme.duck';
import { actions as planActions, selectCoaches } from '../../store/ducks/plan.duck';
import EditThemeEpisode from './EditThemeEpisode';


function EditTheme({ match }) {

  const themeId = +match.params.themeId;
  const theme = useSelector(state => selectThemeById(themeId)(state));
  const themeEpisodes = get(theme, 'episodes', []);
  const coaches = useSelector(selectCoaches);
  const { createThemeEpisode, deleteThemeEpisodes, loadThemes, updateTheme } = actions;
  const { loadCoaches } = planActions;
  const dispatch = useDispatch();
  const [activeThemeEpisodeId, setActiveThemeEpisodeId] = useState();

  useEffect(() => {
    !theme && dispatch(loadThemes());
  }, [dispatch, theme, loadThemes]);

  useEffect(() => {
    !coaches?.length && dispatch(loadCoaches());
  }, [coaches, dispatch, loadCoaches]);

  const themeEpisodesRows = React.useMemo(() => {
    return themeEpisodes.map(te => {
      let isAlmostValid = te.title && te.textForNotification && te.thumbnail && te.video;
      let isValid = isAlmostValid && !!te.textForPushNotification;
      return {
        ...te,
        valid: isValid ? '🟢' : (isAlmostValid ? '🟡' : '🔴'),
      };
    });
  }, [themeEpisodes]);

  async function onDeleteThemeEpisodes(ids) {
    try {
      await dispatch(deleteThemeEpisodes(ids));
      return true;
    } catch (error) {
      window.alert(error);
      return false;
    }
  }

  return !theme ? null : (
    <Portlet>
      <PortletBody>
        <div className="amo-row">
          <div className="amo-column">
            <FormControl style={{ margin: '0 16px 0 0' }}>
              <InputLabel htmlFor="coach">Coach</InputLabel>
              <Select
                inputProps={{ name: 'Coach', id: 'coach' }}
                style={{ minWidth: '300px' }}
                defaultValue={get(theme, 'coachId')}
                onBlur={(e) => dispatch(updateTheme(themeId, { ...theme, coachId: e.target.value }))}
              >
                {coaches.map(c =>
                  <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                )}
              </Select>
            </FormControl>
            <div className="amo-divider" />
            <FormControl style={{ margin: '0 16px 0 0' }}>
              <InputLabel htmlFor="theme-type">Availability</InputLabel>
              <Select
                inputProps={{ name: 'Type', id: 'theme-type' }}
                style={{ minWidth: '300px' }}
                defaultValue={get(theme, 'isPreOpened', false)}
                onBlur={(e) => dispatch(updateTheme(themeId, { ...theme, isPreOpened: e.target.value }))}
              >
                <MenuItem value={true}>All Episodes Are Pre Opened</MenuItem>
                <MenuItem value={false}>Each Episode Opened After Workout</MenuItem>
              </Select>
            </FormControl>
            <div className="amo-divider" />
            <TextField
              label="Emoji"
              className="input-standard"
              defaultValue={get(theme, 'emoji', '')}
              onBlur={(e) => dispatch(updateTheme(themeId, { emoji: e.target.value || null }))}
            />
            <div className="amo-divider" />
            <TextField
              label="Title"
              className="input-standard"
              defaultValue={get(theme, 'title', '')}
              onBlur={(e) => dispatch(updateTheme(themeId, { title: e.target.value }))}
            />
            <div className="amo-divider" />
            <TextField
              label="Overview"
              className="input-standard"
              defaultValue={get(theme, 'overview', '')}
              onBlur={(e) => dispatch(updateTheme(themeId, { overview: e.target.value }))}
            />
            <div className="amo-divider" />
            <FormControl style={{ margin: '0 16px 0 0' }}>
              <InputLabel htmlFor="theme-type">Type</InputLabel>
              <Select
                inputProps={{ name: "Type", id: "theme-type" }}
                style={{ minWidth: '300px' }}
                defaultValue={get(theme, 'type')}
                onBlur={(e) => dispatch(updateTheme(themeId, { ...theme, type: e.target.value }))}
              >
                <MenuItem value="nutrition">Nutrition</MenuItem>
                <MenuItem value="mindset">Mindset</MenuItem>
                <MenuItem value="lifestyle">Lifestyle</MenuItem>
                <MenuItem value="goals">Goals</MenuItem>
              </Select>
            </FormControl>
            <div className="amo-divider" />
          </div>
        </div>
        <br />
        <Table
          data={themeEpisodesRows}
          headCells={[
            { id: 'id', label: 'ID' },
            { id: 'valid', label: 'Valid' },
            { id: 'title', label: 'Title' },
            { id: 'thumbnail', isImage: true, label: 'Thumbnail' },
            { id: 'createdAt', label: 'Created', isDateTime: true },
          ]}
          title="Theme Episodes"
          onCreate={() => dispatch(createThemeEpisode(themeId, `New Episode`))}
          onDelete={onDeleteThemeEpisodes}
          onRowClick={(id) => setActiveThemeEpisodeId(id)}
        />
      </PortletBody>
      <AnimatedModal open={!!activeThemeEpisodeId} onClose={() => setActiveThemeEpisodeId(null)}>
        <EditThemeEpisode themeEpisodeId={activeThemeEpisodeId} />
      </AnimatedModal>
    </Portlet>
  );
}

EditTheme.propTypes = {
  match: PropTypes.object,
};

export default EditTheme;