import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../partials/content/Table';
import { actions, selectAmoCategories } from '../../store/ducks/amo.duck';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import { actions as planActions, selectCoaches, selectCurrentCoachId } from '../../store/ducks/plan.duck';


const AmoCategories = ({ history }) => {

  const amoCategories = useSelector(selectAmoCategories);
  const coaches = useSelector(selectCoaches);
  const currentCoachId = useSelector(selectCurrentCoachId);
  const { loadAmoCategories } = actions;
  const { loadCoaches } = planActions;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAmoCategories());
  }, [dispatch, loadAmoCategories]);

  useEffect(() => {
    !coaches?.length && dispatch(loadCoaches());
  }, [coaches, dispatch, loadCoaches]);

  return (
    <Portlet>
      <PortletBody>
        <Table
          data={(amoCategories || [])
            .filter(ac => !currentCoachId ? true : (ac.coachId === currentCoachId))
            .map(ac => ({
              ...ac,
              coach: coaches.find(c => c.id === ac.coachId)?.name,
            }))
          }
          headCells={[
            { id: 'id', label: 'ID' },
            { id: 'coach', label: 'Coach' },
            { id: 'title', label: 'Title' },
            { id: 'createdAt', label: 'Created', isDateTime: true },
          ]}
          title='Amo Categories'
          onRowClick={(id) => history.push(`/edit-amo-category/${id}`)}
        />
      </PortletBody>
    </Portlet>
  );
}

export default AmoCategories;
