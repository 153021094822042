import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import Table from '../../partials/content/Table';
import { actions, selectPromoCodes } from '../../store/ducks/promo.code';


const PromoCodes = ({ history }) => {

  const promoCodes = useSelector(state => selectPromoCodes(state));
  const promoCodeRows = promoCodes.map(pc => ({
    ...pc,
    usersAmount: pc?.users?.length || 0,
  }));
  const { createPromoCode, loadPromoCodes } = actions;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadPromoCodes());
  }, [dispatch, loadPromoCodes]);

  return (
    <Portlet>
      <PortletBody>
        <Table
          data={promoCodeRows}
          headCells={[
            { id: 'id', label: 'ID' },
            { id: 'code', label: 'Code' },
            { id: 'duration', label: 'Duration' },
            { id: 'expiresAt', label: 'Expiration Date', isDate: true },
            { id: 'affiliateName', label: 'Affiliate Name' },
            { id: 'cover', label: 'Affiliate Cover', isImage: true },
            { id: 'logo', label: 'Affiliate Logo', isImage: true },
            { id: 'usersAmount', label: 'Users Amount' },
            { id: 'createdAt', label: 'Created', isDateTime: true },
          ]}
          title="Promo Codes"
          onCreate={() => dispatch(createPromoCode())}
          onRowClick={(id) => history.push(`/edit-promo-code/${id}`)}
        />
      </PortletBody>
    </Portlet>
  );
}

export default PromoCodes;
