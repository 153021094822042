import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';


export const actionTypes = {
  // USERS
  LoadAllUsers: '[LoadAllUsers] Action',
  LoadAllUsersSuccess: '[LoadAllUsersSuccess] Action',
  UpdateUser: '[UpdateUser] Action',
  UpdateUserSuccess: '[UpdateUserSuccess] Action',
};

const initialUsersState = {
  users: [],
};

export const reducer = (state = initialUsersState, action) => {
  switch (action.type) {
    // USERS
    case actionTypes.LoadAllUsersSuccess: {
      const { users } = action.payload;
      return { ...state, users };
    }
    case actionTypes.UpdateUserSuccess: {
      const { id, user } = action.payload;
      const userIndex = state.users.findIndex(u => u.id === id);
      state.users[userIndex] = { ...state.users[userIndex], ...user }
      return { ...state };
    }
    default:
      return state;
  }
}

export const selectUsers = (state) => state.user.users;
export const selectUserById = (userId) => (state) => {
  const users = selectUsers(state);
  return users.find(u => u.id === userId);
}

export const actions = {
  // USERS
  loadAllUsers: () => ({ type: actionTypes.LoadAllUsers }),
  loadAllUsersSuccess: (users) => ({ type: actionTypes.LoadAllUsersSuccess, payload: { users } }),
  updateUser: (id, user) => ({ type: actionTypes.UpdateUser, payload: { id, user } }),
  updateUserSuccess: (id, user) => ({ type: actionTypes.UpdateUserSuccess, payload: { id, user } }),
};

export function* saga() {
  // USERS
  yield takeLatest(actionTypes.LoadAllUsers, function* saga() {
    const res = yield axios.get('api/dashboard/users');
    if (res?.status !== 200) return;
    yield put(actions.loadAllUsersSuccess(res.data.data.users));
  });
  yield takeLatest(actionTypes.UpdateUser, function* saga({ payload }) {
    const { id, user } = payload;
    const res = yield axios.put(`api/dashboard/users/${id}`, user);
    if (res?.status !== 200) return;
    yield put(actions.updateUserSuccess(id, res.data.data.user));
  });
}
