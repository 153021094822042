import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import Table from '../../partials/content/Table';
import { actions as userWorkoutActions, selectUserWorkouts } from '../../store/ducks/user.workout.duck';

const UserWorkouts = () => {

  const userWorkouts = useSelector(state => selectUserWorkouts(state));
  const { loadAllUserWorkouts } = userWorkoutActions;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllUserWorkouts());
  }, [dispatch, loadAllUserWorkouts]);

  return (
    <Portlet>
      <PortletBody>
        <Table
          data={userWorkouts.map(uw => {
            return {
              ...uw,
              rating: uw?.feedback?.rating === 1 ? '1 - 🤬' :
                uw?.feedback?.rating === 2 ? '2 - 🤨' :
                  uw?.feedback?.rating === 3 ? '3 - 🙂' :
                    uw?.feedback?.rating === 4 ? '4 - 😊' :
                      uw?.feedback?.rating === 5 ? '5 - 🥳' : '',
              improvements: (uw?.feedback?.improvements || []).join(', '),
              feedback: uw?.feedback?.feedback,
            };
          })}
          defaultOrder='desc'
          defaultOrderBy='id'
          headCells={[
            { id: 'id', label: 'ID' },
            { id: 'planTitle', label: 'Plan' },
            { id: 'planLevelTitle', label: 'Plan Level' },
            { id: 'planLevelWeek', label: 'Week' },
            { id: 'workoutId', label: 'Workout ID' },
            { id: 'workoutTitle', label: 'Workout' },
            { id: 'userId', label: 'User ID' },
            { id: 'email', label: 'Email' },
            { id: 'gender', label: 'Gender' },
            { id: 'startTime', label: 'Start Time', isDateTime: true },
            { id: 'endTime', label: 'End Time', isDateTime: true },
            { id: 'duration', label: 'Duration' },
            { id: 'rating', label: 'Rating', width: 20 },
            { id: 'improvements', label: 'Improvements', width: 250, isMultiline: true },
            { id: 'feedback', label: 'Feedback', width: 450, isMultiline: true },
          ]}
          title="User Workout Feedback"
        />
      </PortletBody>
    </Portlet>
  );
}

export default UserWorkouts;
