import { TextField } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnimatedModal from '../../partials/content/AnimatedModal';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import Table from '../../partials/content/Table';
import { actions, amoTypeLabels, selectAmoCategoryById } from '../../store/ducks/amo.duck';
import EditAmo from './EditAmo';


const EditAmoCategoryLevel = ({ match }) => {

  const amoCategoryId = +match.params.amoCategoryId;
  const amoCategoryLevelId = +match.params.amoCategoryLevelId;
  const amoCategory = useSelector(state => selectAmoCategoryById(amoCategoryId)(state));
  const amoCategoryLevels = get(amoCategory, 'levels', []);
  const amoCategoryLevel = amoCategoryLevels.find(acl => acl.id === amoCategoryLevelId);
  const amos = get(amoCategoryLevel, 'amos', []);
  const { createAmo, deleteAmos, loadAmoCategories, updateAmoCategoryLevel } = actions;
  const dispatch = useDispatch();
  const [activeAmoId, setActiveAmoId] = useState();

  useEffect(() => {
    !amoCategory && dispatch(loadAmoCategories());
  }, [dispatch, amoCategory, loadAmoCategories]);

  const amosRows = React.useMemo(() => {
    return amos.map(a => {
      let isAlmostValid = a.textForNotification;
      let isValid = isAlmostValid && a.textForPushNotification && a.video;
      if (a.type === 'workoutReminder') {
        const isWorkoutReminderValid = a.data && a.data.option1Label && a.data.option1FollowUp && a.data.option1FollowUpGif && a.data.option2Label && a.data.option2FollowUp;
        isValid = isValid && isWorkoutReminderValid;
      }
      return {
        ...a,
        typeString: amoTypeLabels[a.type],
        valid: isValid ? '🟢' : (isAlmostValid ? '🟡' : '🔴'),
      };
    });
  }, [amos]);

  return !amoCategory ? null : (
    <Portlet>
      <PortletBody>
        <TextField
          label="Title"
          className="input-standard"
          defaultValue={get(amoCategoryLevel, 'title', '')}
          disabled
          onBlur={(e) => dispatch(updateAmoCategoryLevel(amoCategoryLevelId, { title: e.target.value }))}
        />
        <div className="amo-divider" />
        {amoCategory?.goal === 'common' ?
          (amoCategoryLevel.title === 'Level 1' ?
            <>
              Should contain:
              <br />
              - 25 {amoTypeLabels.workoutReminder}s (5 for each week, weeks 0 to 4)
              <br />
              - any amount of {amoTypeLabels.workoutUndoneReminder}s (they are used for all levels)
            </> :
            <>
              Should contain:
              <br />
              - 25 {amoTypeLabels.workoutReminder}s (5 for each week, weeks 0 to 4)
            </>
          ) :
          <>
            Should contain:
            <br />
            - 1 * {amoTypeLabels.planStarter}s
            <br />
            - 4 * {amoTypeLabels.weekOpener}s (1 for each week, weeks 1 to 4)
            <br />
            - 20 * {amoTypeLabels.wellnessReminder}s (4 for each week, weeks 0 to 4)
            <br />
            - 10 * {amoTypeLabels.wellnessWeekendReminder}s (2 for each week, weeks 0 to 4)
            <br />
            - 4 * {amoTypeLabels.weekSucceededRecap}s (1 for each week, weeks 1 to 4)
            <br />
            - 4 * {amoTypeLabels.weekFailed1to99Recap}s Recaps (1 for each week, weeks 1 to 4)
            <br />
            - 4 * {amoTypeLabels.weekFailedRecap}s Recaps (1 for each week, weeks 1 to 4)
          </>
        }
        <br />
        <br />
        <Table
          data={amosRows}
          headCells={[
            { id: 'id', label: 'ID' },
            { id: 'valid', label: 'Valid' },
            { id: 'typeString', label: 'Type' },
            { id: 'weekNumber', label: 'Week' },
            { id: 'title', label: 'Title' },
            { id: 'createdAt', label: 'Created', isDateTime: true },
          ]}
          title="Amos"
          onCreate={() => dispatch(createAmo(amoCategoryLevelId, `New Amo`))}
          onDelete={(ids) => dispatch(deleteAmos(ids))}
          onRowClick={(id) => setActiveAmoId(id)}
        />
      </PortletBody>
      <AnimatedModal open={!!activeAmoId} onClose={() => setActiveAmoId(null)}>
        <EditAmo amoId={activeAmoId} />
      </AnimatedModal>
    </Portlet>
  );
}

EditAmoCategoryLevel.propTypes = {
  match: PropTypes.object,
};

export default EditAmoCategoryLevel;