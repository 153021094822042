import { cloneDeep } from 'lodash';


export const actionTypes = {
  AddLoadingIndicator: '[AddLoadingIndicator] Action',
  RemoveLoadingIndicator: '[RemoveLoadingIndicator] Action',
  ClearLoadingIndicators: '[ClearLoadingIndicators] Action',
  AddKeyValue: '[AddKeyValue] Action',
  RemoveKeyValue: '[RemoveKeyValue] Action',
};

const initialAuthState = {
  loadingIndicators: [],
  keyValues: {},
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.AddLoadingIndicator: {
      const { id } = action.payload;
      return { ...state, loadingIndicators: [...state.loadingIndicators, id] };
    }
    case actionTypes.RemoveLoadingIndicator: {
      const { id } = action.payload;
      return { ...state, loadingIndicators: state.loadingIndicators.filter(i => i !== id) };
    }
    case actionTypes.ClearLoadingIndicators: {
      return { ...state, loadingIndicators: [] };
    }
    case actionTypes.AddKeyValue: {
      const { key, value } = action.payload;
      return { ...state, keyValues: { ...state.keyValues, [key]: value } };
    }
    case actionTypes.RemoveKeyValue: {
      const { key } = action.payload;
      const keyValues = cloneDeep(state.keyValues);
      delete (keyValues[key]);
      return { ...state, keyValues };
    }
    default:
      return state;
  }
}

export const selectLoadingIndicators = (state) => state.UI.loadingIndicators;
export const selectKeyValues = (state) => state.UI.keyValues;

export const actions = {
  addLoadingIndicator: (id) => ({ type: actionTypes.AddLoadingIndicator, payload: { id } }),
  removeLoadingIndicator: (id) => ({ type: actionTypes.RemoveLoadingIndicator, payload: { id } }),
  clearLoadingIndicators: () => ({ type: actionTypes.ClearLoadingIndicators }),
  addKeyValue: (key, value) => ({ type: actionTypes.AddKeyValue, payload: { key, value } }),
  removeKeyValue: (key) => ({ type: actionTypes.RemoveKeyValue, payload: { key } }),
};

export function* saga() { };
