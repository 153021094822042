import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { TextField, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import { actions, selectUserById } from '../../store/ducks/user.duck';
import SimpleDropzone from '../../partials/content/SimpleDropzone';
import { actions as planActions, selectPlans } from '../../store/ducks/plan.duck';


const EditUser = ({ userId }) => {

  const user = useSelector(state => selectUserById(userId)(state));
  const plans = useSelector(selectPlans);
  const { loadAllUsers, updateUser } = actions;
  const { loadPlans } = planActions;
  const dispatch = useDispatch();

  useEffect(() => {
    userId && dispatch(loadAllUsers());
  }, [dispatch, userId, loadAllUsers]);

  useEffect(() => {
    dispatch(loadPlans());
  }, [dispatch, loadPlans]);

  if (!user) return null;

  const plan = plans.find(p => p.id === user.planId);
  const userPlan = (user?.userPlans || []).find(up => up.planId === user.planId);
  const userPlanLevelIndex = userPlan?.levelsCompleted ?? 0;

  return !user ? null : (
    <Portlet>
      <PortletBody>
        <div className="amo-row">
          <div className="amo-column">
            <TextField
              label="ID"
              className="input-standard"
              value={get(user, 'id', '')}
            />
            <div className="amo-divider" />
            <TextField
              label="Name"
              className="input-standard"
              value={get(user, 'name', '')}
            />
            <div className="amo-divider" />
            <TextField
              label="Email"
              className="input-standard"
              value={get(user, 'email', '')}
            />
            <div className="amo-divider" />
            <FormControl>
              <InputLabel htmlFor="admin">Admin</InputLabel>
              <Select
                inputProps={{ name: 'Admin', id: 'admin' }}
                style={{ width: '300px' }}
                defaultValue={!!get(user, 'isAdmin')}
                onBlur={(e) => dispatch(updateUser(userId, { isAdmin: e.target.value }))}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd.MM.yyyy"
                margin="normal"
                label="Promo From Admin Until"
                value={get(user, 'promoAdminUntil')}
                onChange={(date) => dispatch(updateUser(userId, { promoAdminUntil: date }))}
                InputProps={{ style: { width: 300 } }}
                KeyboardButtonProps={{ 'aria-label': 'change date' }}
              />
            </MuiPickersUtilsProvider>
            <div className="amo-divider" />
            <FormControl>
              <InputLabel htmlFor="planId">Plan</InputLabel>
              <Select
                inputProps={{ name: 'Plan', id: 'planId' }}
                style={{ width: '300px' }}
                defaultValue={get(user, 'planId')}
                onBlur={(e) => dispatch(updateUser(userId, { planId: e.target.value }))}
              >
                {plans.map(plan =>
                  <MenuItem key={plan.id} value={plan.id}>
                    [{plan.id}] {plan.title} {plan.focus ? `(${plan.focus})` : ''}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <div className="amo-divider" />
            <FormControl>
              <InputLabel htmlFor="admin">Plan Level</InputLabel>
              <Select
                inputProps={{ name: 'Plan Level', id: 'planLevelId' }}
                style={{ width: '300px' }}
                defaultValue={userPlanLevelIndex + 1}
                onBlur={(e) => dispatch(updateUser(userId, { planLevel: e.target.value }))}
              >
                {plan.levels.map((pl, index) =>
                  <MenuItem key={pl.id} value={index + 1}>{pl.title}</MenuItem>
                )}
              </Select>
            </FormControl>
            <div className="amo-divider" />
            <FormControl style={{ margin: "0 16px 16px 0" }}>
              <InputLabel htmlFor="planLevelWeek">Plan Level Week</InputLabel>
              <Select
                inputProps={{ name: 'Plan Level Week', id: 'planLevelWeek' }}
                style={{ width: '300px' }}
                defaultValue={user?.planLevelWeek}
                onBlur={(e) => dispatch(updateUser(userId, { planLevelWeek: e.target.value }))}
              >
                <MenuItem value={0}>Week 0</MenuItem>
                <MenuItem value={1}>Week 1</MenuItem>
                <MenuItem value={2}>Week 2</MenuItem>
                <MenuItem value={3}>Week 3</MenuItem>
                <MenuItem value={4}>Week 4</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="amo-divider" />
          <div className="amo-column">
            <SimpleDropzone title="Avatar" url={get(user, 'avatar', '')} />
          </div>
        </div>
      </PortletBody>
    </Portlet>
  );
}

EditUser.propTypes = {
  userId: PropTypes.number,
};

export default EditUser;