
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnimatedModal from '../../partials/content/AnimatedModal';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import Table from '../../partials/content/Table';
import { actions as planActions, selectCoaches, selectCurrentCoachId } from '../../store/ducks/plan.duck';
import { actions, selectWorkoutAudios } from '../../store/ducks/workout.duck';
import EditWorkoutAudio from './EditWorkoutAudio';


const WorkoutAudios = () => {

  const workoutAudios = useSelector(selectWorkoutAudios);
  const coaches = useSelector(selectCoaches);
  const currentCoachId = useSelector(selectCurrentCoachId);
  const { loadAllWorkoutAudios } = actions;
  const { loadCoaches } = planActions;
  const dispatch = useDispatch();

  const [activeWorkoutAudioId, setActiveWorkoutAudioId] = useState(null);

  useEffect(() => {
    dispatch(loadAllWorkoutAudios());
  }, [dispatch, loadAllWorkoutAudios]);

  useEffect(() => {
    !coaches?.length && dispatch(loadCoaches());
  }, [coaches, dispatch, loadCoaches]);

  return (
    <Portlet>
      <PortletBody>
        <Table
          data={(workoutAudios || [])
            .filter(wa => !currentCoachId ? true : (wa.coachId === currentCoachId))
            .map(wa => ({
              ...wa,
              coach: coaches.find(c => c.id === wa.coachId)?.name,
            }))
          }
          defaultOrderBy="title"
          headCells={[
            { id: 'id', label: 'ID' },
            { id: 'coach', label: 'Coach' },
            { id: 'title', label: 'Title' },
            { id: 'createdAt', label: 'Created', isDateTime: true },
          ]}
          title="Workout Audios"
          onRowClick={(id) => setActiveWorkoutAudioId(id)}
        />
      </PortletBody>
      <AnimatedModal open={!!activeWorkoutAudioId} onClose={() => setActiveWorkoutAudioId(null)}>
        <Portlet>
          <EditWorkoutAudio workoutAudioId={activeWorkoutAudioId} />
        </Portlet>
      </AnimatedModal>
    </Portlet>
  );
}

export default WorkoutAudios;
