import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';


export const actionTypes = {
  // USER WORKOUTS
  LoadAllUserWorkouts: '[LoadAllUserWorkouts] Action',
  LoadAllUserWorkoutsSuccess: '[LoadAllUserWorkoutsSuccess] Action',
};

const initialUserWorkoutsState = {
  userWorkouts: [],
};

export const reducer = (state = initialUserWorkoutsState, action) => {
  switch (action.type) {
    // USER WORKOUTS
    case actionTypes.LoadAllUserWorkoutsSuccess: {
      const { userWorkouts } = action.payload;
      return { ...state, userWorkouts };
    }
    default:
      return state;
  }
}

export const selectUserWorkouts = (state) => state.userWorkout.userWorkouts;

export const actions = {
  // USER WORKOUTS
  loadAllUserWorkouts: () => ({ type: actionTypes.LoadAllUserWorkouts }),
  loadAllUserWorkoutsSuccess: (userWorkouts) => ({ type: actionTypes.LoadAllUserWorkoutsSuccess, payload: { userWorkouts } }),
};

export function* saga() {
  // USER WORKOUTS
  yield takeLatest(actionTypes.LoadAllUserWorkouts, function* saga() {
    const res = yield axios.get('api/dashboard/users/workouts/feedback');
    if (res?.status !== 200) return;
    yield put(actions.loadAllUserWorkoutsSuccess(res.data.data.userWorkouts));
  });
}
