import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../sass/pages/workout/editWorkout.scss';
import '../../sass/components/_inputs.scss';
import { actions, selectCoaches, selectCurrentCoachId } from '../../store/ducks/plan.duck';


const CurrentCoachDropdown = (props) => {

  const coaches = useSelector(selectCoaches);
  const currentCoachId = useSelector(selectCurrentCoachId);
  const { loadCoaches, setCurrentCoachId } = actions;
  const dispatch = useDispatch();

  useEffect(() => {
    !coaches?.length && dispatch(loadCoaches());
  }, [coaches, dispatch, loadCoaches]);

  return (
    <FormControl>
      <InputLabel htmlFor="coach">Coach</InputLabel>
      <Select
        inputProps={{ name: 'Coach', id: 'coach' }}
        style={{ minWidth: '200px' }}
        value={currentCoachId}
        onChange={(e) => dispatch(setCurrentCoachId(e.target.value))}
      >
        <MenuItem value={0}>All Coaches</MenuItem>
        {coaches.map(c =>
          <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

export default CurrentCoachDropdown;
