import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import Table from '../../partials/content/Table';
import { actions, selectThemes } from '../../store/ducks/theme.duck';
import { actions as planActions, selectCoaches, selectCurrentCoachId } from '../../store/ducks/plan.duck';


const Themes = ({ history }) => {

  const themes = useSelector(state => selectThemes(state));
  const coaches = useSelector(selectCoaches);
  const currentCoachId = useSelector(selectCurrentCoachId);
  const { createTheme, deleteThemes, loadThemes } = actions;
  const { loadCoaches } = planActions;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadThemes());
  }, [dispatch, loadThemes]);

  useEffect(() => {
    !coaches?.length && dispatch(loadCoaches());
  }, [coaches, dispatch, loadCoaches]);

  return (
    <Portlet>
      <PortletBody>
        <Table
          data={(themes || [])
            .filter(t => !currentCoachId ? true : (t.coachId === currentCoachId))
            .map(t => ({
              ...t,
              coach: coaches.find(c => c.id === t.coachId)?.name,
            }))
          }
          headCells={[
            { id: 'id', label: 'ID' },
            { id: 'coach', label: 'Coach' },
            { id: 'emoji', label: 'Emoji' },
            { id: 'title', label: 'Title' },
            { id: 'type', label: 'Title' },
            { id: 'createdAt', label: 'Created', isDateTime: true },
          ]}
          title="Themes"
          onCreate={() => dispatch(createTheme(currentCoachId, 'New theme', 'nutrition'))}
          onDelete={(ids) => dispatch(deleteThemes(ids.filter(id => ![1, 2, 3, 4, 5, 6].includes(id))))}
          onRowClick={(id) => history.push(`/edit-theme/${id}`)}
        />
      </PortletBody>
    </Portlet>
  );
}

export default Themes;
