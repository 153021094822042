import axios from 'axios';
import { cloneDeep } from 'lodash';
import { put, takeLatest, takeEvery } from 'redux-saga/effects';
import { uploadFile } from '../../../utils';
import { actions as UIActions } from './UI.duck';


export const actionTypes = {
  // THEMES
  LoadThemes: '[LoadThemes] Action',
  LoadThemesSuccess: '[LoadThemesSuccess] Action',
  CreateTheme: '[CreateTheme] Action',
  CreateThemeSuccess: '[CreateThemeSuccess] Action',
  DeleteThemes: '[DeleteThemes] Action',
  DeleteThemesSuccess: '[DeleteThemesSuccess] Action',
  UpdateTheme: '[UpdateTheme] Action',
  UpdateThemeSuccess: '[UpdateThemeSuccess] Action',
  // THEME EPISODES
  CreateThemeEpisode: '[CreateThemeEpisode] Action',
  CreateThemeEpisodeSuccess: '[CreateThemeEpisodeSuccess] Action',
  DeleteThemeEpisodes: '[DeleteThemeEpisodes] Action',
  DeleteThemeEpisodesSuccess: '[DeleteThemeEpisodesSuccess] Action',
  UpdateThemeEpisode: '[UpdateThemeEpisode] Action',
  UploadThemeEpisodeFile: '[UploadThemeEpisodeThumbnail] Action',
  UpdateThemeEpisodeSuccess: '[UpdateThemeEpisodeSuccess] Action',
};

const initialThemesState = {
  themes: [],
};

export const reducer = (state = initialThemesState, action) => {
  switch (action.type) {
    // THEMES
    case actionTypes.LoadThemesSuccess: {
      const { themes } = action.payload;
      return { ...state, themes };
    }
    case actionTypes.CreateThemeSuccess: {
      const { theme } = action.payload;
      return { ...state, themes: [...state.themes, theme] };
    }
    case actionTypes.UpdateThemeSuccess: {
      const { theme } = action.payload;
      const themeIndex = state.themes.findIndex(t => t.id === theme.id);
      state.themes[themeIndex] = { ...state.themes[themeIndex], ...theme }
      return { ...state };
    }
    case actionTypes.DeleteThemesSuccess: {
      const { ids } = action.payload;
      return { ...state, themes: state.themes.filter(t => !ids.includes(t.id)) };
    }
    // THEME EPISODES
    case actionTypes.CreateThemeEpisodeSuccess: {
      const { themeId, themeEpisode } = action.payload;
      const themeIndex = state.themes.findIndex(t => t.id === themeId);
      const theme = cloneDeep(state.themes[themeIndex]);
      theme.episodes = [...(theme.episodes || []), themeEpisode];
      state.themes[themeIndex] = theme;
      return state;
    }
    case actionTypes.DeleteThemeEpisodesSuccess: {
      const { ids } = action.payload;
      let themes = cloneDeep(state.themes);
      themes = themes.map(t => ({
        ...t,
        episodes: t.episodes.filter(te => !ids.includes(te.id))
      }));
      return { ...state, themes };
    }
    case actionTypes.UpdateThemeEpisodeSuccess: {
      const { themeEpisode } = action.payload;
      let themes = cloneDeep(state.themes);
      themes = themes.map(t => ({
        ...t,
        episodes: t.episodes.map(te => te.id !== themeEpisode.id ? te : { ...te, ...themeEpisode })
      }));
      return { ...state, themes };
    }
    default:
      return state;
  }
}

export const selectThemes = (state) => state.theme.themes;
export const selectThemeById = (themeId) => (state) => {
  const themes = selectThemes(state);
  return themes.find(t => t.id === themeId);
}
export const selectThemeEpisodeById = (themeEpisodeId) => (state) => {
  const themes = selectThemes(state);
  const themeEpisodes = themes.reduce((acc, theme) => [...acc, ...(theme.episodes || [])], []);
  return themeEpisodes.find(te => te.id === themeEpisodeId);
}

export const actions = {
  // THEMES
  loadThemes: () => ({ type: actionTypes.LoadThemes }),
  loadThemesSuccess: (themes) => ({ type: actionTypes.LoadThemesSuccess, payload: { themes } }),
  createTheme: (coachId, title, type) => ({ type: actionTypes.CreateTheme, payload: { coachId, title, type } }),
  createThemeSuccess: (theme) => ({ type: actionTypes.CreateThemeSuccess, payload: { theme }, }),
  deleteThemes: (ids) => ({ type: actionTypes.DeleteThemes, payload: { ids } }),
  deleteThemesSuccess: (ids) => ({ type: actionTypes.DeleteThemesSuccess, payload: { ids } }),
  updateTheme: (id, theme) => ({ type: actionTypes.UpdateTheme, payload: { id, theme } }),
  updateThemeSuccess: (theme) => ({ type: actionTypes.UpdateThemeSuccess, payload: { theme } }),
  // THEME EPISODES
  createThemeEpisode: (themeId, title) => ({ type: actionTypes.CreateThemeEpisode, payload: { themeId, title } }),
  createThemeEpisodeSuccess: (themeId, themeEpisode) => ({ type: actionTypes.CreateThemeEpisodeSuccess, payload: { themeId, themeEpisode } }),
  deleteThemeEpisodes: (ids) => ({ type: actionTypes.DeleteThemeEpisodes, payload: { ids } }),
  deleteThemeEpisodesSuccess: (ids) => ({ type: actionTypes.DeleteThemeEpisodesSuccess, payload: { ids } }),
  updateThemeEpisode: (id, themeEpisode) => ({ type: actionTypes.UpdateThemeEpisode, payload: { id, themeEpisode } }),
  uploadThemeEpisodeFile: (id, file, contentType) => ({ type: actionTypes.UploadThemeEpisodeFile, payload: { id, file, contentType } }),
  updateThemeEpisodeSuccess: (themeEpisode) => ({ type: actionTypes.UpdateThemeEpisodeSuccess, payload: { themeEpisode } }),
};

export function* saga() {
  // THEMES
  yield takeLatest(actionTypes.LoadThemes, function* saga() {
    const res = yield axios.get('api/dashboard/themes');
    if (res?.status !== 200) return;
    yield put(actions.loadThemesSuccess(res.data.data.themes));
  });
  yield takeLatest(actionTypes.CreateTheme, function* saga({ payload }) {
    const { coachId, title, type } = payload;
    const res = yield axios.post('api/dashboard/themes', { coachId, title, type });
    if (res?.status !== 200) return;
    yield put(actions.createThemeSuccess(res.data.data.theme));
  });
  yield takeLatest(actionTypes.UpdateTheme, function* saga({ payload }) {
    const { id, theme } = payload;
    const res = yield axios.put(`api/dashboard/themes/${id}`, theme);
    if (res?.status !== 200) return;
    yield put(actions.updateThemeSuccess(res.data.data.theme));
  });
  yield takeLatest(actionTypes.DeleteThemes, function* saga({ payload }) {
    const { ids } = payload;
    yield Promise.all(ids.map(id => axios.delete(`api/dashboard/themes/${id}`)));
    yield put(actions.deleteThemesSuccess(ids));
  });
  // THEME EPISODES
  yield takeLatest(actionTypes.CreateThemeEpisode, function* saga({ payload }) {
    const { themeId, title } = payload;
    const res = yield axios.post(`api/dashboard/themes/${themeId}/episodes`, { title });
    if (res?.status !== 200) return;
    yield put(actions.createThemeEpisodeSuccess(themeId, res.data.data.themeEpisode));
  });
  yield takeLatest(actionTypes.DeleteThemeEpisodes, function* saga({ payload }) {
    const { ids } = payload;
    yield Promise.all(ids.map(id => axios.delete(`api/dashboard/episodes/${id}`)));
    yield put(actions.deleteThemeEpisodesSuccess(ids));
  });
  yield takeLatest(actionTypes.UpdateThemeEpisode, function* saga({ payload }) {
    const { id, themeEpisode } = payload;
    const res = yield axios.put(`api/dashboard/episodes/${id}`, themeEpisode);
    if (res?.status !== 200) return;
    yield put(actions.updateThemeEpisodeSuccess(res.data.data.themeEpisode));
  });
  yield takeEvery(actionTypes.UploadThemeEpisodeFile, function* saga({ payload }) {
    const { id, file, contentType } = payload;
    const loadingId = `theme_episode_upload_${contentType}_${id}`;
    yield put(UIActions.addLoadingIndicator(loadingId));
    try {
      const res = yield uploadFile(`api/dashboard/episodes/${id}/files`, file, contentType);
      if (res?.status !== 200) return;
      yield put(actions.updateThemeEpisodeSuccess(res.data.data.themeEpisode));
    } catch (e) {
      console.error('saga actionTypes.UploadThemeEpisodeFile error', e);
    } finally {
      yield put(UIActions.removeLoadingIndicator(loadingId));
    }
  });
}
