import { Button, InputAdornment, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import logo from '../../../../assets/images/img-large-amo-logo-white.png';
import LockIcon from '@material-ui/icons/Lock';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DoneIcon from '@material-ui/icons/Done';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';

import './ResetPassword.scss';
import { useForm } from '../../../../hooks';

export const passwordRegex = new RegExp(/^.{8,}$/);

const validator = (values) => {
  let errors = {};
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (!values.confirmPassword) {
    errors.confirmPassword = 'Confirm password is required';
  } else if (!passwordRegex.test(values.password)) {
    errors.password = 'Password mush be at least 8 characters long';
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Password do not match';
  }
  return errors;
};

export default function ResetPassword(props) {
  const resetToken = props.match.params.token;
  const initialValues = { password: '', confirmPassword: '' };
  const form = useForm(resetPassword, validator, initialValues);
  const { errors, handleChange, handleSubmit, values } = form;
  const [success, setSuccess] = useState();

  async function resetPassword() {
    try {
      const res = await axios.put('api/users/password/reset', {
        forgotPasswordHash: resetToken,
        password: values.password,
      });
      if (res.status !== 200) {
        throw new Error('Failed');
      }
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
    }
  }

  function renderContent() {
    switch (success) {
      case true:
        return (
          <div>
            <p>Your password has been successfully reset. Go to your app to login.</p>
          </div>
        );
      case false:
        return (
          <div>
            <p>Reset password token is invalid or has expired. Go to your app to request reset password link.</p>
          </div>
        );
      default:
        return (
          <>
            <p>Enter a new password</p>
            <TextField
              className="input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
              label="Password"
              type="password"
              variant="outlined"
              value={values?.password}
              onChange={(e) => handleChange('password', e.target.value)}
              fullWidth
              error={!!errors?.password}
              helperText={errors?.password}
            />
            <TextField
              className="input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
              label="Confirm Password"
              type="password"
              variant="outlined"
              fullWidth
              value={values?.confirmPassword}
              onChange={(e) => handleChange('confirmPassword', e.target.value)}
              error={!!errors?.confirmPassword}
              helperText={errors?.confirmPassword}
            />
          </>
        );
    }
  }

  return (
    <div className="reset__password__container">
      <div className="content__box">
        <div className="top-section">
          <img alt="logo" className="logo" src={logo} />
          <h1>Change Password</h1>
        </div>
        <div className="white-box">{renderContent()}</div>
        {
          <div className={['confirm-button-wrapper', success !== undefined ? 'disabled' : ''].join(' ')}>
            <Button variant="outlined" color="inherit" onClick={handleSubmit}>
              <ChevronRightIcon className={['button__icon', success === undefined ? 'active' : '']} color="inherit" />
              <DoneIcon className={['button__icon', success === true ? 'active' : ''].join(' ')} color="inherit" />
              <CloseIcon className={['button__icon', success === false ? 'active' : ''].join(' ')} color="inherit" />
            </Button>
          </div>
        }
      </div>
    </div>
  );
}
