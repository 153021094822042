import axios from 'axios';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import * as routerHelpers from '../../router/RouterHelpers';
import { actions as UIActions } from './UI.duck';


export const actionTypes = {
  LogIn: '[LogIn] Action',
  LogInSuccess: '[LogInSuccess] Action',
  LogOut: '[LogOut] Action',
  LoadUser: '[LoadUser] Action',
  LoadUserSuccess: '[LoadUserSuccess] Action',
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
};

export const reducer = persistReducer(
  { storage, key: 'persist-auth' },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.LogInSuccess: {
        const { authToken } = action.payload;
        return { ...state, authToken };
      }
      case actionTypes.LogOut: {
        routerHelpers.forgotLastLocation();
        return initialAuthState;
      }
      case actionTypes.LoadUserSuccess: {
        const { user } = action.payload;
        return { ...state, user };
      }
      default:
        return state;
    }
  }
);

export const selectCurrentUser = (state) => state.auth.user;
export const selectJWTToken = (state) => state.auth.authToken;

export const actions = {
  logIn: (email, password, errorId) => ({ type: actionTypes.LogIn, payload: { email, password, errorId } }),
  logInSuccess: (authToken) => ({ type: actionTypes.LogInSuccess, payload: { authToken } }),
  logOut: () => ({ type: actionTypes.LogOut }),
  loadUser: () => ({ type: actionTypes.LoadUser }),
  loadUserSuccess: (user) => ({ type: actionTypes.LoadUserSuccess, payload: { user } }),
};

export function* saga() {
  yield takeLatest(actionTypes.LogIn, function* saga({ payload }) {
    const { email, password, errorId } = payload;
    const res = yield axios.post('api/dashboard/users/login', { email, password, isDashboard: true });
    if (res?.status !== 200) {
      yield put(UIActions.addKeyValue(errorId, 'This combination of email and password is not correct'));
    } else if (res?.data?.data?.token) {
      yield put(UIActions.removeKeyValue(errorId));
      yield put(actions.logInSuccess(res.data.data.token));
      yield put(actions.loadUser());
    }
  });
  yield takeLatest(actionTypes.LoadUser, function* saga() {
    const res = yield axios.get('api/dashboard/users/me');
    if (res?.status === 200) {
      yield put(actions.loadUserSuccess(res.data.data));
    } else {
      yield put(actions.logOut());
    }
  });
}
