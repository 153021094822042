import axios from 'axios';
import { put, takeLatest, takeEvery } from 'redux-saga/effects';
import { uploadFile } from '../../../utils';
import { actions as UIActions } from './UI.duck';


export const actionTypes = {
  // PROMO CODES
  LoadPromoCodes: '[LoadPromoCodes] Action',
  LoadPromoCodesSuccess: '[LoadPromoCodesSuccess] Action',
  CreatePromoCode: '[CreatePromoCode] Action',
  CreatePromoCodeSuccess: '[CreatePromoCodeSuccess] Action',
  UpdatePromoCode: '[UpdatePromoCode] Action',
  UploadPromoCodeFile: '[UpdatePromoCodeFile] Action',
  UpdatePromoCodeSuccess: '[UpdatePromoCodeSuccess] Action',
};

const initialrPomoCodesState = {
  promoCodes: [],
};

export const reducer = (state = initialrPomoCodesState, action) => {
  switch (action.type) {
    // PROMO CODES
    case actionTypes.LoadPromoCodesSuccess: {
      const { promoCodes } = action.payload;
      return { ...state, promoCodes };
    }
    case actionTypes.CreatePromoCodeSuccess: {
      const { promoCode } = action.payload;
      return { ...state, promoCodes: [...state.promoCodes, promoCode] };
    }
    case actionTypes.UpdatePromoCodeSuccess: {
      const { promoCode } = action.payload;
      const { promoCodes } = state;
      const promoCodeIndex = promoCodes.findIndex(pc => pc.id === promoCode.id);
      promoCodes[promoCodeIndex] = { ...promoCodes[promoCodeIndex], ...promoCode };
      return { ...state, promoCodes };
    }
    default:
      return state;
  }
}

export const selectPromoCodes = (state) => state.promoCode.promoCodes;
export const selectPromoCodeById = (promoCodesId) => (state) => {
  const promoCodes = selectPromoCodes(state);
  return promoCodes.find(pc => pc.id === promoCodesId);
}

export const actions = {
  // PROMO CODES
  loadPromoCodes: () => ({ type: actionTypes.LoadPromoCodes }),
  loadPromoCodesSuccess: (promoCodes) => ({ type: actionTypes.LoadPromoCodesSuccess, payload: { promoCodes } }),
  createPromoCode: () => ({ type: actionTypes.CreatePromoCode }),
  createPromoCodeSuccess: (promoCode) => ({ type: actionTypes.CreatePromoCodeSuccess, payload: { promoCode }, }),
  updatePromoCode: (id, promoCode) => ({ type: actionTypes.UpdatePromoCode, payload: { id, promoCode } }),
  uploadPromoCodeFile: (id, file, contentType) => ({ type: actionTypes.UploadPromoCodeFile, payload: { id, file, contentType } }),
  updatePromoCodeSuccess: (promoCode) => ({ type: actionTypes.UpdatePromoCodeSuccess, payload: { promoCode } }),
};

export function* saga() {
  // PROMO CODES
  yield takeLatest(actionTypes.LoadPromoCodes, function* saga() {
    const res = yield axios.get('api/dashboard/promo-codes');
    if (res?.status !== 200) return;
    yield put(actions.loadPromoCodesSuccess(res.data.data.promoCodes));
  });
  yield takeLatest(actionTypes.CreatePromoCode, function* saga() {
    const res = yield axios.post('api/dashboard/promo-codes', {});
    if (res?.status !== 200) return;
    yield put(actions.createPromoCodeSuccess(res.data.data.promoCode));
  });
  yield takeLatest(actionTypes.UpdatePromoCode, function* saga({ payload }) {
    const { id, promoCode } = payload;
    const res = yield axios.put(`api/dashboard/promo-codes/${id}`, promoCode);
    if (res?.status !== 200) return;
    yield put(actions.updatePromoCodeSuccess(res.data.data.promoCode));
  });
  yield takeEvery(actionTypes.UploadPromoCodeFile, function* saga({ payload }) {
    const { id, file, contentType } = payload;
    const loadingId = `promoCode_file_upload_${contentType}_${id}`;
    yield put(UIActions.addLoadingIndicator(loadingId));
    try {
      const res = yield uploadFile(`api/dashboard/promo-codes/${id}/files`, file, contentType);
      if (res?.status !== 200) return;
      yield put(actions.updatePromoCodeSuccess(res.data.data.promoCode));
    } catch (e) {
      console.error('saga actionTypes.UploadPromoCodeFile error', e);
    } finally {
      yield put(UIActions.removeLoadingIndicator(loadingId));
    }
  });
}
