import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AudioDropzone from '../../partials/content/AudioDropzone';
import { PortletBody } from '../../partials/content/Portlet';
import SimpleDropzone from '../../partials/content/SimpleDropzone';
import VideoDropzone from '../../partials/content/VideoDropzone';
import { actions as planActions, selectCoaches } from '../../store/ducks/plan.duck';
import { selectLoadingIndicators } from '../../store/ducks/UI.duck';
import { actions, selectExerciseById, selectPropertiesByType } from '../../store/ducks/workout.duck';


const EditExercise = ({ exerciseId }) => {
  const loadingIndicators = useSelector(selectLoadingIndicators);
  const equipment = useSelector(state => selectPropertiesByType('equipment')(state));
  const muscleGroups = useSelector(state => selectPropertiesByType('muscleGroups')(state));
  const exercise = useSelector(selectExerciseById(exerciseId));
  const coaches = useSelector(selectCoaches);
  const {
    createSet,
    deleteSet,
    loadExercise,
    loadAllProperties,
    updateExercise,
    updateExerciseProperties,
    updateExerciseMuscleGroups,
    uploadExerciseFile,
    // updateSet,
    uploadSetFile,
  } = actions;
  const { loadCoaches } = planActions;
  const dispatch = useDispatch();

  useEffect(() => {
    exerciseId && dispatch(loadExercise(exerciseId));
  }, [dispatch, exerciseId, loadExercise]);

  useEffect(() => {
    dispatch(loadAllProperties());
  }, [dispatch, loadAllProperties]);

  useEffect(() => {
    !coaches?.length && dispatch(loadCoaches());
  }, [coaches, dispatch, loadCoaches]);

  return (!exercise || !exercise.isDetailed) ? null : (
    <PortletBody style={{ width: 1150, boxShadow: '0px 0px 13px 0px rgba(82, 63, 105, 0.2)' }}>
      <h2 style={{ margin: '8px 0 8px 0' }}>EXERCISE:</h2>
      <div className="amo-row">
        <div className="amo-column">
          <FormControl style={{ margin: '0 16px 0 0' }}>
            <InputLabel htmlFor="coach">Coach</InputLabel>
            <Select
              inputProps={{ name: 'Coach', id: 'coach' }}
              style={{ minWidth: '300px' }}
              defaultValue={get(exercise, 'coachId')}
              onBlur={(e) => dispatch(updateExercise(exercise.id, { ...exercise, coachId: e.target.value }))}
            >
              {coaches.map(c =>
                <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
              )}
            </Select>
          </FormControl>
          <div className="amo-divider" />
          <TextField
            label="Exercise Title"
            className="input-standard"
            defaultValue={exercise.title || ''}
            onBlur={(e) => dispatch(updateExercise(exercise.id, { title: e.target.value.trim() }))}
          />
          <div className="amo-divider" />
          <FormControl style={{ marginRight: 16 }}>
            <InputLabel htmlFor="exercise-equipment">Exercise Equipment</InputLabel>
            <Select
              multiple
              inputProps={{ name: 'Exercise Equipment', id: 'exercise-equipment' }}
              style={{ width: 300 }}
              defaultValue={(exercise.equipment || []).map(e => e.id)}
              onBlur={(e) => dispatch(updateExerciseProperties(exerciseId, 'equipment', e.target.value))}
            >
              {equipment.map(({ id, value }) =>
                <MenuItem key={id} value={id}>{value}</MenuItem>
              )}
            </Select>
          </FormControl>
          <div className="amo-divider" />
          <FormControl style={{ minWidth: 200, marginRight: 10 }}>
            <InputLabel htmlFor="body-part">Body Part</InputLabel>
            <Select
              defaultValue={exercise.bodyPart}
              inputProps={{ name: 'Body Part', id: 'body-part' }}
              onBlur={(e) => dispatch(updateExercise(exercise.id, { bodyPart: e.target.value }))}
            >
              <MenuItem value="bodyweightKnee">Bodyweight - Knee</MenuItem>
              <MenuItem value="bodyweightCore">Bodyweight - Core</MenuItem>
              <MenuItem value="bodyweightHip">Bodyweight - Hip</MenuItem>
              <MenuItem value="bodyweightPress">Bodyweight - Press</MenuItem>
              <MenuItem value="bodyweightPush">Bodyweight - Push</MenuItem>
              <MenuItem value="bodyweightPull">Bodyweight - Pull</MenuItem>
              <MenuItem value="bodyweightCardio">Bodyweight - Cardio</MenuItem>
              <MenuItem value="dumbbellKnee">Dumbbell - Knee</MenuItem>
              <MenuItem value="dumbbellCore">Dumbbell - Core</MenuItem>
              <MenuItem value="dumbbellHip">Dumbbell - Hip</MenuItem>
              <MenuItem value="dumbbellPress">Dumbbell - Press</MenuItem>
              <MenuItem value="dumbbellPush">Dumbbell - Push</MenuItem>
              <MenuItem value="dumbbellPull">Dumbbell - Pull</MenuItem>
              <MenuItem value="dumbbellCardio">Dumbbell - Cardio</MenuItem>
              <MenuItem value="dumbbellRow">Dumbbell - Row</MenuItem>
              <MenuItem value="gymKnee">Gym - Knee</MenuItem>
              <MenuItem value="gymCore">Gym - Core</MenuItem>
              <MenuItem value="gymHip">Gym - Hip</MenuItem>
              <MenuItem value="gymPress">Gym - Press</MenuItem>
              <MenuItem value="gymPush">Gym - Push</MenuItem>
              <MenuItem value="gymPull">Gym - Pull</MenuItem>
              <MenuItem value="gymCardio">Gym - Cardio</MenuItem>
              <MenuItem value="gymRow">Gym - Row</MenuItem>
            </Select>
          </FormControl>
          <div className="amo-divider" />
          <FormControl style={{ minWidth: 200, marginRight: 10 }}>
            <InputLabel htmlFor="muscles">Muscles</InputLabel>
            <Select
              multiple={true}
              defaultValue={(exercise.muscleGroups || []).map(e => e.id)}
              inputProps={{ name: 'Muscles', id: 'muscles' }}
              onBlur={(e) => dispatch(updateExerciseMuscleGroups(exercise.id, 'muscleGroups', e.target.value ))}
            >
              {muscleGroups.map(({ id, value }) =>
                  <MenuItem key={id} value={id}>{value}</MenuItem>
              )}
            </Select>
          </FormControl>
          <div className="amo-divider" />
          <FormControl style={{ minWidth: 200, marginRight: 10 }}>
            <InputLabel htmlFor="rank">Rank</InputLabel>
            <Select
              defaultValue={exercise.rank}
              inputProps={{ name: 'Rank', id: 'rank' }}
              onBlur={(e) => dispatch(updateExercise(exercise.id, { rank: e.target.value }))}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8].map(rank =>
                <MenuItem key={rank} value={rank}>{rank}</MenuItem>
              )}
            </Select>
          </FormControl>
          <div className="amo-divider" />
          <FormControl style={{ minWidth: 200, marginRight: 10 }}>
            <InputLabel htmlFor="type">Type</InputLabel>
            <Select
              defaultValue={exercise.type}
              inputProps={{ name: 'Type', id: 'type' }}
              onBlur={(e) => dispatch(updateExercise(exercise.id, { type: e.target.value }))}
            >
              <MenuItem value="repsBased">Reps Based</MenuItem>
              <MenuItem value="repsBasedScreening">Reps Based Screening</MenuItem>
              <MenuItem value="timeBased">Time Based</MenuItem>
            </Select>
          </FormControl>
          <div className="amo-divider" />
          <FormControl style={{ minWidth: 120, marginRight: 10 }}>
            <InputLabel htmlFor="log-type">Log Type</InputLabel>
            <Select
              defaultValue={exercise.logType}
              inputProps={{ name: 'Log Type', id: 'log-type' }}
              onBlur={(e) => dispatch(updateExercise(exercise.id, { logType: e.target.value }))}
            >
              <MenuItem value="reps">Reps</MenuItem>
              <MenuItem value="weight">Weight</MenuItem>
              <MenuItem value='none'>None</MenuItem>
              <MenuItem value='null'></MenuItem>
            </Select>
          </FormControl>
          <div className="amo-divider" />
          <FormControl style={{ minWidth: 120, marginRight: 10 }}>
            <InputLabel htmlFor="log-2-type">Log 2 Type</InputLabel>
            <Select
              defaultValue={exercise.log2Type}
              inputProps={{ name: 'Log 2 Type', id: 'log-2-type' }}
              onBlur={(e) => dispatch(updateExercise(exercise.id, { log2Type: e.target.value }))}
            >
              <MenuItem value="reps">Reps</MenuItem>
              <MenuItem value="weight">Weight</MenuItem>
              <MenuItem value='none'>None</MenuItem>
              <MenuItem value='null'></MenuItem>
            </Select>
          </FormControl>
          <div className="amo-divider" />
          <TextField
            label="Weight Coefficient"
            className="input-standard"
            defaultValue={exercise.weightCoefficient}
            onBlur={(e) => dispatch(updateExercise(exercise.id, { weightCoefficient: +e.target.value }))}
            style={{ minWidth: 300 }}
          />
        </div>
        <div className="amo-divider" />
        <div style={{ marginRight: 16 }}>
          <VideoDropzone
            loading={loadingIndicators.includes(`exercise_file_upload_video_${exercise.id}`)}
            title="Short Video"
            onDrop={(files) => dispatch(uploadExerciseFile(exercise.id, files[0], 'content', 'video'))}
            url={(exercise.exerciseIntermissions ?? []).find(x => x.type === 'content')?.video}
            duration={(exercise.exerciseIntermissions ?? []).find(x => x.type === 'content')?.videoDuration}
          />
        </div>
        <div style={{ marginRight: 16 }}>
          <VideoDropzone
            loading={loadingIndicators.includes(`exercise_file_upload_demoVideo_${exercise.id}`)}
            title="Full Demo Video"
            onDrop={(files) => dispatch(uploadExerciseFile(exercise.id, files[0], 'content', 'demoVideo'))}
            url={(exercise.exerciseIntermissions ?? []).find(x => x.type === 'content')?.demoVideo}
            duration={(exercise.exerciseIntermissions ?? []).find(x => x.type === 'content')?.demoVideoDuration}
          />
        </div>
        <SimpleDropzone
          loading={loadingIndicators.includes(`exercise_file_upload_thumbnail_${exercise.id}`)}
          title="Thumbnail"
          onDrop={(files) => dispatch(uploadExerciseFile(exercise.id, files[0], 'thumbnail', 'thumbnail'))}
          url={exercise?.thumbnail}
        />
      </div>
      <div className="amo-divider" />
      <div className="amo-divider" />
      {(exercise.sets || []).map((set, index) =>
        <div className="set-card-wrapper" key={index}>
          <div className="amo-column, amo-flex1">
            <div className="amo-row" style={{ marginTop: 0 }}>
              <div className="amo-flex1" style={{ maxWidth: 350, marginRight: 10 }}>
                <div className="amo-row" style={{ marginTop: 0 }}>
                  <span style={{ fontSize: 34, fontWeight: '500' }}>
                    Set {index + 1}
                  </span>
                  <DeleteIcon
                    fontSize="large"
                    onClick={() => dispatch(deleteSet(set.id))}
                    style={{ margin: '12px 0 0 15px' }}
                  />
                </div>
              </div>
              <div className="amo-flex1" style={{ maxWidth: 350, marginRight: 10 }}></div>
              <div className="amo-flex1" style={{ maxWidth: 350, marginRight: 10 }}></div>
            </div>
            <div className="amo-row">
              <div className="amo-flex1" style={{ maxWidth: 350, marginRight: 10 }}>
                <AudioDropzone
                  title="Intro"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'intro'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'intro'))}
                  loading={loadingIndicators.includes(`set_file_upload_intro_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'intro')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'intro')?.audioDuration}
                />
              </div>
              <div className="amo-flex1" style={{ maxWidth: 350, marginRight: 10 }}>
                <AudioDropzone
                  title="Set 1"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'set1'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'set1'))}
                  loading={loadingIndicators.includes(`set_file_upload_set1_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'set1')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'set1')?.audioDuration}
                />
              </div>
              <div className="amo-flex1" style={{ maxWidth: 350 }}>
                <AudioDropzone
                  title="Soundbite 1"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'soundbite1'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'soundbite1'))}
                  loading={loadingIndicators.includes(`set_file_upload_soundbite1_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'soundbite1')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'soundbite1')?.audioDuration}
                />
              </div>
            </div>
            <div className="amo-row">
              <div className="amo-flex1" style={{ maxWidth: 350, marginRight: 10 }}>
                <AudioDropzone
                  title="Soundbite 2"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'soundbite2'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'soundbite2'))}
                  loading={loadingIndicators.includes(`set_file_upload_soundbite2_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'soundbite2')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'soundbite2')?.audioDuration}
                />
              </div>
              <div className="amo-flex1" style={{ maxWidth: 350, marginRight: 10 }}>
                <AudioDropzone
                  title="Set 2"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'set2'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'set2'))}
                  loading={loadingIndicators.includes(`set_file_upload_set2_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'set2')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'set2')?.audioDuration}
                />
              </div>
              <div className="amo-flex1" style={{ maxWidth: 350 }}>
                <AudioDropzone
                  title="Soundbite 3"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'soundbite3'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'soundbite3'))}
                  loading={loadingIndicators.includes(`set_file_upload_soundbite3_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'soundbite3')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'soundbite3')?.audioDuration}
                />
              </div>
            </div>
            <div className="amo-row">
              <div className="amo-flex1" style={{ maxWidth: 350, marginRight: 10 }}>
                <AudioDropzone
                  title="Soundbite 4"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'soundbite4'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'soundbite4'))}
                  loading={loadingIndicators.includes(`set_file_upload_soundbite4_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'soundbite4')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'soundbite4')?.audioDuration}
                />
              </div>
              <div className="amo-flex1" style={{ maxWidth: 350, marginRight: 10 }}>
                <AudioDropzone
                  title="Set 3"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'set3'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'set3'))}
                  loading={loadingIndicators.includes(`set_file_upload_set3_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'set3')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'set3')?.audioDuration}
                />
              </div>
              <div className="amo-flex1" style={{ maxWidth: 350 }}>
                <AudioDropzone
                  title="Finisher"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'finisher'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'finisher'))}
                  loading={loadingIndicators.includes(`set_file_upload_finisher_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'finisher')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'finisher')?.audioDuration}
                />
              </div>
            </div>
            <div className="amo-row">
              <div className="amo-flex1" style={{ maxWidth: 350, marginRight: 10 }}>
                <AudioDropzone
                  title="Outro"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'outro'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'outro'))}
                  loading={loadingIndicators.includes(`set_file_upload_outro_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'outro')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'outro')?.audioDuration}
                />
              </div>
              <div className="amo-flex1" style={{ maxWidth: 350, marginRight: 10 }}>
                <AudioDropzone
                  title="Outro Soundbite 1"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'outroSoundbite1'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'outroSoundbite1'))}
                  loading={loadingIndicators.includes(`set_file_upload_outroSoundbite1_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'outroSoundbite1')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'outroSoundbite1')?.audioDuration}
                />
              </div>
              <div className="amo-flex1" style={{ maxWidth: 350 }}>
                <AudioDropzone
                  title="Outro Soundbite 2"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'outroSoundbite2'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'outroSoundbite2'))}
                  loading={loadingIndicators.includes(`set_file_upload_outroSoundbite2_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'outroSoundbite2')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'outroSoundbite2')?.audioDuration}
                />
              </div>
            </div>
            <div className="amo-row">
              <div className="amo-flex1" style={{ maxWidth: 350, marginRight: 10 }}>
                <AudioDropzone
                  title="Outro Soundbite 3"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'outroSoundbite3'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'outroSoundbite3'))}
                  loading={loadingIndicators.includes(`set_file_upload_outroSoundbite3_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'outroSoundbite3')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'outroSoundbite3')?.audioDuration}
                />
              </div>
              <div className="amo-flex1" style={{ maxWidth: 350, marginRight: 10 }}>
                <AudioDropzone
                  title="Outro Soundbite 4"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'outroSoundbite4'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'outroSoundbite4'))}
                  loading={loadingIndicators.includes(`set_file_upload_outroSoundbite4_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'outroSoundbite4')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'outroSoundbite4')?.audioDuration}
                />
              </div>
              <div className="amo-flex1" style={{ maxWidth: 350 }}>
                <AudioDropzone
                  title="Outro Soundbite 5"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'outroSoundbite5'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'outroSoundbite5'))}
                  loading={loadingIndicators.includes(`set_file_upload_outroSoundbite5_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'outroSoundbite5')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'outroSoundbite5')?.audioDuration}
                />
              </div>
            </div>
            <div className="amo-row">
              <div className="amo-flex1" style={{ maxWidth: 350, marginRight: 10 }}>
                <AudioDropzone
                  title="Outro Soundbite 6"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'outroSoundbite6'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'outroSoundbite6'))}
                  loading={loadingIndicators.includes(`set_file_upload_outroSoundbite6_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'outroSoundbite6')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'outroSoundbite6')?.audioDuration}
                />
              </div>
              <div className="amo-flex1" style={{ maxWidth: 350, marginRight: 10 }}>
                <AudioDropzone
                  title="Outro Soundbite 7"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'outroSoundbite7'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'outroSoundbite7'))}
                  loading={loadingIndicators.includes(`set_file_upload_outroSoundbite7_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'outroSoundbite7')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'outroSoundbite7')?.audioDuration}
                />
              </div>
              <div className="amo-flex1" style={{ maxWidth: 350 }}>
                <AudioDropzone
                  title="Outro Soundbite 8"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'outroSoundbite8'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'outroSoundbite8'))}
                  loading={loadingIndicators.includes(`set_file_upload_outroSoundbite8_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'outroSoundbite8')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'outroSoundbite8')?.audioDuration}
                />
              </div>
            </div>
            <div className="amo-row">
              <div className="amo-flex1" style={{ maxWidth: 350, marginRight: 10 }}>
                <AudioDropzone
                  title="Outro Soundbite 9"
                  onDelete={() => dispatch(uploadSetFile(set.id, null, 'outroSoundbite9'))}
                  onDrop={(files) => dispatch(uploadSetFile(set.id, files[0], 'outroSoundbite9'))}
                  loading={loadingIndicators.includes(`set_file_upload_outroSoundbite9_${set.id}`)}
                  url={(set.setIntermissions || []).find(i => i.type === 'outroSoundbite9')?.audio}
                  duration={(set.setIntermissions || []).find(i => i.type === 'outroSoundbite9')?.audioDuration}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {exercise.sets.length < 4 &&
        <div
          className="edit-workout-add-element small"
          onClick={() => dispatch(createSet(exercise.id, Math.max(...(exercise.sets || []).map(s => s.order), 0) + 1))}
        >
          + Add Exercise Set
        </div>
      }
    </PortletBody>
  );
}

EditExercise.propTypes = {
  exerciseId: PropTypes.number,
};

export default EditExercise;
