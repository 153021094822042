import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import SimpleDropzone from '../../partials/content/SimpleDropzone';
import Table from '../../partials/content/Table';
import { actions, selectPromoCodeById } from '../../store/ducks/promo.code';
import { selectLoadingIndicators } from '../../store/ducks/UI.duck';

function EditPromoCode({ match }) {

  const promoCodeId = +match.params.promoCodeId;
  const promoCode = useSelector(state => selectPromoCodeById(promoCodeId)(state));
  const users = get(promoCode, 'users') || [];
  const loadingIndicators = useSelector(selectLoadingIndicators);
  const { loadPromoCodes, updatePromoCode, uploadPromoCodeFile } = actions;
  const dispatch = useDispatch();

  useEffect(() => {
    promoCodeId && dispatch(loadPromoCodes());
  }, [dispatch, promoCodeId, loadPromoCodes]);

  return !promoCode ? null : (
    <Portlet>
      <PortletBody>
        <div className="amo-row">
          <div className="amo-column">
            <TextField
              label="Code"
              className="input-standard"
              defaultValue={get(promoCode, 'code', '')}
              onBlur={(e) => dispatch(updatePromoCode(promoCodeId, { code: e.target.value.trim() }))}
            />
            <div className="amo-divider" />
            <TextField
              label="Description"
              className="input-standard"
              defaultValue={get(promoCode, 'description', '')}
              onBlur={(e) => dispatch(updatePromoCode(promoCodeId, { description: e.target.value.trim() }))}
            />
            <div className="amo-divider" />
            <TextField
              label="Duration"
              className="input-standard"
              defaultValue={get(promoCode, 'duration', '')}
              onBlur={(e) => dispatch(updatePromoCode(promoCodeId, { duration: e.target.value.trim() }))}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd.MM.yyyy"
                margin="normal"
                label="Expiration Date"
                value={get(promoCode, 'expiresAt')}
                onChange={(date) => dispatch(updatePromoCode(promoCodeId, { expiresAt: date }))}
                InputProps={{ style: { width: 300 } }}
                KeyboardButtonProps={{ 'aria-label': 'change date' }}
              />
            </MuiPickersUtilsProvider>
            <div className="amo-divider" />
            <TextField
              label="Affiliate Name"
              className="input-standard"
              defaultValue={get(promoCode, 'affiliateName', '')}
              onBlur={(e) => dispatch(updatePromoCode(promoCodeId, { affiliateName: e.target.value.trim() }))}
            />
          </div>
          <div className="amo-divider" />
          <SimpleDropzone
            loading={loadingIndicators.includes(`promoCode_file_upload_cover_${promoCodeId}`)}
            title="Affiliate Cover"
            onDrop={(files) => files.length && dispatch(uploadPromoCodeFile(promoCodeId, files[0], 'cover'))}
            url={get(promoCode, 'cover')}
          />
          <div className="amo-divider" />
          <SimpleDropzone
            loading={loadingIndicators.includes(`promoCode_file_upload_logo_${promoCodeId}`)}
            title="Affiliate Logo"
            onDrop={(files) => files.length && dispatch(uploadPromoCodeFile(promoCodeId, files[0], 'logo'))}
            url={get(promoCode, 'logo')}
          />
        </div>
        <div className="amo-divider" />
        <div className="amo-divider" />
        <Table
          data={users || []}
          headCells={[
            { id: 'id', label: 'ID' },
            { id: 'email', label: 'Email' },
            { id: 'name', label: 'Name' },
            { id: 'promoCodeUntil', label: 'Promo Code Until', isDate: true  },
            { id: 'createdAt', label: 'Created', isDateTime: true  },
          ]}
          title="Users"
        />
      </PortletBody>
    </Portlet>
  );
}

EditPromoCode.propTypes = {
  match: PropTypes.object,
};

export default EditPromoCode;