import { TextField } from '@material-ui/core';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../store/ducks/auth.duck';
import { useForm } from '../../../hooks';
import { isEmail } from '../../../utils';
import { selectKeyValues } from '../../store/ducks/UI.duck';


const validator = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!isEmail(values.email)) {
    errors.email = 'Email is invalid';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  }
  return errors;
};

const LogIn = () => {

  const { logIn } = actions;
  const dispatch = useDispatch();

  const initialValues = { email: '', password: '' };
  const loginErrorKey = 'loginError';
  const form = useForm(() => dispatch(logIn(values.email, values.password, loginErrorKey)), validator, initialValues);
  const { errors, handleChange, handleSubmit, setErrors, values } = form;

  const keyValues = useSelector(selectKeyValues);
  const loginError = keyValues[loginErrorKey];
  useEffect(() => {
    setErrors({ password: loginError });
  }, [loginError, setErrors]);

  return (
    <form onSubmit={handleSubmit} style={{ margin: '140px auto', display: 'flex', flexDirection: "column", maxWidth: 300 }}>
      <TextField
        label="Email"
        className="input-standard"
        value={get(values, 'email')}
        onChange={(e) => handleChange('email', e.target.value)}
        error={!!get(errors, 'email')}
        helperText={get(errors, 'email')}
      />
      <br />
      <TextField
        label="Password"
        className="input-standard"
        value={get(values, 'password')}
        onChange={(e) => handleChange('password', e.target.value)}
        error={!!get(errors, 'password')}
        helperText={get(errors, 'password')}
        type="password"
      />
      <br />
      <br />
      <button
        id="kt_login_signin_submit"
        type="submit"
        className={`btn btn-primary btn-elevate kt-login__btn-primary`}
      >
        Log In
      </button>
    </form>
  );
}

export default LogIn;
